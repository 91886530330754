const hexToRGB = (hex) => {
    const hexArr = hex
        .replace(
            /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
            (m, r, g, b) => "#" + r + r + g + g + b + b
        )
        .substring(1)
        .match(/.{2}/g)
        .map((x) => parseInt(x, 16));

    return { r: hexArr[0], g: hexArr[1], b: hexArr[2] };
};

export default hexToRGB;
