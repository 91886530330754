import { useEffect, useContext, useState } from "react";
import { AdminAuctionContext } from "../context/AdminAuctionContext";
import { Completions as CompletionsSvc } from "../../services/ActiveAuctionService";
import { UpdateCompletionCount } from "../../services/ActiveAuctionService";
import sortCompletions from "../../utilities/sortCompletions";
import { useEvent } from "@harelpls/use-pusher";
import CompletionCard from "../components/CompletionCard";
import WhatIfCard from "../components/WhatIfCard";
import PlaceBidForm from "../components/PlaceBidForm";
import AuctionInactiveMessage from "../components/AuctionInactiveMessage";
import { PlaceBidProvider } from "../components/PlaceBidForm/context/PlaceBidContext";

export default function Completions() {
    const {
        currentAuctionId,
        currentAuctionIsLive,
        auctionChannel,
        currentAuctionCompletionsToShow,
    } = useContext(AdminAuctionContext);
    const [completionsData, setCompletionsData] = useState([]);
    const [completionsToShow, setCompletionsToShow] = useState(
        currentAuctionCompletionsToShow
    );

    const refreshCompletions = async (value) => {
        let completions = await CompletionsSvc({
            auction_id: currentAuctionId,
            per_page: value,
        });
        await UpdateCompletionCount({
            auction_id: currentAuctionId,
            auction_input_completions: value,
        });
        setCompletionsToShow(value);
        setCompletionsData(sortCompletions(completions.data));
    };

    useEvent(auctionChannel, "App\\Events\\CompletionsUpdated", () => {
        refreshCompletions(completionsToShow);
    });

    useEffect(() => {
        (async () => {
            let completions = await CompletionsSvc({
                auction_id: currentAuctionId,
                per_page: completionsToShow,
            });
            setCompletionsData(sortCompletions(completions.data));
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <PlaceBidProvider>
            {!currentAuctionIsLive && <AuctionInactiveMessage />}
            <div className="mr-4 -mt-4">
                <div className="flex">
                    <div className="w-2/3">
                        <PlaceBidForm />
                    </div>
                    <div className="w-1/3 ml-8">
                        <WhatIfCard />
                    </div>
                </div>
                <hr className="mt-4 mb-2" />
                <div className="flex mt-2 mb-2 items-center">
                    <div className="text-lg bold pr-4">Top Completions</div>
                    <div className="flex-1 text-right">
                        <select
                            className="mt-1 h-10 rounded-md border-gray-200 text-sm"
                            onChange={(e) => refreshCompletions(e.target.value)}
                            defaultValue={currentAuctionCompletionsToShow}
                        >
                            <CountOptions />
                        </select>
                    </div>
                </div>
                {completionsData.map((completion) => (
                    <CompletionCard
                        completion={completion}
                        key={completion.completion_id}
                    />
                ))}
            </div>
        </PlaceBidProvider>
    );
}

const CountOptions = () => {
    const options = [1, 2, 3];
    return options.map((option) => <option key={option}>{option}</option>);
};
