import { useRef, useEffect, useState, useContext } from "react";
import BiddingTabNav from "../components/BiddingTabNav";
import { AuctionContext } from "../context/AuctionContext";
import Pagination from "../components/Pagination";
import TractMapPlaceBid from "../components/PlaceBidCard/TractMapPlaceBid";
import ContentLoader from "react-content-loader";
import { PlaceBidContext } from "../components/PlaceBidCard/context/PlaceBidContext";

export default function TractMap() {
    const canvasRef = useRef();
    const [tractPosData, setTractPosData] = useState({});
    const [currentTractIndex, setCurrentTractIndex] = useState(0);
    const [currentTractImage, setCurrentTractImage] = useState(null);
    const [currentTractImageColorized, setCurrentTractImageColorized] =
        useState(null);
    const [tractData, setTractData] = useState(null);
    const [colorizedStrokeData, setColorizedStrokeData] = useState(null);
    const [strokeData, setStrokeData] = useState(null);
    const [canvasDimensions, setcanvasDimensions] = useState({
        width: 0,
        height: 0,
    });
    const { width: canvasWidth, height: canvasHeight } = canvasDimensions;
    const { currentTractImages } = useContext(AuctionContext);
    const [imageLoad, setImageLoad] = useState(false);
    const [resetString, setResetString] = useState(null);
    const { tractInput, handleTractInputChange } = useContext(PlaceBidContext);
    const setTractInput = (callBack) => {
        const value = callBack(tractInput);
        return handleTractInputChange(value);
    };

    useEffect(() => {
        const currentImage = currentTractImages[currentTractIndex];
        setColorizedStrokeData(null);
        setStrokeData(null);
        setCurrentTractImage({
            preview: `${process.env.REACT_APP_BASE_URL}/api/image/${currentImage.tract_image_name}`,
        });
        setCurrentTractImageColorized({
            preview: `${process.env.REACT_APP_BASE_URL}/api/image/${currentImage.tract_image_colorized_name}`,
        });
        setTractData(JSON.parse(currentImage.tract_image_data));
    }, [currentTractIndex, currentTractImages]);

    useEffect(() => {
        if (!resetString) return;

        resetString.split("+").forEach((tractNum) => {
            const tract = tractData.find(
                (t) => t.tract.toString() === tractNum.toString()
            );

            if (!tract) return;

            return fillCanvas(
                {
                    clientX:
                        tract.clickLocation.x - tract.clickLocation.offset.x,
                    clientY:
                        tract.clickLocation.y - tract.clickLocation.offset.y,
                    preventDefault: () => {
                        return;
                    },
                },
                tract.clickLocation.offset
            );
        });

        setResetString(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetString]);

    useEffect(() => {
        if (!imageLoad || !tractData) return;

        setTractPosData({});
        tractInput.split("+").forEach((tractNum) => {
            const tract = tractData.find(
                (t) => t.tract.toString() === tractNum.toString()
            );

            if (!tract) return;

            return fillCanvas(
                {
                    clientX:
                        tract.clickLocation.x - tract.clickLocation.offset.x,
                    clientY:
                        tract.clickLocation.y - tract.clickLocation.offset.y,
                    preventDefault: () => {
                        return;
                    },
                },
                tract.clickLocation.offset
            );
        });
        setImageLoad(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageLoad]);

    useEffect(() => {
        if (currentTractImage) {
            if (canvasDimensions.width && canvasDimensions.height) {
                let canvas = canvasRef.current;
                let ctx = canvas.getContext("2d");

                const img = new Image();
                img.crossOrigin = "Anonymous";
                img.src = !colorizedStrokeData
                    ? currentTractImageColorized.preview
                    : currentTractImage.preview;
                img.onload = () => {
                    ctx.drawImage(
                        img,
                        0,
                        0,
                        canvasDimensions.width,
                        canvasDimensions.height
                    );
                    // save the original (unfilled) canvas
                    // so we can reference where the black bounding lines are
                    !colorizedStrokeData
                        ? setColorizedStrokeData(
                              ctx.getImageData(
                                  0,
                                  0,
                                  canvasDimensions.width,
                                  canvasDimensions.height
                              )
                          )
                        : setStrokeData(
                              ctx.getImageData(
                                  0,
                                  0,
                                  canvasDimensions.width,
                                  canvasDimensions.height
                              )
                          );
                    if (colorizedStrokeData) setImageLoad(true);
                };
            } else {
                const img = new Image();
                img.crossOrigin = "Anonymous";
                img.src = currentTractImage.preview;

                img.onload = () => {
                    const widthMultiplier = img.width / img.height;
                    const heightMultiplier = img.height / img.width;
                    const adjustedWidth = window.innerWidth * 0.9;
                    const width = 500 * widthMultiplier;
                    img.width = width > adjustedWidth ? adjustedWidth : width;
                    img.height =
                        width > adjustedWidth
                            ? adjustedWidth * heightMultiplier
                            : 500;
                    setcanvasDimensions({
                        width: img.width,
                        height: img.height,
                    });
                };
            }
        }
    }, [
        canvasDimensions,
        colorizedStrokeData,
        currentTractImage,
        currentTractImageColorized,
    ]);

    const onSelectTracts = (value) => {
        setTractInput((prev) => {
            const currentTracts = prev.split("+");
            const newTracts = value.split("+");
            const newArray = currentTracts.concat(newTracts);
            const arrayNoWhitespace = newArray.filter((tract) =>
                parseInt(tract)
            );
            const removeDuplicates = [...new Set(arrayNoWhitespace)];
            const sortedArray = removeDuplicates.sort((a, b) => a - b);
            const tractString = sortedArray.join("+");

            return tractString;
        });
    };

    const onDeselectTracts = (value) => {
        setTractInput((prev) => {
            const currentTracts = prev.split("+");
            const tractsToRemove = value.split("+");
            const tractsRemoved = currentTracts.filter(
                (tract) => !tractsToRemove.includes(tract)
            );
            const sortedArray = tractsRemoved.sort((a, b) => a - b);
            const tractString = sortedArray.join("+");

            return tractString;
        });
    };
    const fillCanvas = (
        e,
        offset,
        options = {
            updateInput: true,
        }
    ) => {
        const { updateInput } = options;
        let filled = false;
        const fillColor = { r: 127, g: 161, b: 68 };
        let canvas = canvasRef.current;

        var ctx = canvas.getContext("2d");

        var canvasOffset = canvas.getBoundingClientRect();
        var offsetX = canvasOffset.left;
        var offsetY = canvasOffset.top;
        const tractLocations = [];
        // get the mouse position
        let x = offset ? parseInt(e.clientX) : parseInt(e.clientX - offsetX);
        let y = offset ? parseInt(e.clientY) : parseInt(e.clientY - offsetY);

        // define the grid area
        // lines can extend beyond grid but
        // floodfill wont happen outside beyond the grid
        const gridRect = {
            x: 0,
            y: 0,
            width: canvasWidth,
            height: canvasHeight,
        };

        // fillData contains the floodfilled canvas data
        var fillData = ctx.getImageData(
            0,
            0,
            canvasDimensions.width,
            canvasDimensions.height
        );

        const getTractNumber = () => {
            e.preventDefault();

            if (
                x < gridRect.x + 5 ||
                x > gridRect.x + gridRect.width ||
                y < gridRect.y + 5 ||
                y > gridRect.y + gridRect.height
            ) {
                return;
            }

            const pixelPos = (y * canvasWidth + x) * 4;

            // get the pixel color under the mouse
            const { r, g, b } = getColors(pixelPos);

            const clickedTract = tractData.find((tract) => {
                const { r: tractR, g: tractG, b: tractB } = tract.color;
                return (
                    Math.abs(r - tractR) <= 10 &&
                    Math.abs(g - tractG) <= 10 &&
                    Math.abs(b - tractB) <= 10
                );
            });
            return clickedTract?.tract.toString();
        };

        const tractNumber = getTractNumber();

        if (tractNumber === undefined) return;

        if (tractPosData[tractNumber]) {
            tractPosData[tractNumber].forEach((pixelLoc) => {
                fillData.data[pixelLoc] = strokeData.data[pixelLoc];
                fillData.data[pixelLoc + 1] = strokeData.data[pixelLoc + 1];
                fillData.data[pixelLoc + 2] = strokeData.data[pixelLoc + 2];
                fillData.data[pixelLoc + 3] = 255;
            });
            ctx.putImageData(fillData, 0, 0);
            setTractPosData((prev) => {
                const tractsMinusThisOne = Object.keys(prev).reduce(
                    (object, key) => {
                        if (key !== tractNumber) {
                            object[key] = prev[key];
                        }
                        return object;
                    },
                    {}
                );
                return tractsMinusThisOne;
            });
            if (updateInput) onDeselectTracts(tractNumber.toString());
            return filled;
        }

        function matchStartColor(pixelPos, startR, startG, startB) {
            // get the color to be matched
            var r = strokeData.data[pixelPos],
                g = strokeData.data[pixelPos + 1],
                b = strokeData.data[pixelPos + 2],
                a = strokeData.data[pixelPos + 3];

            // If current pixel of the outline image is black-ish
            if (matchstrokeColor(r, g, b, a)) {
                return false;
            }

            // get the potential replacement color
            r = fillData.data[pixelPos];
            g = fillData.data[pixelPos + 1];
            b = fillData.data[pixelPos + 2];

            // If the current pixel matches the clicked color
            if (r === startR && g === startG && b === startB) {
                return true;
            }

            // If current pixel matches the new color
            if (r === fillColor.r && g === fillColor.g && b === fillColor.b) {
                return false;
            }

            return true;
        }

        function floodFill(startX, startY, startR, startG, startB) {
            var newPos;
            var x;
            var y;
            var pixelPos;
            var neighborLeft;
            var neighborRight;
            var pixelStack = [[startX, startY]];

            while (pixelStack.length) {
                newPos = pixelStack.pop();
                x = newPos[0];
                y = newPos[1];

                // Get current pixel position
                pixelPos = (y * canvasWidth + x) * 4;

                // Go up as long as the color matches and are inside the canvas
                while (
                    y >= 0 &&
                    matchStartColor(pixelPos, startR, startG, startB)
                ) {
                    y -= 1;
                    pixelPos -= canvasWidth * 4;
                }

                pixelPos += canvasWidth * 4;
                y += 1;
                neighborLeft = false;
                neighborRight = false;

                // Go down as long as the color matches and in inside the canvas
                while (
                    y <= canvasHeight - 1 &&
                    matchStartColor(pixelPos, startR, startG, startB)
                ) {
                    y += 1;

                    fillData.data[pixelPos] = fillColor.r;
                    fillData.data[pixelPos + 1] = fillColor.g;
                    fillData.data[pixelPos + 2] = fillColor.b;
                    fillData.data[pixelPos + 3] = 255;

                    tractLocations.push(pixelPos);

                    if (x > 0) {
                        if (
                            matchStartColor(
                                pixelPos - 4,
                                startR,
                                startG,
                                startB
                            )
                        ) {
                            if (!neighborLeft) {
                                // Add pixel to stack
                                pixelStack.push([x - 1, y]);
                                neighborLeft = true;
                            }
                        } else if (neighborLeft) {
                            neighborLeft = false;
                        }
                    }

                    if (x < canvasWidth - 1) {
                        if (
                            matchStartColor(
                                pixelPos + 4,
                                startR,
                                startG,
                                startB
                            )
                        ) {
                            if (!neighborRight) {
                                // Add pixel to stack
                                pixelStack.push([x + 1, y]);
                                neighborRight = true;
                            }
                        } else if (neighborRight) {
                            neighborRight = false;
                        }
                    }

                    pixelPos += canvasWidth * 4;
                }
            }
        }

        function matchstrokeColor(r, g, b, a) {
            // never recolor the initial black divider strokes
            // must check for near black because of anti-aliasing
            return r < 10 && g < 10 && b < 10 && a === 255;
        }
        // get the pixel colors under x,y
        function getColors(pixelPos) {
            return {
                r: colorizedStrokeData?.data[pixelPos],
                g: colorizedStrokeData?.data[pixelPos + 1],
                b: colorizedStrokeData?.data[pixelPos + 2],
                a: colorizedStrokeData?.data[pixelPos + 3],
            };
        }

        function paintAt(startX, startY) {
            // get the clicked pixel's [r,g,b,a] color data
            var pixelPos = (startY * canvasWidth + startX) * 4,
                r = fillData.data[pixelPos],
                g = fillData.data[pixelPos + 1],
                b = fillData.data[pixelPos + 2],
                a = fillData.data[pixelPos + 3];

            // this pixel's already filled
            if (r === fillColor.r && g === fillColor.g && b === fillColor.b) {
                return;
            }

            // this pixel is part of the original black image--don't fill
            if (matchstrokeColor(r, g, b, a)) {
                return;
            }

            // execute the floodfill
            floodFill(startX, startY, r, g, b);

            // put the colorized data back on the canvas
            ctx.putImageData(fillData, 0, 0);

            setTractPosData((prev) => {
                return {
                    ...prev,
                    [tractNumber]: tractLocations,
                };
            });
        }

        paintAt(x, y);
        if (updateInput) onSelectTracts(tractNumber.toString());
        filled = true;
        return filled;
    };

    return (
        <div>
            <div className="mb-4">
                <BiddingTabNav />
            </div>
            <div className="flex flex-col lg:flex-row lg:space-x-10 justify-center bg-white lg:py-8 lg:px-4 rounded-sm m-1">
                <div
                    className="flex flex-col justify-center"
                    style={{ width: canvasDimensions.width || 500 }}
                >
                    <h2 className="text-gray-600 font-bold mb-2.5">
                        TRACT MAP(S){" "}
                        <span className="font-normal text-sm">
                            Click on a tract to select.
                        </span>
                    </h2>

                    {!strokeData && (
                        <ContentLoader
                            speed={2}
                            width={500}
                            height={500}
                            viewBox="0 0 500 500"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                        >
                            <rect
                                x="0"
                                y="0"
                                rx="2"
                                ry="2"
                                width="400"
                                height="500"
                            />
                        </ContentLoader>
                    )}

                    <canvas
                        ref={canvasRef}
                        width={canvasDimensions.width}
                        height={canvasDimensions.height}
                        style={{
                            visibility: strokeData ? "visible" : "hidden",
                        }}
                        onMouseDown={(e) => fillCanvas(e)}
                    />

                    {currentTractImages.length > 1 && (
                        <div className="flex items-center">
                            <h2 className="text-gray-600 font-bold my-auto mr-2">
                                MAP
                            </h2>
                            <Pagination
                                count={currentTractImages.length}
                                onPageChange={(page) =>
                                    setCurrentTractIndex(page - 1)
                                }
                            />
                        </div>
                    )}
                </div>
                <div className="flex justify-center mt-5">
                    <TractMapPlaceBid
                        tractInput={tractInput}
                        onTractBoxChecked={(value, checked) => {
                            const filledTracts = [];
                            const unfilledTracts = [];
                            value.split("+").forEach((tractNum) => {
                                const tract = tractData.find(
                                    (t) =>
                                        t.tract.toString() ===
                                        tractNum.toString()
                                );
                                if (
                                    !tract ||
                                    !tract.hasOwnProperty("clickLocation")
                                ) {
                                    return checked
                                        ? onSelectTracts(tractNum)
                                        : onDeselectTracts(tractNum);
                                }
                                if (
                                    !checked ||
                                    (checked &&
                                        !tractInput
                                            .split("+")
                                            .includes(tractNum))
                                ) {
                                    const filled = fillCanvas(
                                        {
                                            clientX:
                                                tract.clickLocation.x -
                                                tract.clickLocation.offset.x,
                                            clientY:
                                                tract.clickLocation.y -
                                                tract.clickLocation.offset.y,
                                            preventDefault: () => {
                                                return;
                                            },
                                        },
                                        tract.clickLocation.offset,
                                        {
                                            updateInput: false,
                                        }
                                    );
                                    if (filled) {
                                        filledTracts.push(tractNum);
                                    } else {
                                        unfilledTracts.push(tractNum);
                                    }
                                }
                            });
                            if (filledTracts.length > 0) {
                                onSelectTracts(filledTracts.join("+"));
                            }
                            if (unfilledTracts.length > 0) {
                                onDeselectTracts(unfilledTracts.join("+"));
                            }
                        }}
                        setTractInput={setTractInput}
                    />
                </div>
            </div>
        </div>
    );
}
