import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import formatUSD from "../../utilities/formatUSD";
import suggestedBid from "../../utilities/suggestedBid";
import { useContext } from "react";
import { AdminAuctionContext } from "../context/AdminAuctionContext";
import { PlaceBidContext } from "./PlaceBidForm/context/PlaceBidContext";

export default function WhatIfCard({ whatIfData: whatIfDataInitial = null }) {
    const { currentAuctionBidSuggestions } = useContext(AdminAuctionContext);
    const { whatIf: whatIfDataBid } = useContext(PlaceBidContext);

    const whatIfData = whatIfDataInitial || whatIfDataBid;

    return (
        <>
            {!!whatIfData ? (
                <Transition
                    show={!!whatIfData}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <div className="rounded-lg admin-green-background p-6">
                        <div className="text-lg mb-4 font-bold">
                            Recommendations
                        </div>
                        <div className="flex items-center admin-green-color-light rounded-md p-1">
                            <div className="text-md">Current</div>
                            <div className="flex-1 text-right">
                                <b>{formatUSD(whatIfData.current)}</b>{" "}
                                <span className="text-xs">
                                    (
                                    {formatUSD(
                                        Math.round(
                                            whatIfData.current /
                                                parseFloat(
                                                    whatIfData.request_acres
                                                )
                                        )
                                    ) + `/ac`}
                                    )
                                </span>
                            </div>
                        </div>

                        <div className="flex items-center bg-gray-200 p-1 my-2 rounded-md">
                            <div className="text-md">To Match</div>
                            <div className="flex-1 text-right">
                                <b>{formatUSD(whatIfData.match)}</b>{" "}
                                <span className="text-xs">
                                    (
                                    {formatUSD(
                                        Math.round(
                                            whatIfData.match /
                                                parseFloat(
                                                    whatIfData.request_acres
                                                )
                                        )
                                    ) + `/ac`}
                                    )
                                </span>
                            </div>
                        </div>

                        <div className="flex items-center bg-blue-300 p-1 rounded-md">
                            <div className="text-md">Suggested</div>
                            <div className="flex-1 text-right">
                                <b>
                                    {formatUSD(
                                        suggestedBid(
                                            whatIfData.match,
                                            currentAuctionBidSuggestions
                                        )
                                    )}
                                </b>{" "}
                                <span className="text-xs">
                                    (
                                    {formatUSD(
                                        Math.round(
                                            suggestedBid(
                                                whatIfData.match,
                                                currentAuctionBidSuggestions
                                            ) /
                                                parseFloat(
                                                    whatIfData.request_acres
                                                )
                                        )
                                    ) + `/ac`}
                                    )
                                </span>
                            </div>
                        </div>
                    </div>
                </Transition>
            ) : null}
        </>
    );
}
