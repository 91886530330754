import { useContext } from "react";
import { AuctionContext } from "../../context/AuctionContext";
import { Table } from "../../../components/Table";
import formatUSD from "../../../utilities/formatUSD";
import formatSequential from "../../../utilities/formatSequential";
import PlaceBidErrorBoundry from "../PlaceBidErrorBoundry";
import CardHeader from "./components/CardHeader";
import BidInput from "./components/BidInput";
import PlaceBidActions from "./components/PlaceBidActions";
import PlaceBid from "./components/PlaceBid";
import TractInputReadOnly from "./components/TractInputReadOnly";

export default function TractMapPlaceBid({ tractInput, onTractBoxChecked }) {
    const { currentAuctionCombinations } = useContext(AuctionContext);

    return (
        <div className="flex flex-col w-full">
            <div className="h-64 overflow-y-auto hidden md:block">
                <PlaceBidTable
                    data={currentAuctionCombinations}
                    selectedTractArray={tractInput.split("+")}
                    onCheck={(value, checked) =>
                        onTractBoxChecked(value, checked)
                    }
                />
            </div>
            <div className="mt-0 mb-16 md:mb-0 md:mt-6">
                <PlaceBidErrorBoundry>
                    <div className="border border-gray-300 rounded p-2 pt-3">
                        <CardHeader />
                        <div className="space-y-2 justify-between mb-6">
                            <TractInputReadOnly />
                            <BidInput />
                            <PlaceBidActions />
                        </div>
                    </div>
                </PlaceBidErrorBoundry>
            </div>
            <PlaceBid />
        </div>
    );
}

function PlaceBidTable({ data, selectedTractArray, onCheck }) {
    return (
        <Table>
            <Table.Header>
                <Table.HeaderCell>
                    <span className="sr-only">Select Tract(s)</span>
                </Table.HeaderCell>
                <Table.HeaderCell>Alias</Table.HeaderCell>
                <Table.HeaderCell>Tract(s)</Table.HeaderCell>
                <Table.HeaderCell>Acres</Table.HeaderCell>
                <Table.HeaderCell>Current Bid</Table.HeaderCell>
                <Table.HeaderCell>PPA</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
                {data.map((tract) => {
                    const tractArr = tract.tractName.split("+");
                    return (
                        <Table.Row key={tract.tractName}>
                            <Table.Cell>
                                <div className="flex items-center h-5">
                                    <input
                                        id="settings-option-0"
                                        name="privacy_setting"
                                        type="checkbox"
                                        className="focus:ring-indigo-500 h-4 w-4 text-cta cursor-pointer border-gray-300"
                                        checked={tractArr.every((t) =>
                                            selectedTractArray.includes(t)
                                        )}
                                        onChange={(e) =>
                                            onCheck(
                                                tract.tractName,
                                                e.target.checked
                                            )
                                        }
                                    />
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                {tract.alias ? `${tract.alias} ` : ""}
                            </Table.Cell>
                            <Table.Cell>
                                {formatSequential(tract.tractName)}
                            </Table.Cell>
                            <Table.Cell>
                                {Math.round(parseFloat(tract.acres))}
                            </Table.Cell>
                            <Table.Cell>
                                {tract.highBid ? formatUSD(tract.highBid) : ""}
                            </Table.Cell>
                            <Table.Cell>
                                {tract.ppa ? `${formatUSD(tract.ppa)}/ac` : ""}
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
}
