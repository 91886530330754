import { XIcon } from "@heroicons/react/solid";
import { TrashIcon } from "@heroicons/react/outline";
import { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import formatSequential from "../../utilities/formatSequential";
import { UpdateBid, RemoveBid } from "../../services/BiddingService";

export default function EditBidModal({ open, bidData, onConfirm, onCancel }) {
    const [bidName, setBidName] = useState("");
    const [bidderNumber, setBidderNumber] = useState(0);
    const [bidAmount, setBidAmount] = useState(0);

    useEffect(() => {
        setBidName(bidData.bid_name);
        setBidderNumber(bidData.bidder_number);
        setBidAmount(bidData.bid_amount);
    }, [bidData]);

    const handleConfirm = (e) => {
        e.preventDefault();
        let bid = {
            bid_id: bidData.bid_id,
            tracts: bidName,
            bidder_number: bidderNumber,
            amount: bidAmount,
        };
        UpdateBid(bid);
        onConfirm();
    };

    return (
        <Modal isOpen={open}>
            <div className="min-h-screen px-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <form
                        onSubmit={handleConfirm}
                        className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl"
                    >
                        <XIcon
                            className="w-6 h-6 ml-1 float-right cursor-pointer"
                            onClick={onCancel}
                        />

                        <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                        >
                            Edit Bid
                        </Dialog.Title>
                        <hr className="my-4" />

                        <div className="flex my-2">
                            <div className="w-1/5 mx-1 py-2">
                                <label>Tracts</label>
                            </div>
                            <div className="flex-1">
                                <input
                                    defaultValue={formatSequential(
                                        bidData.bid_name
                                    )}
                                    onChange={(e) => setBidName(e.target.value)}
                                    className="border border-gray-300 rounded-lg p-2 w-full"
                                />
                            </div>
                        </div>
                        <div className="flex my-2">
                            <div className="w-1/5 mx-1 py-2">
                                <label>Bidder #</label>
                            </div>
                            <div className="flex-1">
                                <input
                                    defaultValue={bidData.bidder_number}
                                    onChange={(e) =>
                                        setBidderNumber(e.target.value)
                                    }
                                    className="border border-gray-300 rounded-lg p-2 w-full"
                                />
                            </div>
                        </div>
                        <div className="flex my-2">
                            <div className="w-1/5 mx-1 py-2">
                                <label>Bid Amount</label>
                            </div>
                            <div className="flex-1">
                                <input
                                    defaultValue={bidData.bid_amount}
                                    onChange={(e) =>
                                        setBidAmount(e.target.value)
                                    }
                                    className="border border-gray-300 rounded-lg p-2 w-full"
                                />
                            </div>
                        </div>

                        <div className="flex items-center mt-10 text-cta">
                            <div className="text-left w-1/2 item-center">
                                <div
                                    className="inline-block cursor-pointer"
                                    onClick={() => {
                                        RemoveBid(bidData.bid_id);
                                        onConfirm();
                                    }}
                                >
                                    <div className="flex">
                                        <TrashIcon className="w-6 -mt-1" />
                                        <div className="text-sm">
                                            Remove Bid
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-right w-1/2">
                                <Button htmlType="submit" rounded>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </form>
                </Transition.Child>
            </div>
        </Modal>
    );
}
