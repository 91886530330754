import { Fragment, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { AuthContext } from "../contexts/AuthContext";
import { useLocation } from "react-router-dom";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function UserNav() {
    const { loggedInUser } = useContext(AuthContext);
    const { pathname } = useLocation();
    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button className="ml-6 rounded-full flex items-center">
                            <span className="sr-only">Open options</span>
                            <span className="inline-flex items-center justify-center h-7 w-7 rounded-full bg-gray-500">
                                {loggedInUser.hasOwnProperty("first_name") &&
                                    loggedInUser.hasOwnProperty(
                                        "last_name"
                                    ) && (
                                        <span className="text-xs font-medium leading-none text-white">
                                            {loggedInUser.first_name.charAt(0)}
                                            {loggedInUser.last_name.charAt(0)}
                                        </span>
                                    )}
                            </span>
                        </Menu.Button>
                    </div>

                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                            <div className="py-1">
                                {loggedInUser.is_admin &&
                                    !pathname.includes("/admin/") && (
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="/admin"
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-700",
                                                        "admin-nav-link block px-4 py-2 text-sm"
                                                    )}
                                                >
                                                    Admin
                                                </a>
                                            )}
                                        </Menu.Item>
                                    )}
                                {loggedInUser.is_admin &&
                                    pathname.includes("/admin/") && (
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="/"
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-700",
                                                        "admin-nav-link block px-4 py-2 text-sm"
                                                    )}
                                                >
                                                    Public
                                                </a>
                                            )}
                                        </Menu.Item>
                                    )}
                                {loggedInUser.is_admin && (
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="/admin/active"
                                                className={classNames(
                                                    active
                                                        ? "bg-gray-100 text-gray-900"
                                                        : "text-gray-700",
                                                    "block px-4 py-2 text-sm"
                                                )}
                                            >
                                                Active Auctions
                                            </a>
                                        )}
                                    </Menu.Item>
                                )}
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="/logout"
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                "block w-full text-left px-4 py-2 text-sm"
                                            )}
                                        >
                                            Logout
                                        </a>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
}
