import { useContext, useState } from "react";
import AuthLayout from "./components/AuthLayout";
import { Link, Redirect } from "react-router-dom";
import Button from "../components/Button";
import Input from "../components/Input";
import Icon from "../components/Icon";
import { loginUser } from "../services/SessionService";
import { AuthContext } from "../contexts/AuthContext";
import { GlobalContext } from "../contexts/GlobalContext";
import Alert from "../components/Alert";

export default function Login() {
    const authContext = useContext(AuthContext);
    const globalContext = useContext(GlobalContext);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        try {
            const loggedInData = await loginUser({
                email: data.get("email"),
                password: data.get("password"),
            });
            authContext.setIsLoggedIn(loggedInData.user ? true : false);
            authContext.setLoggedInUser(loggedInData.user);
            globalContext.setGlobalContext((prev) => {
                return {
                    ...prev,
                    activeAuctions: loggedInData.active_auctions,
                };
            });
        } catch (error) {
            if (error.message === "Your email or password was not correct.") {
                setError(error.message);
            } else {
                setError("An error occurred. Please try again.");
            }
        }
    };

    if (authContext.isLoggedIn) {
        if (authContext.loggedInUser.isAdmin === 1) {
            return <Redirect to={"/admin"} />;
        } else {
            return <Redirect to={"/"} />;
        }
    }

    return (
        <AuthLayout title="Login">
            <form className="w-full" onSubmit={(e) => handleSubmit(e)}>
                {!!error && (
                    <Alert className="mb-8 mx-auto max-w-sm">{error}</Alert>
                )}
                {!error && (
                    <div className="-mt-8 mb-8">
                        <p className="text-center text-white font-medium">
                            Please log in with your schraderauction.com profile
                            below.
                        </p>
                    </div>
                )}
                <div className="max-w-xs mx-auto">
                    <div className="flex mb-10">
                        <Icon type="user" width="50px" className="mr-2" />
                        <Input
                            borderless
                            htmlType="email"
                            placeholder="Email"
                            required
                        />
                    </div>
                    <div className="flex mb-10">
                        <Icon
                            type="lock-closed"
                            width="50px"
                            className="mr-2"
                        />
                        <Input
                            borderless
                            htmlType="password"
                            placeholder="Password"
                            required
                        />
                    </div>
                    <Button
                        className="block mt-12 mx-auto font-secondary"
                        size="lg"
                        type="light"
                        htmlType="submit"
                        rounded
                    >
                        Sign In
                    </Button>
                    <Link
                        className="block mt-5 text-bold text-center text-sm text-white hover:text-gray-300 focus:outline-none"
                        to="/forgot-password"
                    >
                        Forgot your password?
                    </Link>
                    <Alert type="info" className="mb-8 mt-6 mx-auto max-w-sm">
                        <p className="text-xs font-medium">
                            Don't have a profile?{" "}
                            <a
                                className="underline font-bold"
                                href=" https://www.schraderauction.com/buyers/profile/"
                            >
                                Click here to create one
                            </a>
                            .
                            <br />
                            Still have questions? Please call:{"  "}
                            <a
                                className="underline font-bold"
                                href="tel:1-800-828-8282"
                            >
                                800-451-2709
                            </a>
                            .
                        </p>
                    </Alert>
                </div>
            </form>
        </AuthLayout>
    );
}
