import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import GlobalNav from "../components/GlobalNav";
import SidebarLayout from "../components/SidebarLayout";
import AdminSideNav from "./components/AdminSideNav";

import AdminAuctionsListView from "./pages/AdminAuctionsListView";
import Settings from "./pages/Settings";
import Active from "./pages/Active";
import AuctionInput from "./pages/AuctionInput";
import AdminAuctionContextProvider from "./context/AdminAuctionContext";

export default function Admin() {
    return (
        <SidebarLayout topnav={<GlobalNav />} sidenav={<AdminSideNav />}>
            <Switch>
                <Route path="/admin/auction-interface/:auctionID">
                    <AdminAuctionContextProvider>
                        <AuctionInput />
                    </AdminAuctionContextProvider>
                </Route>
                <Route path="/admin/auctions">
                    <AdminAuctionsListView />
                </Route>
                <Route path="/admin/settings">
                    <Settings />
                </Route>
                <Route path="/admin/active">
                    <Active />
                </Route>
                <Redirect from="/admin" to="/admin/auctions" />
            </Switch>
        </SidebarLayout>
    );
}
