import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import { useState, useEffect } from "react";
import AllAuctions from "../../services/AuctionService";
import Pager from "../../components/Pager";
import { format } from "date-fns";
import { Table } from "../../components/Table";
import WheelSpinner from "../../components/WheelSpinner";
import TableOptions from "../components/TableOptions";
import auctionPlaceholder from "../../images/auction-placeholder.jpg";

export default function AdminAuctionListView() {
    const [auctions, setAuctions] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [paginationData, setPaginationData] = useState();
    const [page, setPage] = useState(1);

    useEffect(() => {
        async function fetchAuctions() {
            try {
                let response = await AllAuctions({ page });
                setAuctions(response.data);
                setPaginationData(response.meta);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
        return fetchAuctions();
    }, [setAuctions, page]);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center mt-64 ">
                <WheelSpinner size="64" />
            </div>
        );
    }

    return (
        <>
            <AdminAuctionList
                auctions={auctions}
                paginationData={paginationData}
                past={false}
            />
            <Pager
                onClickPrevious={setPage}
                onClickNext={setPage}
                {...paginationData}
            />
        </>
    );
}

function AdminAuctionList({ auctions }) {
    return (
        <>
            <Table>
                <Table.Header>
                    <Table.HeaderCell>
                        <span className="sr-only">Auction Image</span>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <span className="text-sm uppercase font-medium tracking-wide">
                            Auction Name
                        </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <span className="text-sm uppercase font-medium tracking-wide">
                            Location
                        </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <span className="text-sm uppercase font-medium tracking-wide">
                            Date
                        </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <span className="sr-only">Actions</span>
                    </Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {auctions.map((auction) => (
                        <Table.Row key={`auction_${auction.auction_number}`}>
                            <Table.Cell className="w-56">
                                <div className="py-3">
                                    <img
                                        src={
                                            auction.image_src
                                                ? auction.image_src
                                                : auctionPlaceholder
                                        }
                                        alt={auction.image_alt}
                                        className="max-h-32 min-w-36 object-cover"
                                    />
                                </div>
                            </Table.Cell>
                            <Table.Cell className="w-80 py-4">
                                <div className="flex items-center space-x-3">
                                    <div className="flex-shrink-1 w-72">
                                        <div className="font-bold text-gray-800 whitespace-normal">
                                            <Link
                                                to={`/admin/auction-interface/${auction.id}/input`}
                                                className="hover:text-cta text-sm"
                                            >
                                                {auction.title}
                                            </Link>
                                        </div>
                                        <div className="text-gray-400 text-xs whitespace-normal">
                                            {auction.tagline}
                                        </div>
                                    </div>
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                <div className="whitespace-normal text-sm font-bold w-48">
                                    {auction.location}
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                <span className="px-3">
                                    <div className="text-base font-medium">
                                        {format(
                                            parseInt(
                                                auction.auction_date_time
                                            ) * 1000,
                                            "d MMM yyyy"
                                        )}
                                    </div>
                                    <div className="text-gray-400 font-bold">
                                        {auction.auction_time}{" "}
                                        {auction.auction_timezone}
                                    </div>
                                </span>
                            </Table.Cell>
                            <Table.Cell>
                                <span className="px-6 flex items-center justify-center space-x-2">
                                    <div className="self-center">
                                        <TableOptions auctionID={auction.id} />
                                    </div>
                                </span>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </>
    );
}
