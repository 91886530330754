import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import SchraderLogoPrimary from "../images/SchraderLogoPrimary.svg";
import UserNav from "./UserNav";

export default function GlobalNav() {
    const { pathname, search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const isMobile = searchParams.get("mobileapp") === "true" ? true : false;

    return (
        <div className={`${isMobile ? "hidden" : ""}`}>
            <div className="h-16 w-full flex-shrink-0 flex-grow flex bg-white shadow mb-4">
                <div className="flex-1 px-4 flex bg-white">
                    <div className="flex h-16 items-center justify-start px-1 md:px-3">
                        <Link
                            to={
                                pathname.includes("/admin/")
                                    ? "/admin/auctions"
                                    : "/auctions"
                            }
                        >
                            <img
                                src={SchraderLogoPrimary}
                                alt="Logo"
                                className="mr-8"
                            />
                        </Link>
                        {pathname.includes("/admin/") && (
                            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                                ADMIN
                            </span>
                        )}
                    </div>
                    <div className="flex items-center justify-end ml-auto flex-wrap">
                        <div className="inline-flex ml-4 relative">
                            <UserNav />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
