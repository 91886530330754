import { useContext, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { AuthContext } from "../contexts/AuthContext";
import apiClient from "../utilities/ApiClient";
import { CurrentBids } from "./ActiveAuctionService";

export const useGetAuctionBids = ({ auction_id }) => {
    return useQuery(
        ["currentBids", auction_id.toString()],
        () => CurrentBids(auction_id),
        {
            refetchOnWindowFocus: false,
        }
    );
};

export const useGetBidder = ({ auction_id }) => {
    const { loggedInUser } = useContext(AuthContext);
    return useQuery(
        "bidderId",
        () =>
            apiClient
                .get("auctions/get-bidder", {
                    params: {
                        contact_id: loggedInUser.id,
                        auction_id: auction_id,
                    },
                })
                .then((res) => {
                    if (res.data.bidder === null) {
                        return {};
                    }
                    return res.data.bidder;
                }),
        {
            refetchOnWindowFocus: false,
        }
    );
};

// {
//     auction_id: currentAuctionId,
//     tracts: tractInput,
//     amount: bidAmount * 1000,
//     bidder_id: bidder.bidder_id,
// };

export const usePlaceBid = () => {
    const queryCache = useQueryClient();
    const [canSubmit, setCanSubmit] = useState(true);

    return useMutation(
        (values) => {
            if (!canSubmit) throw new Error("Duplicate bid");
            setCanSubmit(false);
            return apiClient
                .post(`auctions/${values.auction_id}/place-bid`, values)
                .then((res) => res.data);
        },
        {
            onSettled: () => setTimeout(() => setCanSubmit(true), 1000),
            onError: (error) => {
                console.log(error);
            },
            onSuccess: ({ data }) => {
                const oldData = queryCache.getQueryData([
                    "currentBids",
                    data.bid_auction_id.toString(),
                ]);
                queryCache.setQueryData(
                    ["currentBids", data.bid_auction_id.toString()],
                    {
                        ...oldData,
                        data: {
                            ...oldData.data,
                            bids: [data, ...oldData.data.bids],
                        },
                    }
                );
            },
        }
    );
};
