import { format } from "date-fns";
import { useState, createContext, useEffect, useContext, useMemo } from "react";
import { Redirect, useParams } from "react-router-dom";
import apiClient from "../../utilities/ApiClient";
import { SingleAuction } from "../../services/AuctionService";
import { GlobalContext } from "../../contexts/GlobalContext";
import { useQuery, useQueryClient } from "react-query";
import {
    CurrentBids,
    CurrentTractCount,
} from "../../services/ActiveAuctionService";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import WheelSpinner from "../../components/WheelSpinner";
import { useToasts } from "react-toast-notifications";
import { UniqueCombinations } from "../../services/BiddingService";
import getAuctionTimezone, {
    getAuctionLongTimezone,
} from "../../utilities/getAuctionTimezone";
import { Aliases as AliasesSvc } from "../../services/ActiveAuctionService";
import { AuthContext } from "../../contexts/AuthContext";
import { AdminChatProvider } from "../components/AdminChat/context/AdminChatContext";
import useAuctionStartEndTime from "../../hooks/useAuctionStartEndTime";

const initialState = {
    currentAuctionTitle: null,
    currentAuctionImage: null,
    currentAuctionDate: null,
    currentAuctionId: null,
    currentAuctionTracts: null,
    currentAuctionBids: null,
    currentAuctionTractCount: null,
    currentAuctionAliases: null,
    currentAuctionData: null,
    currentAuctionIsLive: false,
    currentAuctionMessageCount: 0,
    currentAuctionOnlineUsers: 0,
    currentAuctionBiddingEngineHost: null,
    currentAuctionBiddingEnginePort: null,
    currentAuctionCombinations: null,
    currentAuctionStartTime: null,
    currentAuctionEndTime: null,
    currentAuctionExtension: null,
    currentAuctionPremium: null,
    newBidCount: 0,
    currentAuctionBidSuggestions: null,
    currentAuctionIsSingleTract: false,
    currentAuctionCompletionsToShow: 1,
    onlineAuctionClosed: false,
    auctionPendingClose: false,
};
export const AdminAuctionContext = createContext();

export default function AdminAuctionContextProvider({ children }) {
    const [auctionState, setAuctionState] = useState(initialState);
    const [auctionDataLoading, setAuctionDataLoading] = useState(true);
    const { setGlobalContext } = useContext(GlobalContext);
    const params = useParams();
    const { loggedInUser } = useContext(AuthContext);

    const makeQueryClient = useQueryClient();
    const { addToast, removeToast } = useToasts();

    const { currentAuctionBidOpen, currentAuctionBidClose } =
        useAuctionStartEndTime({
            auctionID: params.auctionID,
            auctionPendingClose: auctionState.auctionPendingClose,
            onAuctionClosed: () =>
                setAuctionState((p) => ({
                    ...p,
                    onlineAuctionClosed: true,
                    auctionPendingClose: false,
                })),
        });

    const currentAuctionAcres = useMemo(() => {
        if (!auctionState.currentAuctionTracts) return 0;
        return auctionState.currentAuctionTracts.reduce((acc, tract) => {
            return acc + parseFloat(tract.tract_acres);
        }, 0);
    }, [auctionState.currentAuctionTracts]);

    const {
        data: bidData,
        isLoading: bidsLoading,
        error: bidsError,
    } = useQuery(
        ["currentBids", auctionState.currentAuctionId],
        () => CurrentBids(params.auctionID),
        { refetchOnWindowFocus: false }
    );

    const {
        data: uniqueCombinations,
        isLoading: combinationsLoading,
        error: combinationsError,
        refetch: refetchCombinations,
    } = useQuery(
        ["combinations", auctionState.currentAuctionId],
        () => UniqueCombinations(params.auctionID).then((res) => res.bids),
        { refetchOnWindowFocus: false }
    );

    const bidChannel = useChannel(`bids-${params.auctionID}`);
    const auctionChannel = useChannel(`private-auction-${params.auctionID}`);
    const whatifsChannel = useChannel(`notifications`);

    useEffect(() => {
        (async () => {
            let aliases = await AliasesSvc(params.auctionID);
            setAuctionState((p) => ({
                ...p,
                currentAuctionAliases: aliases.aliases,
            }));
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (combinationsError || combinationsLoading) return;
        setAuctionState((p) => ({
            ...p,
            currentAuctionCombinations: uniqueCombinations,
        }));
    }, [uniqueCombinations, combinationsLoading, combinationsError]);

    useEvent(auctionChannel, "App\\Events\\CompletionsUpdating", () => {
        addToast(
            <div>
                <h2> Completions Updating... </h2>{" "}
            </div>,
            {
                appearance: "info",
                autoDismiss: true,
                id: "completions-processing",
            }
        );
    });

    useEvent(auctionChannel, "App\\Events\\BidRemoved", (data) => {
        addToast(
            <div>
                <h2> Bid Removed </h2>{" "}
            </div>,
            {
                appearance: "info",
                autoDismiss: true,
                id: "bid-removed",
            }
        );
    });

    useEvent(auctionChannel, "App\\Events\\CompletionsUpdated", (data) => {
        removeToast("completions-processing", () => {
            addToast(
                <div>
                    <h2> Completions Updated! </h2>{" "}
                </div>,
                {
                    appearance: "success",
                    autoDismiss: true,
                    id: "completions-updated",
                }
            );
        });
        setAuctionState((prev) => {
            return {
                ...prev,
                currentAuctionData: data.message.auction,
            };
        });
    });
    useEvent(bidChannel, "App\\Events\\BidApproved", (data) => {
        makeQueryClient.invalidateQueries(["currentBids", params.auctionID]);
        refetchCombinations();
    });
    useEvent(bidChannel, "App\\Events\\BidRejected", (data) => {
        refetchCombinations();
    });

    useEvent(auctionChannel, "App\\Events\\AuctionStarted", (data) => {
        //if(data.message.auction_id == auctionState.currentAuctionId) {
        setAuctionState((prev) => {
            return {
                ...prev,
                currentAuctionIsLive: true,
            };
        });
        //}
    });

    useEvent(auctionChannel, "App\\Events\\AliasesUpdated", (data) => {
        makeQueryClient.invalidateQueries(["currentBids", params.auctionID]);
        refetchCombinations();
        setAuctionState((p) => ({
            ...p,
            currentAuctionAliases: data.message.aliases,
        }));
    });

    useEvent(
        auctionChannel,
        "App\\Events\\GlobalChatMessageReceived",
        (data) => {
            setAuctionState((prev) => {
                return {
                    ...prev,
                    currentAuctionMessageCount:
                        data.message.auction_message_count,
                };
            });
        }
    );

    useEvent(auctionChannel, "App\\Events\\AuctionEnded", (data) => {
        setAuctionState((prev) => {
            return {
                ...prev,
                currentAuctionIsLive: false,
            };
        });
    });

    useEvent(bidChannel, "App\\Events\\BidReceived", (data) => {
        makeQueryClient.invalidateQueries(["currentBids", params.auctionID]);
    });

    useEffect(() => {
        async function checkIfAuctionIsLive() {
            if (auctionState?.currentAuctionId) {
                try {
                    let res = await apiClient.get(
                        `auctions/${auctionState.currentAuctionId}/status`
                    );

                    if (
                        res.data.message?.auction_id ===
                        parseInt(auctionState.currentAuctionId)
                    ) {
                        setAuctionState((prev) => {
                            return {
                                ...prev,
                                currentAuctionIsLive: true,
                            };
                        });
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }
        return checkIfAuctionIsLive();
    }, [auctionState.currentAuctionTitle, auctionState.currentAuctionId]);

    useEvent(
        bidChannel,
        "App\\Events\\AuctionTimeChange",
        ({ message: auction }) => {
            makeQueryClient.setQueryData(["start-end-time", params.auctionID], {
                auction_start: auction.auction_bid_open * 1000,
                auction_end: auction.auction_bid_close * 1000,
            });
            setAuctionState((state) => {
                return {
                    ...state,
                    onlineAuctionClosed: auction.auction_ended,
                };
            });
        }
    );

    useEffect(() => {
        if (!bidsError && !bidsLoading) {
            setAuctionState((prev) => {
                return {
                    ...prev,
                    currentAuctionBids: bidData.data,
                };
            });
        }
    }, [bidData, bidsError, bidsLoading]);

    useEffect(() => {
        (async () => {
            setAuctionDataLoading(true);
            const tractCount = await CurrentTractCount(params.auctionID);
            const auctionData = await SingleAuction(params.auctionID);
            setAuctionState((prev) => {
                return {
                    ...prev,
                    currentAuctionTitle: auctionData.auction_name,
                    currentAuctionImage: auctionData.featured_image?.src,
                    currentAuctionEarnest: auctionData.auction_em
                        ? parseFloat(`0.${auctionData.auction_em}`)
                        : 0.1,
                    currentAuctionPremium: auctionData.auction_bp
                        ? parseFloat(auctionData.auction_bp) / 100
                        : 0,
                    currentAuctionDate: format(
                        parseInt(auctionData.auction_date_time) * 1000,
                        "M/dd/yy h:mma"
                    ),
                    currentAuctionId: params.auctionID,
                    currentAuctionTracts: auctionData.tracts,
                    currentAuctionTractCount: tractCount.data,
                    currentAuctionData: auctionData,
                    currentAuctionMessageCount: auctionData.message_count,
                    currentAuctionBiddingEngineHost:
                        auctionData.auction_bidding_engine_host ?? "",
                    currentAuctionBiddingEnginePort:
                        auctionData.auction_bidding_engine_port ?? "",
                    currentAuctionExtension:
                        auctionData.auction_ext_minutes ?? "",
                    currentAuctionTimezone: getAuctionLongTimezone(
                        getAuctionTimezone(auctionData.auction_timezone)
                    ),
                    currentAuctionBidSuggestions: JSON.parse(
                        auctionData.auction_bid_increments
                    ),
                    currentAuctionIsSingleTract:
                        auctionData.tract_count <= 1 ? true : false,
                    currentAuctionCompletionsToShow:
                        auctionData.auction_input_completions ?? 1,
                };
            });
            setGlobalContext((prev) => {
                return {
                    ...prev,
                    currentAuctionTitle: auctionData.auction_name,
                    currentAuctionDate: format(
                        parseInt(auctionData.auction_date_time) * 1000,
                        "M/dd/yy h:mma"
                    ),
                };
            });
            setAuctionDataLoading(false);
        })();
        return () => {
            setGlobalContext((prev) => {
                return {
                    ...prev,
                    currentAuctionTitle: null,
                    currentAuctionDate: null,
                };
            });
        };
    }, [params, setGlobalContext]);

    if (loggedInUser.is_admin === false) {
        return <Redirect to="/" />;
    }

    if (!params.auctionID) {
        return <Redirect to="/admin/auctions" />;
    }

    if (auctionDataLoading) {
        return (
            <div className="flex items-center justify-center mt-64 ">
                <WheelSpinner size="64" />
            </div>
        );
    }

    return (
        <AdminAuctionContext.Provider
            value={{
                ...auctionState,
                currentAuctionAcres,
                auctionChannel,
                setAuctionState,
                whatifsChannel,
                bidChannel,
                currentAuctionEndTime: currentAuctionBidClose,
                currentAuctionStartTime: currentAuctionBidOpen,
            }}
        >
            <AdminChatProvider>{children}</AdminChatProvider>
        </AdminAuctionContext.Provider>
    );
}
