import React, { useContext, useState, useEffect, useRef } from "react";
import { AdminAuctionContext } from "../context/AdminAuctionContext";
import apiClient from "../../utilities/ApiClient";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import { useToasts } from "react-toast-notifications";
import { formatInTimeZone, format } from "date-fns-tz";
import BidIncrementModal from "../components/BidIncrementModal";

export default function Settings() {
    const [biddingEngineHost, setBiddingEngineHost] = useState("");
    const [biddingEnginePort, setBiddingEnginePort] = useState("");
    const [auctionStartTime, setAuctionStartTime] = useState("");
    const [auctionEndTime, setAuctionEndTime] = useState("");
    const [auctionExtension, setAuctionExtension] = useState("");
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [incrementModalOpen, setIncrementModalOpen] = useState(false);
    const [file, setFile] = useState("");
    const { addToast } = useToasts();
    const ref = useRef();

    const {
        currentAuctionStartTime,
        currentAuctionEndTime,
        currentAuctionExtension,
        currentAuctionBiddingEngineHost,
        currentAuctionBiddingEnginePort,
        currentAuctionId,
        currentAuctionTimezone,
    } = useContext(AdminAuctionContext);

    // https://stackoverflow.com/questions/19721439/download-json-object-as-a-file-from-browser
    const downloadObjectAsJson = (exportObj, exportName) => {
        var dataStr =
            "data:text/json;charset=utf-8," +
            encodeURIComponent(JSON.stringify(exportObj));
        var downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    };

    const resetFile = () => {
        ref.current.value = "";
    };

    const RenderProgressMessage = () => {
        if (!uploadInProgress) return "";

        return (
            <div className="rounded-xl bg-gray-100 p-4 mb-4 flex">
                <Spinner width={24} className="mr-4" />
                Import in progress. This may take a few seconds.
            </div>
        );
    };

    const uploadFile = () => {
        if (!file?.name) return;

        setUploadInProgress(true);

        const formData = new FormData();
        formData.append("file", file, file.name);

        (async () => {
            let res = await apiClient.post(
                `auctions/${currentAuctionId}/import`,
                formData
            );
            if (res.data.success) {
                addToast(
                    <div>
                        <h2>Data Imported</h2>
                    </div>,
                    {
                        appearance: "info",
                        autoDismiss: false,
                        id: "data-imported",
                    }
                );
                setUploadInProgress(false);
                resetFile();
            } else {
                addToast(
                    <div>
                        <h2>Problem Importing Data</h2>
                    </div>,
                    {
                        appearance: "error",
                        autoDismiss: false,
                        id: "data-error",
                    }
                );
            }
        })();
    };

    const updateAuctionStartStopTime = () => {
        (async () => {
            let data = {
                auction_start_time: auctionStartTime,
                auction_end_time: auctionEndTime,
                auction_ext_minutes: auctionExtension,
            };
            let res = await apiClient.put(
                `auctions/${currentAuctionId}/update-start-end-times`,
                data
            );
            if (res.data.success) {
                addToast(
                    <div>
                        <h2>Auction Start/End Times Update</h2>
                    </div>,
                    {
                        appearance: "info",
                        autoDismiss: true,
                        id: "auction-times",
                    }
                );
            } else {
                addToast(
                    <div>
                        <h2>Problem Updating Start/End Time</h2>
                    </div>,
                    {
                        appearance: "error",
                        autoDismiss: false,
                        id: "data-error",
                    }
                );
            }
        })();
    };

    const handleBiddingEngineUpdate = async () => {
        try {
            let res = await apiClient.put(
                `auctions/${currentAuctionId}/update-settings`,
                {
                    bidding_engine_host: biddingEngineHost,
                    bidding_engine_port: biddingEnginePort,
                }
            );
            if (res?.data?.success) {
                addToast(
                    <div>
                        <h2>Settings Updated</h2>
                    </div>,
                    {
                        appearance: "info",
                        autoDismiss: true,
                        id: "bidding-engine-updated",
                    }
                );
            }
        } catch (error) {
            addToast(
                <div>
                    <h2>Problem Updating Settings</h2>
                </div>,
                {
                    appearance: "info",
                    autoDismiss: false,
                    id: "bidding-engine-updated",
                }
            );
        }
    };

    const handleIncrementUpdate = async (increments) => {
        try {
            await apiClient.put(`auctions/${currentAuctionId}/bid-increments`, {
                increments,
            });
            addToast(
                <div>
                    <h2>Auction Bid Increments Updated</h2>
                </div>,
                {
                    appearance: "info",
                    autoDismiss: true,
                    id: "increments-updated",
                }
            );
        } catch (e) {
            console.log(e);
        } finally {
            setIncrementModalOpen(false);
        }
    };

    useEffect(() => {
        setBiddingEngineHost(currentAuctionBiddingEngineHost);
        setBiddingEnginePort(currentAuctionBiddingEnginePort);
    }, [setBiddingEngineHost, setBiddingEnginePort]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const startTime = formatInTimeZone(
            currentAuctionStartTime,
            currentAuctionTimezone,
            "yyyy-MM-dd HH:mm:ss"
        );
        const endTime = formatInTimeZone(
            currentAuctionEndTime,
            currentAuctionTimezone,
            "yyyy-MM-dd HH:mm:ss"
        );
        if (currentAuctionStartTime) setAuctionStartTime(startTime);
        if (currentAuctionEndTime) setAuctionEndTime(endTime);
    }, [currentAuctionStartTime, currentAuctionEndTime]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!currentAuctionExtension) return;
        setAuctionExtension(currentAuctionExtension);
    }, [currentAuctionExtension]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="space-y-8 divide-y divide-gray-200 mt-4 mb-8">
            <div className="space-y-8 divide-y divide-gray-200">
                <div>
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Bidding Engine
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Make changes to the location of the bidding engine
                        </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                            <label className="block text-sm font-medium text-gray-700">
                                Host
                            </label>
                            <div className="mt-1">
                                <input
                                    value={biddingEngineHost}
                                    onChange={(e) =>
                                        setBiddingEngineHost(e.target.value)
                                    }
                                    className="rounded border-solid border border-gray-300 w-full px-4 py-2 shadow-sm"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label className="block text-sm font-medium text-gray-700">
                                Port
                            </label>
                            <div className="mt-1">
                                <input
                                    value={biddingEnginePort}
                                    onChange={(e) =>
                                        setBiddingEnginePort(e.target.value)
                                    }
                                    className="rounded border-solid border border-gray-300 w-full px-4 py-2 shadow-sm"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <Button onClick={handleBiddingEngineUpdate}>
                                Save
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="pt-8">
                    <div>
                        <h4 className="text-lg leading-6 font-medium text-gray-900">
                            Import/Export Auction Data
                        </h4>
                        <p className="mt-1 text-sm text-gray-500">
                            Update or download the current auction data
                        </p>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                            <div className="flex space-x-6">
                                <p className="leading-6 text-sm text-gray-600 mb-4">
                                    Import Auction Data
                                </p>
                                <fieldset disabled={uploadInProgress}>
                                    <label className="block text-sm font-medium text-gray-700">
                                        JSON File:
                                    </label>
                                    <input
                                        type="file"
                                        onChange={(e) =>
                                            setFile(e.target.files[0])
                                        }
                                        ref={ref}
                                    />
                                    <Button
                                        disabled={uploadInProgress}
                                        onClick={() => uploadFile()}
                                    >
                                        Upload
                                    </Button>
                                </fieldset>
                                <RenderProgressMessage />
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <div className="flex space-x-6">
                                <p className="leading-6 text-sm text-gray-600  mb-4">
                                    Export Auction Data
                                </p>
                                <div className="mt-1">
                                    <Button
                                        onClick={async () => {
                                            let res = await apiClient.get(
                                                `auctions/${currentAuctionId}/export`
                                            );
                                            downloadObjectAsJson(
                                                res.data,
                                                currentAuctionId
                                            );
                                        }}
                                    >
                                        Download
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-8">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Edit Suggested Bid Increments
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Make changes to the suggested bid increments.
                        </p>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                            <div className="mt-1">
                                <Button
                                    onClick={() => setIncrementModalOpen(true)}
                                >
                                    Edit
                                </Button>
                            </div>
                            <BidIncrementModal
                                onSubmit={(increments) =>
                                    handleIncrementUpdate(increments)
                                }
                                onClose={() => setIncrementModalOpen(false)}
                                open={incrementModalOpen}
                            />
                        </div>
                    </div>
                </div>
                <div className="pt-8">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Online Only
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Edit the time that an auction is online. Auction
                            start/end time should be in{" "}
                            <span className="font-bold">
                                {currentAuctionTimezone}
                            </span>{" "}
                            time using a 24-hr time format (e.g.{" "}
                            {format(new Date(), "yyyy-MM-dd")} 08:30:00 or{" "}
                            {format(new Date(), "yyyy-MM-dd")} 22:30:00).
                        </p>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                            <label className="block text-sm font-medium text-gray-700">
                                Auction Start Time
                            </label>
                            <div className="mt-1">
                                <input
                                    value={auctionStartTime}
                                    placeholder="e.g.: 2021-09-30 09:00:00"
                                    onChange={(e) => {
                                        setAuctionStartTime(e.target.value);
                                    }}
                                    className="rounded border-solid border border-gray-300 w-full px-4 py-2 shadow-sm"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label className="block text-sm font-medium text-gray-700">
                                Auction End Time
                            </label>
                            <div className="mt-1">
                                <input
                                    value={auctionEndTime}
                                    placeholder="e.g.: 2021-10-09 17:30:00"
                                    onChange={(e) => {
                                        setAuctionEndTime(e.target.value);
                                    }}
                                    className="rounded border-solid border border-gray-300 w-full px-4 py-2 shadow-sm"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label className="block text-sm font-medium text-gray-700">
                                Auction Extension in Minutes
                            </label>
                            <div className="mt-1">
                                <input
                                    value={auctionExtension}
                                    placeholder="0"
                                    onChange={(e) => {
                                        setAuctionExtension(e.target.value);
                                    }}
                                    className="rounded border-solid border border-gray-300 w-full px-4 py-2 shadow-sm"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <Button onClick={updateAuctionStartStopTime}>
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
