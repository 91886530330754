import React, { useContext } from "react";
import { usePlaceBid } from "../../../../services/usePlaceBid";
import { AuctionContext } from "../../../context/AuctionContext";
import PlaceBidModal from "../../PlaceBidModal";
import { PlaceBidContext } from "../context/PlaceBidContext";
export default function PlaceBid({ tractInputRef = null }) {
    const { bidder, currentAuctionId } = useContext(AuctionContext);
    const {
        placeBidModalOpen,
        bidAmount,
        tractInput,
        bidAcres,
        resetAllContext,
        editContext,
        togglePlaceBidModal,
    } = useContext(PlaceBidContext);
    const placeBid = usePlaceBid();
    return (
        <PlaceBidModal
            open={placeBidModalOpen}
            bidder={bidder}
            bid={{
                bidAmount: bidAmount * 1000,
                tractName: tractInput,
                totalAcres: bidAcres,
            }}
            onConfirm={async () => {
                try {
                    await placeBid.mutateAsync({
                        auction_id: currentAuctionId,
                        tracts: tractInput,
                        amount: bidAmount * 1000,
                        bidder_id: bidder.bidder_id,
                    });
                    resetAllContext();
                } catch (error) {
                    editContext({ errorText: error?.response?.data?.error });
                } finally {
                    togglePlaceBidModal(false);
                    // The modal close animation causes this
                    // not to fire so we have to delay this :(
                    if (tractInputRef)
                        setTimeout(() => tractInputRef.current.focus(), 500);
                }
            }}
            onCancel={() => togglePlaceBidModal(false)}
        />
    );
}
