import React from "react";
import * as Sentry from "@sentry/browser";
import GlobalFallback from "./GlobalFallback";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.log("error catch");
        console.error(error, errorInfo);
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return !!this.props.fallback ? (
                this.props.fallback
            ) : (
                <GlobalFallback />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
