import React, { useContext } from "react";
import Button from "../../../../components/Button";
import classNames from "../../../../utilities/classNames";
import formatUSD from "../../../../utilities/formatUSD";
import { PlaceBidContext } from "../context/PlaceBidContext";

export default function PlaceBidActions() {
    const {
        biddingDisabled,
        togglePlaceBidModal,
        errorText,
        bidLeads,
        toLead,
        whatIf,
        handleLeadClick,
    } = useContext(PlaceBidContext);
    return (
        <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
            <div className="flex items-center space-x-2 sm:space-x-4">
                <Button
                    className={classNames(
                        "h-10",
                        biddingDisabled ? "cursor-not-allowed" : ""
                    )}
                    onClick={() => {
                        if (biddingDisabled) return;
                        togglePlaceBidModal(true);
                    }}
                    disabled={biddingDisabled}
                >
                    PLACE&nbsp;BID
                </Button>
                {whatIf && (
                    <Button
                        className="block sm:hidden h-10"
                        onClick={handleLeadClick}
                    >
                        BID {formatUSD(toLead)} TO LEAD
                    </Button>
                )}
            </div>
            {errorText && (
                <div
                    className={classNames(
                        bidLeads ? "text-green-700" : "text-red-700",
                        "text-xs md:text-sm h-6 mt-2 md:mt-0 text-center md:text-left"
                    )}
                >
                    {errorText}
                </div>
            )}
        </div>
    );
}
