import { format } from "date-fns";
import React from "react";

const ChatMessageUser = ({ messages, loggedInUser }) => {
    const isFromSelf = (message, userID) => {
        if (message.hasOwnProperty("bidder")) {
            try {
                if (message.bidder.user.contact_id === userID) {
                    return true;
                }
            } catch (e) {
                return false;
            }
        }
        return false;
    };

    return (
        <div className="flex flex-col">
            {messages.map((message, i) => (
                <React.Fragment key={message.message_id}>
                    <DateStamp
                        date={message.message_created_on}
                        prevDate={
                            i !== 0
                                ? messages[i - 1].message_created_on
                                : "01/01/1970" // Forces the first date to be displayed
                        }
                    />
                    {/* Red, right-aligned message */}
                    {(message.message_from_admin === 1 ||
                        isFromSelf(message, loggedInUser.id)) && (
                        <AdminMessage
                            message={message.message_body}
                            global={message.message_bidder_id === null}
                            timestamp={message.message_created_on}
                        />
                    )}
                    {/* Gray, left-aligned message */}
                    {message.message_from_admin === 0 &&
                        !isFromSelf(message, loggedInUser.id) && (
                            <UserMessage
                                message={message.message_body}
                                global={message.message_bidder_id === null}
                                timestamp={message.message_created_on}
                            />
                        )}
                </React.Fragment>
            ))}
        </div>
    );
};

const AdminMessage = ({ message, global, timestamp }) => (
    <div className="mb-4" key={message.message_id}>
        <div className="text-xs text-red-700 font-medium">ADMIN</div>
        <div className="p-4 box-border inline-block rounded-lg text-sm bg-cta text-white">
            {message}
        </div>
        <div className="text-xs mt-1">
            {global && (
                <span className="text-gray-500">Sent to Everyone at </span>
            )}
            <span className="text-gray-500">
                {format(new Date(timestamp), "h:mm aa")}
            </span>
        </div>
    </div>
);

const UserMessage = ({ message, name, timestamp }) => (
    <div className="mb-4 self-end">
        <div className="w-full flex flex-col items-end">
            <span className="text-gray-700 text-right text-xs font-medium">
                YOU
            </span>
            <div className="p-4 box-border inline-block rounded-lg text-sm bg-gray-200 text-gray-800 ml-auto">
                {message}
            </div>

            <div className="text-xs mt-1 pl-1 ml-auto w-full text-right">
                <span className="text-gray-500">
                    {format(new Date(timestamp), "h:mm aa")}
                </span>
            </div>
        </div>
    </div>
);

const DateStamp = ({ date, prevDate }) => {
    const formattedDate = format(new Date(date), "MMM d, yyyy");
    const formattedPrev = format(new Date(prevDate), "MMM d, yyyy");

    if (formattedDate === formattedPrev) {
        return null;
    }

    return (
        <div className="text-xs text-gray-500 w-full text-center">
            {formattedDate}
        </div>
    );
};

export default ChatMessageUser;
