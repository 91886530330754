import { createContext, useEffect } from "react";
import Pusher from "pusher-js";
import axios from "axios";
import { PusherProvider } from "@harelpls/use-pusher";

export const PusherContext = createContext();

export const PusherContextProvider = ({ children }) => {
    let authorizer = (channel, options) => {
        return {
            authorize: (socketId, callback) => {
                axios
                    .post(
                        `${process.env.REACT_APP_BASE_URL}/api/broadcasting/auth`,
                        {
                            socket_id: socketId,
                            channel_name: channel.name,
                        },
                        { withCredentials: true }
                    )
                    .then((res) => {
                        if (res.status !== 200) {
                            throw new Error(
                                `Received ${res.status} from broadcasting/auth`
                            );
                        }
                        return res;
                    })
                    .then((data) => {
                        callback(null, data.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        callback(
                            new Error(`Error calling auth endpoint: ${err}`),
                            {
                                auth: "",
                            }
                        );
                    });
            },
        };
    };

    const key = "F74FtNHcUj";
    const options = {
        broadcaster: "pusher",
        httpHost: window.location.hostname,
        wsHost: window.location.hostname,
        clientKey: key,
        cluster: "mt1",
        forceTLS: false,
        enabledTransports: ["ws", "wss"],
        disableStats: true,
        authorizer,
    };

    if (process.env.REACT_APP_ENV === "local") {
        options.wsPort = 6001;
        options.wssPort = 6001;
    } else {
        let port = window.location.port;
        if (!port) {
            port = window.location.protocol === "https:" ? "443" : "80";
        }

        options.wsPort = port;
        options.wssPort = port;
        options.forceTLS = window.location.protocol === "https:";
        options.wsPath = "/ws";
    }

    const pusher = new Pusher(key, options);

    useEffect(() => {
        return () => pusher.disconnect();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <PusherProvider {...options}>{children}</PusherProvider>;
};
