const formatUSD = (amount) => {
    const toUSD = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });
    return toUSD.format(amount).replace(/\D00(?=\D*$)/, "");
};

export default formatUSD;
