import { useState, createContext, useEffect } from "react";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import apiClient from "../utilities/ApiClient";

const initialState = {
    currentAuctionTitle: null,
    currentAuctionDate: null,
    isMobileSidebarOpen: false,
    activeAuctions: [],
};
export const GlobalContext = createContext(initialState);

export default function GlobalContextProvider({ children }) {
    const [globalContext, setGlobalContext] = useState(initialState);

    const activeAuctionsChannel = useChannel(`active-auctions`);

    useEvent(activeAuctionsChannel, "App\\Events\\ActiveAuctions", (data) => {
        setGlobalContext((prev) => {
            return {
                ...prev,
                activeAuctions: data.message.auctions,
            };
        });
    });

    useEffect(() => {
        (async () => {
            let res = await apiClient.get("auctions/active");
            setGlobalContext((prev) => {
                return {
                    ...prev,
                    activeAuctions: res.data,
                };
            });
        })();
    }, []);

    return (
        <GlobalContext.Provider
            value={{
                ...globalContext,
                setGlobalContext,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
}
