import { useContext, useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import { useLocation } from "react-router";
import { AuctionContext } from "../context/AuctionContext";
import Draggable from "react-draggable";
import {
    FastForwardIcon,
    MinusIcon,
    RewindIcon,
    VolumeOffIcon,
    VolumeUpIcon,
    ChevronDoubleUpIcon,
    PlayIcon,
    PauseIcon,
} from "@heroicons/react/solid";
import Button from "../../components/Button";

export default function PipViewer() {
    const [isVisible, setVisibility] = useState(false);
    const [hover, setHover] = useState(false);
    const [tapViewControls, setTapViewControls] = useState(false);
    const [minimize, setMinimize] = useState(true);
    const [isMuted, setMuted] = useState(false);
    const [playing, setPlaying] = useState(true);
    const [resetTimeout, setResetTimeout] = useState(false);
    const { pathname } = useLocation();
    const [timeOutId, setTimeOutId] = useState(null);
    const [videoCanAttach, setVideoCanAttach] = useState(false);

    const { currentAuctionStream, videoIsDetached, setAuctionState } =
        useContext(AuctionContext);
    const playerRef = useRef(null);

    useEffect(() => {
        if (!pathname.includes("bidding")) {
            return setVisibility(true);
        }
        if (pathname.includes("bidding") && videoIsDetached) {
            setVisibility(true);
            setVideoCanAttach(true);
            return setMinimize(true);
        }

        setVisibility(false);

        return () => setVisibility(false);
    }, [pathname, videoIsDetached]);

    const fastForward = () =>
        playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
    const rewind = () =>
        playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);

    const playerControlsDispaly = () => {
        setResetTimeout(true);
    };

    useEffect(() => {
        if (!resetTimeout) return;
        if (setTimeOutId) {
            clearTimeout(timeOutId);
            setTimeOutId(
                setTimeout(() => {
                    setTapViewControls(false);
                    setTimeOutId(null);
                }, 4000)
            );
        } else {
            setTimeOutId(
                setTimeout(() => {
                    setTapViewControls(false);
                    setTimeOutId(null);
                }, 4000)
            );
        }
        setResetTimeout(false);
    }, [resetTimeout, timeOutId]);

    return (
        <>
            {currentAuctionStream && isVisible && (
                <>
                    {minimize && (
                        <div className="fixed bottom-24 -left-16 z-10 px-4 py-2 rounded transform rotate-90 bg-brand-gray-200 cursor-pointer flex items-center text-center justify-items-center shadow">
                            <button onClick={() => setMinimize(false)}>
                                <div className="text-xs font-medium text-gray-600 hover:text-gray-900 cursor-pointer capitalize flex items-center justify-center space-x-2">
                                    <span>
                                        <ChevronDoubleUpIcon className="h-4 w-4" />
                                    </span>
                                    <span>Watch Live Stream</span>
                                </div>
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (!playing) {
                                        setPlaying(true);
                                        return setMuted(false);
                                    }
                                    setMuted(!isMuted);
                                }}
                            >
                                <div className="absolute -right-5 bottom-3 transform -rotate-90  bg-brand-gray-200 rounded-full p-2">
                                    {playing && (
                                        <>
                                            {isMuted ? (
                                                <VolumeOffIcon className="w-5 h-5 text-gray-600 hover:text-gray-900 cursor-pointer" />
                                            ) : (
                                                <VolumeUpIcon className="w-5 h-5 text-gray-600 hover:text-gray-900 cursor-pointer" />
                                            )}
                                        </>
                                    )}
                                    {!playing && (
                                        <PlayIcon className="w-5 h-5 text-gray-600 hover:text-gray-900 cursor-pointer" />
                                    )}
                                </div>
                            </button>
                        </div>
                    )}

                    <Draggable handle=".pip" bounds="body">
                        <div
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                            className={`fixed bottom-4 left-8 z-10 ${
                                minimize
                                    ? "w-0 h-0"
                                    : "w-screen-1/3 min-w-72 aspect-ratio-16-9"
                            } pip flex flex-col items-center text-center justify-items-center rounded-md overflow-hidden`}
                        >
                            <>
                                <ReactPlayer
                                    width="100%"
                                    height="100%"
                                    url={currentAuctionStream}
                                    controls={false}
                                    playing={playing}
                                    muted={isMuted}
                                    playsinline={true}
                                    config={{
                                        youtube: {
                                            playerVars: {
                                                controls: 0,
                                                modestbranding: 1,
                                                playsinline: 1,
                                            },
                                        },
                                    }}
                                    ref={playerRef}
                                />
                                <div
                                    className="w-screen-1/3 min-w-72 aspect-ratio-16-9 absolute top-0 z-10"
                                    onTouchStart={() => {
                                        setTapViewControls(!tapViewControls);
                                        playerControlsDispaly();
                                    }}
                                />

                                {(hover || tapViewControls || !playing) && (
                                    <>
                                        <div
                                            className="w-screen-1/3 min-w-72 aspect-ratio-16-9 absolute top-0 p-2 z-50"
                                            style={{
                                                background:
                                                    "rgba( 63, 63, 63, 0.7 )",
                                                boxShadow:
                                                    "0 8px 32px 0 rgba( 31, 38, 135, 1 )",
                                            }}
                                        >
                                            <div className="flex h-5 justify-between w-full z-20">
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setMinimize(true);
                                                    }}
                                                    onTouchStart={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setMinimize(true);
                                                        playerControlsDispaly();
                                                    }}
                                                    className="p-1 cursor-pointer bg-gray-200 rounded block z-50"
                                                    title="Minimize"
                                                >
                                                    <MinusIcon className="text-gray-500 h-3 w-3" />
                                                </button>
                                                <button
                                                    className="p-1 cursor-pointer bg-gray-200 rounded block z-50"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setMuted(!isMuted);
                                                    }}
                                                    onTouchStart={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setMuted(!isMuted);
                                                        playerControlsDispaly();
                                                    }}
                                                >
                                                    {isMuted ? (
                                                        <VolumeOffIcon className="w-3 h-3 text-gray-500 " />
                                                    ) : (
                                                        <VolumeUpIcon className="w-3 h-3 text-gray-500" />
                                                    )}
                                                </button>
                                            </div>
                                            <div className="absolute top-0 left-0 flex justify-center items-center space-x-2 w-screen-1/3 min-w-72 aspect-ratio-16-9 z-10">
                                                <button
                                                    onClick={rewind}
                                                    onTouchStart={() => {
                                                        rewind();
                                                        playerControlsDispaly();
                                                    }}
                                                >
                                                    <RewindIcon className="h-8 w-8 text-gray-200 text-opacity-90" />
                                                    <span className="text-xs text-gray-200 font-bold text-opacity-90">
                                                        15 sec
                                                    </span>
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setPlaying(!playing);
                                                    }}
                                                    onTouchStart={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setPlaying(!playing);
                                                        playerControlsDispaly();
                                                    }}
                                                >
                                                    {playing ? (
                                                        <PauseIcon className="h-20 w-20 text-gray-200 text-opacity-90" />
                                                    ) : (
                                                        <PlayIcon className="h-20 w-20 text-gray-200 text-opacity-90" />
                                                    )}
                                                </button>
                                                <button
                                                    onClick={fastForward}
                                                    onTouchStart={() => {
                                                        fastForward();
                                                        playerControlsDispaly();
                                                    }}
                                                >
                                                    <FastForwardIcon className="h-8 w-8 text-gray-200 text-opacity-90" />
                                                    <span className="text-xs text-gray-200 font-bold text-opacity-90">
                                                        15 sec
                                                    </span>
                                                </button>
                                            </div>
                                            {videoCanAttach && (
                                                <div className="flex h-5 justify-between absolute bottom-2 right-2 z-20">
                                                    <Button
                                                        type="secondary"
                                                        size="xs"
                                                        className="z-50"
                                                        onClick={() =>
                                                            setAuctionState(
                                                                (p) => {
                                                                    return {
                                                                        ...p,
                                                                        videoIsDetached: false,
                                                                    };
                                                                }
                                                            )
                                                        }
                                                        onTouchStart={() => {
                                                            setAuctionState(
                                                                (p) => {
                                                                    return {
                                                                        ...p,
                                                                        videoIsDetached: false,
                                                                    };
                                                                }
                                                            );
                                                            playerControlsDispaly();
                                                        }}
                                                    >
                                                        Attach Video
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </>
                        </div>
                    </Draggable>
                </>
            )}
        </>
    );
}
