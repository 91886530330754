// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
    switch (action.type) {
        // Edit context
        case "EDIT_CONTEXT":
            return {
                ...state,
                ...action.payload,
            };
        // Reset a state property to it's inital state
        case "RESET_ONE_CONTEXT":
            return {
                ...state,
                [action.key]: action.payload[action.key],
            };
        case "RESET_ALL_CONTEXT":
            return {
                ...action.payload,
            };
        case "RESET_SIDE_EFFECTS":
            return {
                ...state,
            };
        default:
            return state;
    }
};
