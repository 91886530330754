import { useEffect, useContext, useState, useRef } from "react";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import { AdminAuctionContext } from "../context/AdminAuctionContext";
import { Completions as CompletionsSvc } from "../../services/ActiveAuctionService";
import sortCompletions from "../../utilities/sortCompletions";
import CompletionSalesReportModal from "../components/CompletionSalesReportModal";
import PrintHeader from "../components/PrintHeader";
import ReactToPrint from "react-to-print";
import CompletionCard from "../components/CompletionCard";
import AuctionInactiveMessage from "../components/AuctionInactiveMessage";
import { useEvent } from "@harelpls/use-pusher";

export default function Completions() {
    const {
        currentAuctionId,
        currentAuctionTitle,
        currentAuctionDate,
        currentAuctionIsLive,
        auctionChannel,
    } = useContext(AdminAuctionContext);

    const [completionsData, setCompletionsData] = useState([]);
    const [salesReportData, setSalesReportData] = useState({});
    const [completionSalesReportModalOpen, setCompletionSalesReportModalOpen] =
        useState(false);
    const [completionsToShow, setCompletionsToShow] = useState(10);

    const refreshCompletions = async (value) => {
        let completions = await CompletionsSvc({
            auction_id: currentAuctionId,
            per_page: value,
        });
        setCompletionsData(sortCompletions(completions.data));
    };

    useEffect(() => {
        (async () => {
            let completions = await CompletionsSvc({
                auction_id: currentAuctionId,
                per_page: 10,
            });
            setCompletionsData(sortCompletions(completions.data));
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEvent(auctionChannel, "App\\Events\\CompletionsUpdated", () => {
        refreshCompletions(completionsToShow);
    });

    return (
        <>
            <iframe id="iframePrintContents" title="Print"></iframe>

            {!currentAuctionIsLive && <AuctionInactiveMessage />}

            <div className="mr-4">
                <div className="mb-10">
                    <div className="float-left text-lg bold">
                        Top Completions
                    </div>
                    <div className="float-right">
                        <Button
                            className="pt-4 pb-4"
                            onClick={() => {
                                if (completionsData.length > 0) {
                                    setSalesReportData(completionsData[0]);
                                    setCompletionSalesReportModalOpen(true);
                                }
                            }}
                        >
                            Generate Sales Report
                        </Button>
                    </div>
                    <div className="float-right mr-5">
                        <select
                            className="mt-1"
                            value={completionsToShow}
                            onChange={(e) => {
                                setCompletionsToShow(e.target.value);
                                refreshCompletions(e.target.value);
                            }}
                        >
                            <option>10</option>
                            <option>25</option>
                            <option>100</option>
                            <option value="999">All</option>
                        </select>
                    </div>
                    <div className="clear-both"></div>
                </div>

                {completionsData.map((completion, i) => (
                    <Completion
                        key={i}
                        completion={completion}
                        number={i + 1}
                    />
                ))}

                <CompletionSalesReportModal
                    open={completionSalesReportModalOpen}
                    salesReportData={salesReportData}
                    auctionName={currentAuctionTitle}
                    auctionDate={currentAuctionDate}
                    onCancel={() => setCompletionSalesReportModalOpen(false)}
                />
            </div>
        </>
    );
}

const Completion = ({ completion, number }) => {
    const cardRef = useRef();
    const { currentAuctionTitle } = useContext(AdminAuctionContext);
    return (
        <div
            className="print"
            id={`completion-${completion.completion_id}`}
            key={completion.completion_id}
        >
            <div className="text-right mb-1">
                <ReactToPrint
                    trigger={() => (
                        <Icon
                            type="print"
                            width="24px"
                            className="mr-2 float-right cursor-pointer no-print"
                            color="brand-gray-500"
                        />
                    )}
                    content={() => cardRef.current}
                />
                <div className="clear-both"></div>
            </div>
            <div ref={cardRef} className="tableEl">
                <PrintHeader
                    auction={`${currentAuctionTitle} Auction`}
                    title={`Completion ${number}`}
                />
                <CompletionCard completion={completion} />
            </div>
        </div>
    );
};
