import { useContext } from "react";
import { Link } from "react-router-dom";
import { AdminAuctionContext } from "../context/AdminAuctionContext";

export default function AuctionInactiveMessage() {
    const { currentAuctionId } = useContext(AdminAuctionContext);

    return (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-10">
            <div className="flex items-center">
                <div className="flex-shrink-0">
                    <svg
                        className="h-5 w-5 text-yellow-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                            clipRule="evenodd"
                        />
                    </svg>
                </div>
                <p className="text-sm text-yellow-700 ml-2">
                    Auction is not currently running.{" "}
                </p>
                <div className="ml-auto">
                    <Link
                        to={`/admin/auction-interface/${currentAuctionId}/rebuild`}
                        className="font-bold text-sm inline-block rounded-full text-white bg-yellow-400 px-4 py-1 hover:bg-yellow-500 hover:text-white"
                    >
                        Start Auction
                    </Link>
                </div>
            </div>
        </div>
    );
}
