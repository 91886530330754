import React, { useContext } from "react";
import { AdminAuctionContext } from "../../../context/AdminAuctionContext";
import PlaceBidModal from "../../PlaceBidModal";
import { PlaceBidContext } from "../context/PlaceBidContext";
import usePlaceAdminBid from "../hooks/usePlaceAdminBid";

export default function PlaceBid() {
    const { currentAuctionId } = useContext(AdminAuctionContext);
    const {
        placeBidModalOpen,
        bidAmount,
        bidderNumber,
        tractInputFormatted,
        bidAcres,
        togglePlaceBidModal,
        tractRef,
    } = useContext(PlaceBidContext);
    const placeBid = usePlaceAdminBid();
    return (
        <PlaceBidModal
            open={placeBidModalOpen}
            onConfirm={() =>
                placeBid({
                    auction_id: currentAuctionId,
                    tracts: tractInputFormatted,
                    amount: bidAmount,
                    bidder_number: bidderNumber,
                })
            }
            onCancel={() => {
                togglePlaceBidModal(false);
                // Unfortanately the modal close animation will prevent focus
                // from being set on the input so we have to delay it a bit
                setTimeout(() => tractRef.current.focus(), 500);
            }}
            bid={{
                bidder_number: bidderNumber,
                tract_name: tractInputFormatted,
                total_acres: bidAcres,
                bid_amount: bidAmount * 1000,
            }}
        />
    );
}
