import { useContext } from "react";
import TractInput from "./components/TractInput";
import BidDetails from "./components/BidDetails";
import BidInput from "./components/BidInput";
import BidderInput from "./components/BidderInput";
import classNames from "../../../utilities/classNames";
import PlaceBid from "./components/PlaceBid";
import { PlaceBidContext } from "./context/PlaceBidContext";
import PlaceBidActions from "./components/PlaceBidActions";

export default function PlaceBidForm({ detailsOnBottom = false }) {
    const { errorText, biddingDisabled, togglePlaceBidModal } =
        useContext(PlaceBidContext);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (biddingDisabled) return;
        return togglePlaceBidModal(true);
    };

    return (
        <form onSubmit={handleSubmit}>
            <fieldset>
                <TractInput />
                <div className="flex">
                    <div className={detailsOnBottom ? "w-full" : "w-2/3"}>
                        <BidInput />
                        <BidderInput />
                        <div className="text-red-700 text-xs h-4">
                            {errorText}
                        </div>
                        <div
                            className={classNames(
                                detailsOnBottom && "hidden",
                                "mt-2"
                            )}
                        >
                            <PlaceBidActions />
                        </div>
                    </div>
                    <div
                        className={classNames(
                            detailsOnBottom && "hidden",
                            "w-1/3 ml-4"
                        )}
                    >
                        <BidDetails />
                    </div>
                </div>
                <div
                    className={classNames(
                        !detailsOnBottom && "hidden",
                        "w-full mb-2"
                    )}
                >
                    <BidDetails />
                </div>
                <div className={classNames(!detailsOnBottom && "hidden")}>
                    <PlaceBidActions />
                </div>
            </fieldset>
            <PlaceBid />
        </form>
    );
}
