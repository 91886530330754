import { useContext, useState, useEffect } from "react";
import Button from "../../components/Button";
import { Table } from "../../components/Table";
import { AdminAuctionContext } from "../context/AdminAuctionContext";
import { WhatIf as WhatIfSvc } from "../../services/ActiveAuctionService";
import formatUSD from "../../utilities/formatUSD";
import formatSequential from "../../utilities/formatSequential";
import { Aliases as AliasesSvc } from "../../services/ActiveAuctionService";
import suggestedBid from "../../utilities/suggestedBid";
import WhatIfCard from "../components/WhatIfCard";
import Spinner from "../../components/Spinner";
import AuctionInactiveMessage from "../components/AuctionInactiveMessage";

export default function WhatIf() {
    const {
        currentAuctionId,
        currentAuctionBidSuggestions,
        currentAuctionIsLive,
    } = useContext(AdminAuctionContext);

    const [whatIfData, setWhatIfData] = useState("");
    const [auctionAliases, setAuctionAliases] = useState([]);
    const [aliasWaitSeconds, setAliasWaitSeconds] = useState(0);
    const [tempAlias, setTempAlias] = useState("");
    const [tempAliasBidString, setTempAliasBidString] = useState("");
    const [bidTracts, setBidTracts] = useState("");
    const [aliasesPending, setAliasesPending] = useState(false);

    useEffect(() => {
        (async () => {
            let aliases = await AliasesSvc(currentAuctionId);
            setAuctionAliases(aliases.aliases);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTractsChange = (e) => setBidTracts(e.target.value);

    const getRowClassName = (i, tract) => {
        let className = "";
        if (i % 2 !== 0) {
            className += "bg-brand-gray-200";
        }
        if (tract.replace(/[a-z]/gi, "") === bidTracts.replace(/[a-z]/gi, "")) {
            className += " highlightRow";
        }

        return className;
    };

    const inputIsAlias = (input) => {
        let output = input;
        let returnString = "";
        if (output.match(/[a-z]/i)) {
            output = output.toUpperCase();
        } else {
            setAliasesPending(false);
        }
        auctionAliases.forEach((alias) => {
            if (output === alias.bid_alias) {
                returnString = output + alias.bid_name;
            }
        });
        if (returnString) {
            if (returnString.match(/^[A-Za-z\s]+/)) {
                return `${
                    returnString.match(/^[A-Za-z\s]+/)[0]
                }${formatSequential(returnString.replace(/[^+.\d]/g, ""))}`;
            }
            return formatSequential(returnString);
        }
        return false;
    };

    const autoCompleteAlias = (input) => {
        let output = input;
        if (output.match(/[a-z]/i)) {
            output = output.toUpperCase();
        }
        auctionAliases.forEach((alias) => {
            if (output === alias.bid_alias) {
                output = output + alias.bid_name;
            }
        });
        return output;
    };

    const handleKeyDown = (e) => {
        const allowedKeys = [
            "+",
            "-",
            ".",
            "Backspace",
            "ArrowLeft",
            "ArrowRight",
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "Tab",
            "Enter",
        ];

        if (e.key.toLowerCase() === "enter") {
            calculateWhatif();
            setAliasWaitSeconds(0);
            setAliasesPending(false);
            return true;
        } else {
            setWhatIfData("");
        }

        if (allowedKeys.includes(e.key)) {
            setTempAliasBidString("");
            setAliasWaitSeconds(0);
        }

        if (e.key.match(/[a-z]/i)) {
            setAliasWaitSeconds(2);
            setTimeout(() => {
                let alias = inputIsAlias(e.target.value);
                if (alias !== false) {
                    setTempAliasBidString(alias);
                } else {
                    setAliasesPending(false);
                }
                setTempAlias(e.target.value);
            }, 200);
        } else {
            let input;
            if (e.key.toLowerCase() === "backspace" && e.target.value === "") {
                input = "";
            } else {
                if (e.key.match(/[a-z]/i)) {
                    input = autoCompleteAlias(e.target.value);
                } else {
                    input = e.target.value;
                    setAliasesPending(false);
                }
            }
            setBidTracts(input);
        }
    };

    useEffect(() => {
        if (aliasWaitSeconds > 0) {
            setAliasesPending(true);
        } else {
            setAliasesPending(false);
        }
    }, [aliasWaitSeconds]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (aliasWaitSeconds <= 1 && tempAlias !== "") {
                if (tempAliasBidString !== "") {
                    setBidTracts(tempAliasBidString);
                    setTempAlias("");
                    setAliasWaitSeconds(0);
                    setAliasesPending(false);
                }
            } else {
                if (aliasWaitSeconds > 2) {
                    setAliasWaitSeconds(2);
                } else {
                    if (aliasWaitSeconds <= 0) {
                        setAliasWaitSeconds(0);
                    } else {
                        setAliasWaitSeconds(aliasWaitSeconds - 0.4);
                    }
                }
            }
        }, 200);
        return () => {
            clearTimeout(timer);
        };
    }, [aliasWaitSeconds, tempAliasBidString, tempAlias]);

    const calculateWhatif = async () => {
        let whatIf = await WhatIfSvc({
            auction_id: currentAuctionId,
            tracts: bidTracts,
        });
        let data = whatIf.message;
        setWhatIfData(data);
    };

    return (
        <>
            {!currentAuctionIsLive && <AuctionInactiveMessage />}

            <div className="flex">
                <div className="w-1/3">
                    <h2 className="font-bold text-xl">Bid Information</h2>
                    <div className="flex items-center mt-4">
                        <div className="pb-6">Tract(s)</div>
                        <div className="px-4 flex-1 max-w-xl">
                            <div className="w-3/4 flex-1 relative">
                                {aliasesPending && (
                                    <div className="absolute right-4 top-3">
                                        <Spinner color="#333" width="16" />
                                    </div>
                                )}
                                <div></div>
                                <input
                                    className="w-full p-2 rounded-md border-solid border-gray-200 border"
                                    value={formatSequential(bidTracts)}
                                    onChange={handleTractsChange}
                                    onKeyDown={(e) => {
                                        handleKeyDown(e);
                                    }}
                                />
                                <span className="text-gray-500 text-xs">
                                    (examples: ‘1’ or ‘2-4’ or ‘3+5-7’ or ‘A’
                                    alias)
                                </span>
                            </div>
                        </div>
                    </div>
                    <Button
                        className="mt-4 py-3"
                        onClick={() => {
                            if (!aliasesPending) {
                                calculateWhatif();
                            }
                        }}
                        disabled={aliasesPending}
                    >
                        Generate
                    </Button>
                </div>

                <div className="w-1/3">
                    <div className="rounded-lg bg-gray-100 px-4 py-2">
                        <div className="mb-2 font-medium">Bid Details</div>
                        <div className="flex items-center bg-gray-200 p-2 rounded-md">
                            <div>Acres:</div>
                            <div className="flex-1 text-right">
                                <b>{whatIfData?.request_acres}</b>
                            </div>
                        </div>
                        <div className="flex items-center p-3">
                            <div>PPA:</div>
                            <div className="flex-1 text-right">
                                <b>
                                    {whatIfData.match > 0 && (
                                        <>
                                            {whatIfData.match /
                                                whatIfData?.request_acres &&
                                            suggestedBid(
                                                whatIfData.match,
                                                currentAuctionBidSuggestions
                                            ) /
                                                whatIfData?.request_acres !==
                                                Infinity
                                                ? formatUSD(
                                                      Math.round(
                                                          suggestedBid(
                                                              whatIfData.match,
                                                              currentAuctionBidSuggestions
                                                          ) /
                                                              whatIfData?.request_acres
                                                      )
                                                  )
                                                : ""}
                                            /ac
                                        </>
                                    )}
                                </b>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-1/3 ml-8">
                    <WhatIfCard whatIfData={whatIfData} />
                </div>
            </div>
            <br />
            {whatIfData !== "" && whatIfData?.best_comp?.bids?.length > 0 && (
                <>
                    <h2 className="text-xl font-bold mb-2">
                        Highest completion including what if
                    </h2>
                    <Table className="rounded-xl bg-white p-2 border border-brand-gray-200 border-solid">
                        <Table.Header className="bg-transparent">
                            <Table.HeaderCell className="align-top py-4 text-base text-center">
                                Alias
                            </Table.HeaderCell>
                            <Table.HeaderCell className="align-top py-4 text-base text-center">
                                Tracts
                            </Table.HeaderCell>
                            <Table.HeaderCell className="align-top py-4 text-base text-center">
                                Acres
                            </Table.HeaderCell>
                            <Table.HeaderCell className="align-top py-4 text-base text-center">
                                Bidder
                            </Table.HeaderCell>
                            <Table.HeaderCell className="align-top py-4 text-base text-center">
                                Bid Amount
                            </Table.HeaderCell>
                            <Table.HeaderCell className="align-top py-4 text-base text-center">
                                PPA
                            </Table.HeaderCell>
                        </Table.Header>
                        <Table.Body className="bg-transparent">
                            {whatIfData.best_comp.bids
                                .sort(
                                    (a, b) =>
                                        a.tracts.match(/^[0-9\s]+/)[0] -
                                        b.tracts.match(/^[0-9\s]+/)[0]
                                )
                                .map((bid, i) => (
                                    <Table.Row
                                        className={getRowClassName(
                                            i,
                                            bid.tracts
                                        )}
                                        key={i}
                                    >
                                        <Table.Cell className="align-top py-2 text-base text-center">
                                            {bid.alias !== bid.acres
                                                ? bid.alias
                                                : ""}
                                        </Table.Cell>
                                        <Table.Cell className="align-top py-2 text-base text-center">
                                            {bid.tracts_friendly}
                                        </Table.Cell>
                                        <Table.Cell className="align-top py-2 text-base text-center">
                                            {bid.acres}
                                        </Table.Cell>
                                        <Table.Cell className="align-top py-2 text-base text-center">
                                            {bid.bidder_color && (
                                                <div
                                                    className="bidderColorBlock"
                                                    style={{
                                                        backgroundColor: `${bid.bidder_color}`,
                                                    }}
                                                ></div>
                                            )}
                                            {!bid.bidder_color &&
                                                bid.bidder_number && (
                                                    <div
                                                        className="bidderColorBlock"
                                                        style={{
                                                            backgroundColor: `#ccc`,
                                                        }}
                                                    ></div>
                                                )}
                                            <div>
                                                {bid.bidder_number && <>#</>}
                                                {bid.bidder_number}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell className="align-top py-2 text-base text-center">
                                            {formatUSD(bid.amount)}
                                        </Table.Cell>
                                        <Table.Cell className="align-top py-2 text-base text-center">
                                            {formatUSD(
                                                Math.round(
                                                    bid.amount / bid.acres
                                                )
                                            )}
                                            /ac
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                        </Table.Body>
                    </Table>
                </>
            )}
        </>
    );
}
