import apiClient from "../utilities/ApiClient";

export const AllAuctions = async (params) => {
    try {
        let res = await apiClient.get("auctions", { params: params });
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const ActiveAuctions = async () => {
    try {
        let res = await apiClient.get("auctions/active/full");
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const DestroyBiddingEngine = async (port) => {
    try {
        let res = await apiClient.delete(
            `auctions/${port}/kill-bidding-engine`
        );
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const SingleAuction = async (auctionID) => {
    try {
        let res = await apiClient.get(`auctions/${auctionID}`);
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export default AllAuctions;
