import React from "react";
import SideNav, { Item } from "../../components/SideNav";

export default function AdminSideNav() {
    // TODO:  clicking the link back to /admin/auctions from a specific auction details page doesn't do anything
    return (
        <SideNav>
            <Item link="/admin/auction-interface">Dashboard</Item>
            <Item link="/admin/auctions">Auctions</Item>
            <Item link="/admin/settings">Settings</Item>
        </SideNav>
    );
}
