import React, { useContext } from "react";
import { PlaceBidContext } from "../context/PlaceBidContext";
import Button from "../../../../components/Button";
import formatUSD from "../../../../utilities/formatUSD";

export default function TractInputReadOnly() {
    const { tractInputFormatted, toLead, handleLeadClick, whatIf } =
        useContext(PlaceBidContext);
    return (
        <div className="flex items-center justify-between">
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="w-full h-full">
                    <p className="text-brand-gray-500 text-sm">
                        Selected Tracts:
                    </p>

                    {tractInputFormatted ? (
                        <p>{tractInputFormatted}</p>
                    ) : (
                        <p className="text-brand-gray-600 font-bold text-sm">
                            No Tract(s) Selected
                        </p>
                    )}
                </div>
            </div>
            {whatIf && (
                <Button
                    className="hidden sm:inline-flex"
                    onClick={handleLeadClick}
                >
                    BID {formatUSD(toLead)} TO LEAD
                </Button>
            )}
        </div>
    );
}
