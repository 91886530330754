import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { GlobalContext } from "../contexts/GlobalContext";
import Icon from "./Icon";

export default function SideNav({ className, children, ...props }) {
    return (
        <div className={`h-full bg-gradient-gray ${className}`} {...props}>
            <ul className="pt-5">{children}</ul>
        </div>
    );
}

export const Item = ({ children, link, className, ...props }) => {
    const isSelected = "bg-brand-gray-700 bg-opacity-50 border-brand-gray-900";
    const location = useLocation();
    const { isMobileSidebarOpen, setGlobalContext } = useContext(GlobalContext);

    return (
        <Link
            onClick={() => {
                if (isMobileSidebarOpen)
                    setGlobalContext((prev) => {
                        return { ...prev, isMobileSidebarOpen: false };
                    });
            }}
            to={(location) => ({ ...location, pathname: link })}
        >
            <li
                className={`flex items-center py-3.5 px-4 my-2.5 text-white border-0 border-l-4 border-transparent
            hover:bg-brand-gray-700 hover:bg-opacity-50 hover:border-black 
            ${location.pathname.includes(link) ? isSelected : ""} ${className}`} // TODO: change style to fit theme
                {...props}
            >
                {children}
                <Icon
                    type="chevron-right"
                    className="ml-auto "
                    width="20"
                    color="brand-gray-500"
                />
            </li>
        </Link>
    );
};
