const sortCompletions = (completions) => {
    // sort completion bids by tract
    completions.forEach((completion, i) => {
        let bids = [];
        completion.bids.forEach((bid) => {
            if (bid.bid_name.includes("+")) {
                bid.sortOn = bid.bid_name.substr(0, bid.bid_name.indexOf("+"));
            } else {
                bid.sortOn = bid.bid_name;
            }
            bids.push(bid);
        });

        bids.sort(function (a, b) {
            return a.sortOn - b.sortOn;
        });

        completions[i].bids = bids;
    });
    return completions;
};

export const sortCompletion = (completion) => {
    let bids = [];
    if (completion.bids) {
        completion.bids.forEach((bid) => {
            if (bid.bid_name.includes("+")) {
                bid.sortOn = bid.bid_name.substr(0, bid.bid_name.indexOf("+"));
            } else {
                bid.sortOn = bid.bid_name;
            }
            bids.push(bid);
        });
    }
    bids.sort(function (a, b) {
        return a.sortOn - b.sortOn;
    });

    return { ...completion, bids };
};

export default sortCompletions;
