import { useContext, useEffect, useState, useRef, useCallback } from "react";
import ChatMessageUser from "./ChatMessageUser";
import { AuthContext } from "../../contexts/AuthContext";
import apiClient from "../../utilities/ApiClient";
import { AuctionContext } from "../context/AuctionContext";
import Icon from "../../components/Icon";
import { useEvent } from "@harelpls/use-pusher";
import { XIcon } from "@heroicons/react/solid";

const ChatUser = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [audioPlaying, setAudioPlaying] = useState(false);
    const [unread, setUnread] = useState(0);
    const { loggedInUser } = useContext(AuthContext);
    const [userBidderID, setUserBidderID] = useState(null);
    const [canChat, setCanChat] = useState(false);
    const { currentAuctionId, chatChannel, auctionChannel } =
        useContext(AuctionContext);
    const [messages, setMessages] = useState([]);
    const chatWindow = useRef(null);

    const toggleChatWindow = () => {
        setShowPopup(!showPopup);
        setUnread(0);
        setTimeout(function () {
            scrollChatWindow();
        }, 100);
    };

    const fetchUserMessages = useCallback(async () => {
        if (userBidderID === null) return;

        let req = { bidder_id: userBidderID };
        try {
            let res = await apiClient.get("chat/" + currentAuctionId, {
                params: req,
            });
            setMessages(res.data.messages);
            scrollChatWindow();
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }, [userBidderID, currentAuctionId]);

    const fetchUserBidderID = useCallback(async () => {
        if (!currentAuctionId) return;

        try {
            let params = {
                contact_id: loggedInUser.id,
                auction_id: currentAuctionId,
            };
            let response = await apiClient.get("auctions/get-bidder-id", {
                params: params,
            });
            if (response.data.bidder_id) {
                setCanChat(true);
            }
            setUserBidderID(response.data.bidder_id);
        } catch (error) {
            console.log(error);
        }
    }, [currentAuctionId, loggedInUser.id]);

    const addMessage = useCallback((new_message) => {
        setMessages((oldmessages) => [...oldmessages, new_message]);
        scrollChatWindow();
    }, []);

    // init messages by grabbing from db
    useEffect(() => {
        (async () => {
            await fetchUserBidderID();
            await fetchUserMessages(userBidderID);
        })();
    }, [userBidderID, fetchUserBidderID, fetchUserMessages]);

    const playNewChatSound = useCallback(async () => {
        // Safari does not suppor audio playback without a user interaction
        // This handles that error. Maybe we toast on Safari?
        try {
            if (audioPlaying) return;
            setAudioPlaying(true);
            const audio = new Audio(
                window.location.origin + "/audio/new-message.mp3"
            );
            await audio.play();
        } catch (error) {
            console.log(error);
            console.log("Browser does not support programmatic audio play");
        } finally {
            setAudioPlaying(false);
        }
    }, [audioPlaying]);

    const newMessageRecieved = (data) => {
        addMessage(data.message);
        if (!data.message.message_from_admin) return;
        if (!showPopup) setUnread((p) => p + 1);
        playNewChatSound();
    };

    useEvent(chatChannel, "App\\Events\\ChatMessageReceived", (data) =>
        newMessageRecieved(data)
    );
    useEvent(auctionChannel, "App\\Events\\ChatMessageReceived", (data) =>
        newMessageRecieved(data)
    );

    const scrollChatWindow = () => {
        let messageWindow = document.getElementById("messageWindow");
        if (messageWindow) {
            messageWindow.scrollTop = messageWindow.scrollHeight;
        }
    };

    // post new message to server
    const postMessage = async (data) => {
        try {
            await apiClient.post("chat/save", data);
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    };

    // if enter key pressed, prep message and call postMessage to send to server
    const handleKeyPress = (e) => {
        if (e.charCode === 13) {
            let message = e.target.value;
            let dateTime = new Date()
                .toISOString()
                .slice(0, 19)
                .replace("T", " "); // mysql YYYY-MM-DD h:i:s equivelant

            let data = {
                message: {
                    message_auction_id: currentAuctionId,
                    message_body: message,
                    message_from_admin: 0,
                    message_read: 0,
                    message_bidder_id: userBidderID,
                    message_bidder_contact_id: loggedInUser.id,
                    message_created_on: dateTime,
                },
            };
            try {
                postMessage(data);
                setTimeout(function () {
                    e.target.value = "";
                }, 50);
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <>
            {canChat === true && (
                <div className="chatPopup" ref={chatWindow}>
                    {showPopup && (
                        <div
                            style={{ zIndex: 1000 }}
                            className="fixed h-screen md:h-auto max-w-full w-screen md:w-350 bottom-0 right-0 md:bottom-14 md:right-8 shadow-lg bg-white md:rounded-lg"
                        >
                            <div className="flex justify-between w-full bg-gray-200 p-5 md:rounded-t-lg text-gray-500">
                                <div className="">
                                    <div className="text-xs">Chatting With</div>
                                    <div className="text-base text-gray-600">
                                        Admin
                                    </div>
                                </div>
                                <div>
                                    <button onClick={toggleChatWindow}>
                                        <XIcon className="text-gray-500 w-6 h-6" />
                                    </button>
                                </div>
                            </div>
                            <div className="flex flex-col h-full">
                                <div
                                    className="p-5 h-3/4 md:h-80 md:max-h-80 overflow-x-hidden overflow-y-scroll"
                                    id="messageWindow"
                                >
                                    <ChatMessageUser
                                        messages={messages}
                                        loggedInUser={loggedInUser}
                                    />
                                </div>
                                <div className="border-t border-gray-200 h-1/4">
                                    <textarea
                                        onKeyPress={handleKeyPress}
                                        className="w-full border-0 text-gray-600 text-sm focus:outline-none outline-none chat-input"
                                        placeholder="Your message..."
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    )}
                    <div
                        onClick={toggleChatWindow}
                        className="hidden md:flex fixed bottom-4 right-8 z-10 px-4 py-1 rounded bg-cta cursor-pointer items-center text-center justify-items-center shadow-lg"
                    >
                        {unread > 0 && (
                            <div className="absolute -top-3.5 -left-2  bg-success text-white rounded-full h-6 w-auto leading-none flex items-center justify-center p-2">
                                {unread}
                            </div>
                        )}
                        <Icon type="chat-bubble" width="32" />
                        <p className="text-white font-b">Chat Now</p>
                    </div>
                    <div
                        onClick={toggleChatWindow}
                        className={`${
                            showPopup ? "hidden" : "flex"
                        } md:hidden fixed bottom-2 right-2 z-10 px-2 py-2 rounded-full shadow-lg bg-cta cursor-pointer items-center text-center justify-items-center`}
                    >
                        {unread > 0 && (
                            <div className="absolute -top-3.5 -left-2 bg-success text-white rounded-full h-6 w-auto leading-none flex items-center justify-center p-2">
                                {unread}
                            </div>
                        )}
                        <Icon type="chat-bubble" width="32" />
                    </div>
                </div>
            )}
        </>
    );
};

export default ChatUser;
