const uniqueColors = [
    "#FF0000",
    "#FFFF00",
    "#0000FF",
    "#FF9900",
    "#00FF00",
    "#9900FF",
    "#FF00FF",
    "#999999",
    "#006600",
    "#996600",
    "#66CC66",
    "#3399CC",
    "#FF9999",
    "#009900",
    "#99CCFF",
    "#990000",
    "#CC9999",
    "#009999",
    "#003366",
    "#FF6699",
    "#0066CC",
    "#CC9966",
    "#CCFF99",
    "#CCCCCC",
    "#CC3399",
    "#99FF33",
    "#33CCFF",
    "#5D76CB",
    "#6E5160",
    "#FFCFAB",
    "#EFDECE",
    "#8A795D",
    "#78A8C3",
    "#CE8054",
    "#AAF0D1",
    "#FCD975",
    "#BAB86C",
    "#DC5D58",
    "#51BCD8",
    "#CDA4DE",
    "#CD9575",
    "#CD4A4C",
    "#C5E384",
    "#644A3C",
    "#C5D0E6",
    "#B4674D",
    "#DC579B",
    "#67290F",
    "#D8CE3D",
    "#FFAACC",
    "#FF7F49",
    "#FF496C",
    "#9F9F07",
    "#FC2847",
    "#FD7C6E",
    "#464444",
    "#8E4585",
    "#87A96B",
    "#78DBE2",
    "#714B23",
    "#6B9094",
    "#EBC7DF",
    "#EDD19C",
    "#CE6C4E",
    "#AA5F98",
    "#E3256B",
    "#DBD7D2",
    "#FF48D0",
    "#E8B40A",
    "#EFBC88",
    "#5D7F4A",
    "#CCAA41",
    "#A8ABBB",
    "#9ACEEB",
    "#52267F",
    "#82CEA6",
    "#1164B4",
    "#4EA6BB",
    "#17806D",
    "#1FCECB",
    "#7366BD",
    "#BE9150",
    "#DE5D83",
    "#FFA474",
    "#FFBCD9",
    "#FFCF48",
    "#FFFF66",
    "#FF2B2B",
    "#A76678",
    "#ED655C",
    "#FC89AC",
    "#F0E891",
    "#78H1A9",
    "#A2ADD0",
    "#C364C5",
    "#6B4154",
    "#32537F",
    "#CC6666",
    "#30BA8F",
    "#263135",
    "#45CEA2",
    "#DEAA88",
    "#6DAE81",
    "#C0448F",
    "#8F509D",
    "#1974D2",
    "#FF6E4A",
    "#FFA343",
    "#88DC04",
    "#96415D",
    "#CEFF1D",
    "#0E8465",
    "#3FFFCC",
    "#608B80",
    "#F75394",
    "#FDBCB4",
    "#E10295",
    "#76FF7A",
    "#9AE3E0",
    "#6BB205",
    "#1CA9C9",
    "#3BB08F",
    "#F78FA7",
    "#FF43A4",
    "#C87A68",
    "#CACA0D",
    "#FC74FD",
    "#235C24",
    "#607A79",
    "#B58426",
    "#808080",
    "#FEBA40",
    "#6718EE",
    "#06FD01",
    "#AF07ED",
    "#F0D740",
    "#3B5B7F",
    "#1C68C0",
    "#D7CE11",
    "#D30CFE",
    "#18FE12",
    "#4011BE",
    "#F3C581",
    "#A9733F",
    "#0451EE",
    "#6DDF01",
    "#FE04ED",
    "#79FB42",
    "#021F7E",
    "#9DB0C1",
    "#F88B11",
    "#4B3CFE",
    "#12ED13",
    "#C901BD",
    "#DFF482",
    "#24303E",
    "#3199EF",
    "#EAA201",
    "#BA29EC",
    "#0AF743",
    "#5C027C",
    "#FCE8C2",
    "#8C4410",
    "#0181FE",
    "#8CB813",
    "#FC19BC",
    "#5CFD83",
    "#0A073D",
    "#BAD9EF",
    "#EA5A01",
    "#3169EB",
    "#24CC44",
    "#DF0D7B",
    "#C9FEC3",
    "#12100F",
    "#4BC6FE",
    "#F87114",
    "#9D53BB",
    "#02DE84",
    "#79053C",
    "#FEFCF0",
    "#6D1E01",
    "#04B1EB",
    "#A98945",
    "#F33D7A",
    "#40ECC4",
    "#18010F",
    "#D3F4FE",
    "#D72F15",
    "#1C9BBA",
    "#3BA086",
    "#F02A3B",
    "#AFF6F1",
    "#060101",
    "#67E9EA",
    "#FE4246",
    "#7F8379",
    "#01B7C5",
    "#981A0E",
    "#F9FDFE",
    "#500615",
    "#0FDAB9",
    "#C45887",
    "#E36B3A",
    "#28CBF1",
    "#2C0E01",
    "#E7FEE9",
    "#BF1047",
    "#0CC877",
    "#566FC6",
    "#FB540E",
    "#92DDFE",
    "#010516",
    "#86FCB7",
    "#FD1D88",
    "#62B339",
    "#0787F2",
    "#B43F01",
    "#EDEBE9",
    "#360148",
    "#20F576",
    "#DB2DC7",
    "#CE9C0D",
    "#159FFE",
    "#452B17",
    "#F5F6B6",
    "#A30189",
    "#03EA38",
    "#7341F2",
    "#FE8501",
    "#73B5E8",
    "#031B49",
    "#A3FC75",
    "#F506C8",
    "#45DB0D",
    "#1557FE",
    "#CE6D17",
    "#DBCAB5",
    "#1F0E8B",
    "#36FE37",
    "#EE0FF3",
    "#B4C901",
    "#076EE7",
    "#62564A",
    "#FDDC74",
    "#8506C9",
    "#01FC0C",
    "#921CFE",
    "#FBB418",
    "#5685B4",
    "#0C408C",
    "#BFEA36",
    "#E701F3",
    "#2CF601",
    "#282CE7",
    "#E39E4B",
    "#C49D73",
    "#0F2DCA",
    "#50F50C",
    "#F901FE",
    "#97EB19",
    "#013FB3",
    "#80868D",
    "#FEB435",
    "#671CF4",
    "#06FC01",
    "#AF05E6",
    "#F0DC4D",
    "#3B5571",
    "#1C6FCB",
    "#D7C80B",
    "#D30FFE",
    "#18FE1A",
    "#400EB2",
    "#F3CA8E",
    "#A96C34",
    "#0457F4",
    "#6DDA01",
    "#FE06E5",
    "#79FD4E",
    "#021B70",
    "#9EB6CC",
    "#F7840B",
    "#4B42FE",
    "#12E91A",
    "#C901B1",
    "#DFF68F",
    "#242B33",
    "#319FF5",
    "#EA9B02",
    "#BA2EE4",
    "#0AF54F",
    "#5C016F",
    "#FCECCD",
    "#8B3E0A",
    "#0188FD",
    "#8CB21B",
    "#FC1DB0",
    "#5CFC91",
    "#0A0532",
    "#BADDF5",
    "#EA5302",
    "#3170E4",
    "#24C750",
    "#E0106E",
    "#C9FECE",
    "#110D0A",
    "#4BCCFD",
    "#F86A1C",
    "#9D59AE",
    "#02D992",
    "#7A0731",
    "#FEFDF6",
    "#6D1A02",
    "#04B7E3",
    "#A98251",
    "#F3436C",
    "#40E8CF",
    "#180109",
    "#D3F7FD",
    "#D72A1D",
    "#1CA1AD",
    "#3B9A93",
    "#F02F30",
    "#AFF4F6",
    "#060102",
    "#68ECE2",
    "#FE3C52",
    "#7F8A6B",
    "#01B0D0",
    "#981E09",
    "#F9FBFD",
    "#50041D",
    "#0FDEAC",
    "#C45294",
    "#E3722F",
    "#28C5F6",
    "#2C1102",
    "#E7FEE1",
    "#BF0D53",
    "#0CCD6A",
    "#5669D1",
    "#FB5B08",
    "#91D8FD",
    "#01071E",
    "#86FDAB",
    "#FD1996",
    "#61B92E",
    "#0880F7",
    "#B44502",
    "#EDE7E1",
    "#360255",
    "#20F769",
    "#DB28D2",
    "#CEA308",
    "#1598FC",
    "#45311F",
    "#F5F3AA",
    "#A30197",
    "#03ED2D",
    "#743BF7",
    "#FE8B03",
    "#73AFE0",
    "#031F56",
    "#A3FB68",
    "#F504D3",
    "#45E007",
    "#1550FC",
    "#CE7420",
    "#DBC4A9",
    "#1F1298",
    "#36FE2C",
    "#EE0CF8",
    "#B4CE03",
    "#0767DF",
    "#625C57",
    "#FDD766",
    "#8508D4",
    "#01FD07",
    "#9218FC",
    "#FBBA21",
    "#567FA8",
    "#0C4699",
    "#BFE62B",
    "#E702F8",
    "#2CF803",
    "#2827DE",
    "#E3A458",
    "#C49665",
    "#0F32D4",
    "#50F207",
    "#F901FC",
    "#97EE21",
    "#013AA6",
    "#808D9A",
    "#FEAD2A",
    "#6721F9",
    "#06FB03",
    "#AF04DD",
    "#F0E159",
    "#3B4F64",
    "#1C75D5",
    "#D7C306",
    "#D313FB",
    "#18FE22",
    "#400BA5",
    "#F3D09C",
    "#A96529",
    "#045EF9",
    "#6ED504",
    "#FE08DC",
    "#79FE5A",
    "#021763",
    "#9EBCD6",
    "#F77D06",
    "#4B48FB",
    "#12E523",
    "#C902A4",
    "#DFF89D",
    "#232628",
    "#31A6F9",
    "#EA9504",
    "#BA33DC",
    "#0AF25C",
    "#5C0162",
    "#FCEFD7",
    "#8B3806",
    "#018FFB",
    "#8CAC24",
    "#FC22A3",
    "#5CFA9E",
    "#0A0327",
    "#BAE2FA",
    "#EA4D04",
    "#3177DB",
    "#24C15D",
    "#E01360",
    "#C9FED8",
    "#110A05",
    "#4BD1FB",
    "#F86425",
    "#9D60A2",
    "#02D49F",
    "#7A0926",
    "#FEFEFA",
    "#6D1605",
    "#04BDDA",
    "#A97B5E",
    "#F3495F",
    "#40E4D9",
    "#180205",
    "#D3F9FA",
    "#D72526",
    "#1CA8A0",
    "#3B93A0",
    "#F03526",
    "#AFF1FA",
    "#060105",
    "#68F0D9",
    "#FE375F",
    "#7F905E",
    "#01AADA",
    "#982305",
    "#F9FAFA",
    "#500326",
    "#0FE39F",
    "#C44BA2",
    "#E37925",
    "#28C0FB",
    "#2C1405",
    "#E7FED8",
    "#BF0A60",
    "#0CD25D",
    "#5662DB",
    "#FB6104",
    "#91D3FA",
    "#010927",
    "#86FE9E",
    "#FD15A3",
    "#61BF24",
    "#087AFB",
    "#B44B06",
    "#EDE3D7",
    "#360362",
    "#20F95C",
    "#DC23DC",
    "#CEA904",
    "#1591F9",
    "#453628",
    "#F5F09D",
    "#A301A4",
    "#03F123",
    "#7435FB",
    "#FE9206",
    "#73A8D6",
    "#032463",
    "#A3F95A",
    "#F503DC",
    "#45E404",
    "#154AF9",
    "#CE7A29",
    "#DBBE9C",
    "#1F15A5",
    "#36FE22",
    "#EE09FB",
    "#B4D406",
    "#0760D5",
    "#626364",
    "#FDD259",
    "#850ADD",
    "#01FE03",
    "#9214F9",
    "#FBC02A",
    "#56789A",
    "#0C4CA6",
    "#BFE221",
    "#E703FC",
    "#2CFA07",
    "#2822D5",
    "#E3AB65",
    "#C49058",
    "#0F38DE",
    "#50EF03",
    "#F901F8",
    "#97F12B",
    "#013499",
    "#8094A7",
    "#FEA721",
    "#6725FC",
    "#06F907",
    "#AF02D4",
    "#F0E566",
    "#3B4857",
    "#1C7CDF",
    "#D7BD03",
    "#D316F8",
    "#18FE2C",
    "#400998",
    "#F3D5A9",
    "#A95F20",
    "#0464FC",
    "#6ED007",
    "#FE0BD3",
    "#79FE68",
    "#021356",
    "#9EC2E0",
    "#F77603",
    "#4B4EF7",
    "#12E12D",
    "#C90497",
    "#DFFAAA",
    "#23211F",
    "#31ACFC",
    "#EA8E08",
    "#BA39D2",
    "#0AEF69",
    "#5C0155",
    "#FCF2E0",
    "#8B3302",
    "#0196F7",
    "#8CA52E",
    "#FC2696",
    "#5CF8AB",
    "#0A021E",
    "#BAE6FD",
    "#EA4708",
    "#317ED1",
    "#24BB6A",
    "#E01753",
    "#C9FDE1",
    "#110802",
    "#4BD6F7",
    "#F85D2F",
    "#9D6694",
    "#02CFAC",
    "#7A0B1D",
    "#FEFEFD",
    "#6D1209",
    "#04C3D0",
    "#A9746B",
    "#F34F52",
    "#40E0E2",
    "#180402",
    "#D3FBF6",
    "#D72030",
    "#1CAE93",
    "#3B8CAD",
    "#F03A1D",
    "#AEEEFD",
    "#060109",
    "#68F3CF",
    "#FE316C",
    "#7F9751",
    "#01A4E3",
    "#982802",
    "#F9F8F6",
    "#500231",
    "#0FE792",
    "#C445AE",
    "#E3801C",
    "#28BAFD",
    "#2D180A",
    "#E7FDCE",
    "#BF076E",
    "#0CD750",
    "#565BE4",
    "#FB6802",
    "#91CEF5",
    "#010C32",
    "#86FE91",
    "#FD11B0",
    "#61C51B",
    "#0873FD",
    "#B4510A",
    "#EDDFCD",
    "#36046F",
    "#20FB4F",
    "#DC1FE4",
    "#CEB002",
    "#158BF5",
    "#463C33",
    "#F5ED90",
    "#A301B1",
    "#03F41A",
    "#7430FE",
    "#FE990B",
    "#73A2CC",
    "#032970",
    "#A4F74E",
    "#F502E5",
    "#45E801",
    "#1544F4",
    "#CE8134",
    "#DBB88E",
    "#1F19B2",
    "#36FD1A",
    "#EE07FE",
    "#B4D90B",
    "#075ACB",
    "#626A71",
    "#FDCC4D",
    "#850DE6",
    "#01FE01",
    "#9210F4",
    "#FBC635",
    "#56718D",
    "#0C53B3",
    "#BFDE19",
    "#E705FE",
    "#2CFC0C",
    "#281ECA",
    "#E3B173",
    "#C4894B",
    "#0E3DE7",
    "#51EC01",
    "#F901F3",
    "#97F436",
    "#012F8C",
    "#809BB4",
    "#FEA018",
    "#672AFE",
    "#06F60C",
    "#AF01C9",
    "#F0E974",
    "#3B424A",
    "#1C83E7",
    "#D7B701",
    "#D21AF3",
    "#18FD37",
    "#40068B",
    "#F3DAB5",
    "#A95817",
    "#046BFE",
    "#6ECB0D",
    "#FE0EC8",
    "#79FE75",
    "#021049",
    "#9EC8E8",
    "#F76F01",
    "#4A54F2",
    "#12DD38",
    "#C90589",
    "#DFFCB6",
    "#231D17",
    "#31B3FE",
    "#EA870D",
    "#B93FC7",
    "#09EB76",
    "#5C0148",
    "#FCF5E9",
    "#8B2D01",
    "#019CF2",
    "#8C9F39",
    "#FC2B88",
    "#5BF6B7",
    "#0A0116",
    "#BAEAFE",
    "#EA410E",
    "#3185C6",
    "#24B577",
    "#E01B47",
    "#C9FCE9",
    "#110601",
    "#4BDBF1",
    "#F8573A",
    "#9D6D87",
    "#02CAB9",
    "#7A0E15",
    "#FEFEFE",
    "#6D0F0E",
    "#04C9C5",
    "#A96E79",
    "#F35646",
    "#40DCEA",
    "#180601",
    "#D3FCF1",
    "#D71C3B",
    "#1BB486",
    "#3B85BA",
    "#F14015",
    "#AEEAFE",
    "#06010F",
    "#68F6C4",
    "#FE2C7A",
    "#7F9E45",
    "#019DEB",
    "#982D01",
    "#F9F5F0",
    "#50013C",
    "#0FEB84",
    "#C43FBB",
    "#E38614",
    "#28B3FE",
    "#2D1C0F",
    "#E7FCC3",
    "#BF057B",
    "#0CDC44",
    "#5655EB",
    "#FB6F01",
    "#91C8EF",
    "#010F3D",
    "#86FE83",
    "#FD0EBC",
    "#61CA13",
    "#086CFE",
    "#B55710",
    "#EDDAC2",
    "#36067C",
    "#20FD43",
    "#DC1BEC",
    "#CEB601",
    "#1484EF",
    "#46423E",
    "#F6E982",
    "#A301BD",
    "#03F613",
    "#742BFE",
    "#FE9F10",
    "#739BC1",
    "#032E7E",
    "#A4F542",
    "#F501ED",
    "#45EC01",
    "#153EEE",
    "#CE883F",
    "#DBB281",
    "#1F1DBE",
    "#36FC12",
    "#EE05FE",
    "#B4DD11",
    "#0753C0",
    "#62707F",
    "#FDC740",
    "#8510ED",
    "#01FE01",
    "#920DEE",
    "#FBCC40",
    "#566A80",
    "#0C59BF",
    "#BFD911",
    "#E707FE",
    "#2CFD12",
    "#281ABF",
    "#E4B780",
    "#C4823F",
    "#0E43EE",
    "#51E801",
    "#F901ED",
    "#97F741",
    "#012A7E",
    "#80A1C0",
    "#FE9A11",
    "#672FFE",
    "#06F412",
    "#AF01BE",
    "#F0EC81",
    "#3B3C3E",
    "#1C8AEE",
    "#D7B001",
    "#D21EEC",
    "#18FB42",
    "#40047D",
    "#F3DEC1",
    "#A95210",
    "#0472FE",
    "#6EC513",
    "#FE11BC",
    "#79FE83",
    "#020D3D",
    "#9ECDEF",
    "#F76901",
    "#4A5BEC",
    "#12D843",
    "#C9077C",
    "#DFFDC2",
    "#231910",
    "#31B9FE",
    "#EB8014",
    "#B945BB",
    "#09E784",
    "#5C023C",
    "#FCF7F0",
    "#8B2801",
    "#01A3EB",
    "#8C9844",
    "#FC317B",
    "#5BF3C3",
    "#0A010F",
    "#BAEDFE",
    "#EA3B14",
    "#318BBA",
    "#24AF85",
    "#E01F3B",
    "#C9FBF0",
    "#110401",
    "#4BE0EA",
    "#F85045",
    "#9D7479",
    "#02C4C4",
    "#7A120E",
    "#FEFEFE",
    "#6D0C15",
    "#04CEB9",
    "#A96786",
    "#F35C3A",
    "#40D7F1",
    "#180801",
    "#D3FDEA",
    "#D71846",
    "#1BBA78",
    "#3B7FC5",
    "#F1460E",
    "#AEE6FE",
    "#060216",
    "#68F8B8",
    "#FE2787",
    "#7FA439",
    "#0196F1",
    "#983201",
    "#F9F2E9",
    "#500147",
    "#0FEE77",
    "#C43AC6",
    "#E38D0D",
    "#28ADFE",
    "#2D2116",
    "#E7FBB7",
    "#BF0489",
    "#0CE138",
    "#564FF2",
    "#FB7501",
    "#91C3E8",
    "#011349",
    "#86FE76",
    "#FD0BC7",
    "#61D00D",
    "#0865FE",
    "#B55E17",
    "#EDD5B6",
    "#36088A",
    "#20FE37",
    "#DC17F2",
    "#CEBC01",
    "#147DE8",
    "#46484A",
    "#F6E574",
    "#A302C8",
    "#03F80C",
    "#7426FE",
    "#FEA618",
    "#7395B5",
    "#03338B",
    "#A4F236",
    "#F501F3",
    "#45EF01",
    "#1538E7",
    "#CE8F4B",
    "#DBAC73",
    "#1F22C9",
    "#36FA0C",
    "#EE03FE",
    "#B4E218",
    "#074DB4",
    "#62778C",
    "#FDC135",
    "#8513F3",
    "#01FE01",
    "#920AE6",
    "#FBD14C",
    "#566472",
    "#0C60CA",
    "#BFD40B",
    "#E709FE",
    "#2CFE19",
    "#2816B3",
    "#E4BD8E",
    "#C47B34",
    "#0E49F4",
    "#51E401",
    "#F903E6",
    "#97F94D",
    "#012571",
    "#80A8CB",
    "#FE930B",
    "#6735FE",
    "#06F11A",
    "#AF01B1",
    "#F0F08F",
    "#3B3733",
    "#1C90F4",
    "#D7AA01",
    "#D223E5",
    "#18FA4E",
    "#400370",
    "#F3E3CC",
    "#A94B0A",
    "#0479FD",
    "#6EC01B",
    "#FE14B0",
    "#79FE90",
    "#020A32",
    "#9ED2F5",
    "#F76202",
    "#4A61E4",
    "#12D34F",
    "#C9096E",
    "#DFFECD",
    "#23150A",
    "#31BFFD",
    "#EB7A1B",
    "#B94BAF",
    "#09E391",
    "#5C0331",
    "#FCF9F5",
    "#8B2302",
    "#01A9E3",
    "#8C9150",
    "#FC366D",
    "#5BF0CE",
    "#0A0109",
    "#BAF1FD",
    "#EA351C",
    "#3192AE",
    "#24A892",
    "#E02430",
    "#C9F9F6",
    "#110302",
    "#4BE4E3",
    "#F84A52",
    "#9D7A6C",
    "#02BECF",
    "#7A1509",
    "#FEFEFD",
    "#6D091D",
    "#04D4AD",
    "#A96094",
    "#F3632F",
    "#40D2F6",
    "#180A02",
    "#D3FEE2",
    "#D71453",
    "#1BC06B",
    "#3B78D0",
    "#F14C09",
    "#AEE2FD",
    "#06031E",
    "#68FAAC",
    "#FE2295",
    "#7FAB2E",
    "#0190F7",
    "#983802",
    "#F9EFE1",
    "#500154",
    "#0FF169",
    "#C534D1",
    "#E39408",
    "#28A7FD",
    "#2D251E",
    "#E7F9AA",
];

export default uniqueColors;
