import { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { AdminAuctionContext } from "../context/AdminAuctionContext";
import formatUSD from "../../utilities/formatUSD";
import formatSequential from "../../utilities/formatSequential";
import EditBidModal from "../components/EditBidModal";
import { AdminChatContext } from "./AdminChat/context/AdminChatContext";
import AuctionTimer from "./AuctionTimer";

export default function AuctionHeader() {
    const {
        currentAuctionId,
        currentAuctionTitle,
        currentAuctionData,
        currentAuctionIsLive,
        currentAuctionBids,
        currentAuctionStartTime,
        currentAuctionEndTime,
    } = useContext(AdminAuctionContext);
    const { activeUsers, users } = useContext(AdminChatContext);
    const unreadMessages = useMemo(() => {
        if (users) {
            return users.filter((user) => user.message_read === 0).length;
        } else {
            return 0;
        }
    }, [users]);

    const [bidsWaiting, setBidsWaiting] = useState(0);
    const [lastBid, setLastBid] = useState({ bid_amount: 0 });
    const [total, setTotal] = useState(0);
    const [completionsRefreshing, setCompletionsRefreshing] = useState(false);

    const [editBidData, setEditBidData] = useState({
        bid_amount: "",
        bid_bidder_id: "",
        bid_name: "",
    });

    const [showEditBidModal, setShowEditBidModal] = useState(false);

    const openEditBidModal = async (bid) => {
        setShowEditBidModal(true);
        bid.bidder_number = bid.bidder.bidder_number;
        setEditBidData(bid);
    };

    const getBidsTotal = () => {
        return 0; // add top bids if no completion?
    };

    useEffect(() => {
        try {
            // loop through completions and grab the hightest one
            let topCompletionTotal = 0;
            currentAuctionData.completions.forEach((completion) => {
                if (completion.completion_sale_price > topCompletionTotal) {
                    topCompletionTotal = completion.completion_sale_price;
                }
            });
            setTotal(topCompletionTotal);
        } catch (e) {
            setTotal(getBidsTotal());
        }
    }, [currentAuctionData, total]);

    useEffect(() => {
        try {
            // Get last _approved_ bid
            for (let i = 0; i < currentAuctionBids.bids.length; i++) {
                if (currentAuctionBids.bids[i].bid_approved === 1) {
                    setLastBid(currentAuctionBids.bids[i]);
                    break;
                }
            }
        } catch (e) {
            setLastBid(0);
        }
    }, [currentAuctionBids]);

    useEffect(() => {
        try {
            let count = 0;
            currentAuctionBids.bids.forEach((bid) => {
                if (bid.bid_approved === 0) {
                    count += 1;
                }
            });
            setBidsWaiting(count);
        } catch (e) {}
    }, [currentAuctionBids, completionsRefreshing]);

    return (
        <>
            <div className="flex">
                <div className="w-1/2 text-left flex items-center pb-2">
                    <div className="text-xl font-bold">
                        {currentAuctionTitle} AUCTION
                    </div>
                    {currentAuctionIsLive && (
                        <div className="rounded-full ml-4 bg-green-500 text-white text-xs px-4 py-1">
                            RUNNING
                        </div>
                    )}
                    {!currentAuctionIsLive && (
                        <div className="rounded-full ml-4 bg-red-500 text-white text-xs px-4 py-1">
                            OFFLINE
                        </div>
                    )}
                    {!!currentAuctionEndTime && !!currentAuctionStartTime && (
                        <AuctionTimer />
                    )}
                </div>
                <div className="w-1/2 text-right flex items-center">
                    <div className="flex-1 text-sm text-gray-400">Total</div>
                    <div className="text-xl font-bold ml-2">
                        {formatUSD(total)}
                    </div>
                </div>
            </div>
            <div className="flex mb-1">
                <div className="inline-block text-sm text-gray-400">Online</div>
                <div className="inline-block text-sm text-gray-400 ml-2">
                    {activeUsers.length - 1}
                </div>
                <div className="inline-block text-sm text-gray-400 mx-2">
                    &bull;
                </div>
                <div className="inline-block text-sm text-gray-400">
                    Messages
                </div>
                <div className="inline-block text-sm text-gray-400 ml-2">
                    {unreadMessages}
                </div>
                <div className="inline-block text-sm text-gray-400 mx-2">
                    &bull;
                </div>
                <div className="inline-block text-sm text-gray-400">
                    Bids Waiting
                </div>
                <div className="inline-block text-sm text-blue-400 ml-2 cursor-pointer">
                    <Link
                        to={`/admin/auction-interface/${currentAuctionId}/bids`}
                    >
                        {bidsWaiting}
                    </Link>
                </div>
                <div className="inline-block text-sm text-gray-400 mx-2">
                    &bull;
                </div>
                <div className="inline-block text-sm text-gray-400">
                    Last Bid
                </div>
                <div className="inline-block text-sm text-blue-400 ml-2 cursor-pointer">
                    {!!lastBid?.bid_amount && (
                        <div
                            onClick={() => openEditBidModal(lastBid)}
                            to={`/admin/auction-interface/${currentAuctionId}/bids`}
                        >
                            {lastBid.bid_alias}
                            {`${formatSequential(
                                lastBid.bid_name
                            )} @ ${formatUSD(lastBid.bid_amount)} by `}
                            {lastBid.bidder?.bidder_number &&
                                `#${lastBid.bidder.bidder_number}`}
                            {!lastBid.bidder?.bidder_number && "n/a"}
                        </div>
                    )}
                    {!lastBid?.bid_amount && <>n/a</>}
                </div>
            </div>

            <EditBidModal
                open={showEditBidModal}
                bidData={editBidData}
                onCancel={() => setShowEditBidModal(false)}
                onConfirm={() => {
                    setShowEditBidModal(false);
                }}
                completionsRefreshing={completionsRefreshing}
                setCompletionsRefreshing={setCompletionsRefreshing}
            />
        </>
    );
}
