import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Modal from "../../components/Modal";
import { Table } from "../../components/Table";
import Button from "../../components/Button";
import formatUSD from "../../utilities/formatUSD";
import formatSequential from "../../utilities/formatSequential";

export default function PlaceBidModal({
    open,
    bid,
    bidder,
    onConfirm,
    onCancel,
    singleTract = false,
}) {
    return (
        <Modal isOpen={open}>
            <div className="min-h-screen px-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl">
                        <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                        >
                            Confirm Bid
                        </Dialog.Title>
                        <hr className="my-4" />
                        <div className="my-6">
                            <p className="text-sm text-gray-500">
                                By clicking{" "}
                                <span className="font-bold">“Place Bid”</span>{" "}
                                below, you agree to purchase the property at the
                                bid price.
                            </p>
                        </div>
                        <div className="mt-2">
                            <h4 className="font-bold text-gray-600">
                                Current Bid:{" "}
                                {singleTract && (
                                    <span className="text-lg text-green-700 font-medium">
                                        {formatUSD(bid.bidAmount)}
                                    </span>
                                )}
                            </h4>
                        </div>
                        {!singleTract && (
                            <Table>
                                <Table.Header>
                                    <Table.HeaderCell>
                                        Tract(s)
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Acres</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Bid Price
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>PPA</Table.HeaderCell>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            {formatSequential(bid.tractName)}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {bid.totalAcres}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {formatUSD(bid.bidAmount)}
                                        </Table.Cell>
                                        <Table.Cell>{`${formatUSD(
                                            bid.bidAmount / bid.totalAcres
                                        )}/ac`}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        )}
                        <div className="my-6 text-center">
                            {bidder.hasOwnProperty("bidder_earnest_money") && (
                                <>
                                    <p className="font-bold text-gray-600">
                                        Total Power:{" "}
                                        {formatUSD(
                                            bidder.bidder_earnest_money * 10
                                        )}
                                    </p>

                                    <p
                                        className={`font-medium text-sm ${
                                            bidder.bidder_earnest_money -
                                                parseFloat(bid.bidAmount) *
                                                    0.1 <
                                            0
                                                ? "text-red-600"
                                                : "text-gray-600"
                                        }`}
                                    >
                                        Power Remaining After Bid:{" "}
                                        {formatUSD(
                                            (bidder.bidder_earnest_money -
                                                parseFloat(bid.bidAmount) *
                                                    0.1) *
                                                10
                                        )}
                                    </p>
                                </>
                            )}
                        </div>
                        <div className="mt-4 flex items-center justify-center space-x-4">
                            <Button
                                onClick={() => {
                                    if (
                                        bidder.bidder_earnest_money -
                                            parseFloat(bid.bidAmount) * 0.1 <
                                        0
                                    )
                                        return;
                                    onConfirm();
                                }}
                                rounded
                                disabled={
                                    bidder.bidder_earnest_money -
                                        parseFloat(bid.bidAmount) * 0.1 <
                                    0
                                }
                                className={
                                    bidder.bidder_earnest_money -
                                        parseFloat(bid.bidAmount) * 0.1 <
                                    0
                                        ? "cursor-not-allowed"
                                        : ""
                                }
                            >
                                PLACE BID
                            </Button>
                            <button
                                onClick={onCancel}
                                className="text-sm font-bold text-gray-500 hover:text-gray-300"
                            >
                                Cancel Bid
                            </button>
                        </div>
                    </div>
                </Transition.Child>
            </div>
        </Modal>
    );
}
