import { Fragment, useContext, useEffect, useState } from "react";
import { Card, CardTitle } from "../../components/Card";
import { Table } from "../../components/Table";
import { AuctionContext } from "../context/AuctionContext";
import { AuthContext } from "../../contexts/AuthContext";
import formatUSD from "../../utilities/formatUSD";
import EmptyTableState from "./EmptyTableState";
import { format } from "date-fns";
import {
    CalendarIcon,
    ChevronDownIcon,
    ChevronUpIcon,
} from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";

export default function YourBidsSingleTractCard() {
    const [showMobile, setShowMobile] = useState(false);
    return (
        <Card style={{ maxHeight: 645 }}>
            <CardTitle>
                <button
                    onClick={() => setShowMobile((p) => !p)}
                    className="flex justify-between items-center w-full"
                >
                    <div className="font-medium">YOUR BIDS</div>
                    {showMobile ? (
                        <ChevronDownIcon className="text-gray-400 h-8 w-8 block md:hidden" />
                    ) : (
                        <ChevronUpIcon className="text-gray-400 h-8 w-8 block md:hidden" />
                    )}
                </button>
            </CardTitle>
            <div
                style={{ maxHeight: 550 }}
                className="-mb-3 md:mb-4 overflow-y-auto"
            >
                <YourBidsTable showMobile={showMobile} />
            </div>
        </Card>
    );
}

function YourBidsTable({ showMobile }) {
    const {
        currentAuctionRecentBid,
        currentAuctionHighBids,
        currentAuctionBids,
    } = useContext(AuctionContext);
    const { loggedInUser } = useContext(AuthContext);
    const [yourBids, setYourBids] = useState([]);

    useEffect(() => {
        if (!currentAuctionBids) return;
        const usersBids = currentAuctionBids.bids.filter(
            (bid) => bid?.bidder?.user?.contact_id === loggedInUser.id
        );
        const usersBidsFormatted = usersBids.map((bid) => {
            return {
                id: bid.bid_id,
                created_at: format(
                    new Date(bid.bid_created_on),
                    "MM/dd/yy h:mm a"
                ),
                approved: bid.bid_approved,
                alias: bid.bid_alias,
                name: bid.bid_name,
                acres: currentAuctionHighBids[0]?.acres,
                amount: bid.bid_amount,
                ppa: (
                    bid.bid_amount / currentAuctionHighBids[0]?.acres
                ).toFixed(0),
            };
        });
        setYourBids(usersBidsFormatted);
    }, [currentAuctionBids, currentAuctionHighBids, loggedInUser.id]);

    const In = () => <p className="text-success font-bold">In</p>;
    const Out = () => <p className="text-cta font-bold">Out</p>;
    const Pend = () => <p className="text-gray-400 font-bold">Pending</p>;

    return (
        <>
            {yourBids.length === 0 && (
                <EmptyTableState
                    title="No bids"
                    body="Looks like you havent placed a bid yet."
                />
            )}
            {yourBids.length > 0 && (
                <>
                    <Transition
                        as={Fragment}
                        show={showMobile}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <ul className="divide-y divide-gray-200 md:hidden">
                            {yourBids.map((bid) => (
                                <li key={bid.id}>
                                    <MobileCard
                                        bid={bid}
                                        highBidId={
                                            currentAuctionHighBids[0]?.bid_id
                                        }
                                        recentBidId={currentAuctionRecentBid}
                                    />
                                </li>
                            ))}
                        </ul>
                    </Transition>
                    <Table className="hidden md:block">
                        <Table.Header>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Bid Amount</Table.HeaderCell>
                            <Table.HeaderCell>PPA</Table.HeaderCell>
                            <Table.HeaderCell>
                                <span className="sr-only">In/Out</span>
                            </Table.HeaderCell>
                        </Table.Header>
                        <Table.Body>
                            {yourBids.map((bid, i) => (
                                <Table.Row
                                    key={bid.id}
                                    className={`${
                                        currentAuctionRecentBid?.bid_id ===
                                        bid.id
                                            ? "bg-success bg-opacity-50"
                                            : i % 2 !== 0
                                            ? "bg-brand-gray-200"
                                            : "bg-none"
                                    }`}
                                >
                                    <Table.Cell>{bid.created_at}</Table.Cell>
                                    <Table.Cell>
                                        <span className="font-bold">
                                            {formatUSD(bid.amount)}
                                        </span>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatUSD(bid.ppa)}/ac
                                    </Table.Cell>
                                    <Table.Cell className="pl-0">
                                        {currentAuctionHighBids[0]?.bid_id ===
                                        bid.id ? (
                                            <In />
                                        ) : bid.bid_approved === 0 ? (
                                            <Pend />
                                        ) : (
                                            <Out />
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </>
            )}
        </>
    );
}

const MobileCard = ({ bid, highBidId, recentBidId }) => {
    const In = () => (
        <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            In
        </p>
    );
    const Out = () => (
        <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
            Out
        </p>
    );
    const Pend = () => (
        <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
            Pending
        </p>
    );

    return (
        <div
            className={`py-4 px-2 ${
                recentBidId === bid.id ? "bg-success bg-opacity-50" : "bg-none"
            }`}
        >
            <div className="flex items-center justify-between">
                <p className="text-sm leading-none font-bold text-green-800 truncate">
                    {formatUSD(bid.amount)}
                    <span className="text-xxs leading-none pl-2 text-gray-500 font-normal">
                        ({formatUSD(bid.ppa)}/ac)
                    </span>
                </p>
                <div className="ml-2 flex-shrink-0 flex">
                    {highBidId === bid.id ? (
                        <In />
                    ) : bid.bid_approved === 0 ? (
                        <Pend />
                    ) : (
                        <Out />
                    )}
                </div>
            </div>
            <div className="mt-2 sm:flex sm:justify-between">
                <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                    <CalendarIcon
                        className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                        aria-hidden="true"
                    />
                    <p className="text-xs">{bid.created_at}</p>
                </div>
            </div>
        </div>
    );
};
