import TabNav, { Item } from "../../components/TabNav";
import { useContext } from "react";
import { AuctionContext } from "../context/AuctionContext";
import TabNavInfo from "./TabNavInfo";
import { MapIcon } from "@heroicons/react/outline";

export default function BiddingTabNav() {
    const { currentAuctionId: auctionID, currentTractImages } =
        useContext(AuctionContext);
    return (
        <TabNav rightContent={<TabNavInfo />}>
            <Item link={`/bid/${auctionID}/bidding`}>
                <span className="flex items-center space-x-2">
                    <svg
                        className="w-5"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                    >
                        <g>
                            <path d="m240.99 436.75v60.25c0 8.284 6.716 15 15 15h241c8.284 0 15-6.716 15-15v-60.25c0-8.284-6.716-15-15-15h-241c-8.285 0-15 6.716-15 15zm30 15h211v30.25h-211z" />
                            <path d="m13.206 451.638c8.797 8.797 20.353 13.196 31.908 13.196s23.111-4.398 31.908-13.196l181.108-181.108 21.39 21.39-10.695 10.695c-5.858 5.858-5.858 15.355 0 21.213l42.604 42.604c2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464 10.606-4.394l127.81-127.81c5.858-5.858 5.858-15.355 0-21.213l-42.604-42.604c-5.857-5.858-15.355-5.858-21.213 0l-10.695 10.695-106.596-106.596 10.695-10.695c2.813-2.813 4.394-6.628 4.394-10.606 0-3.979-1.581-7.793-4.394-10.606l-42.603-42.604c-5.858-5.858-15.356-5.857-21.213 0l-127.809 127.81c-5.858 5.858-5.858 15.355 0 21.213l42.603 42.603c2.812 2.813 6.628 4.394 10.606 4.394s7.794-1.581 10.606-4.394l10.695-10.695 21.391 21.391-181.108 181.107c-17.594 17.594-17.594 46.222 0 63.816zm245.013-394.035-106.597 106.596-21.39-21.39 106.596-106.596zm42.426 255.619 106.596-106.596 21.39 21.39-106.596 106.596zm.089-42.515-106.597-106.596 63.994-63.993 106.596 106.596zm-266.315 138.328 181.108-181.107 21.39 21.39-181.107 181.107c-5.898 5.896-15.492 5.896-21.391 0-5.897-5.897-5.897-15.493 0-21.39z" />
                        </g>
                    </svg>
                    <p>Bidding</p>
                </span>
            </Item>
            {currentTractImages.length > 0 && (
                <Item link={`/bid/${auctionID}/tracts`}>
                    <span className="flex items-center space-x-2">
                        <MapIcon className="h-5 w-5" />
                        <p>Bid by Tract Map</p>
                    </span>
                </Item>
            )}
        </TabNav>
    );
}
