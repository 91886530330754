import PropTypes from "prop-types";
import { useRef, useState, useEffect, useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import useResizeObserver from "../hooks/useResizeObserver";

export default function SidebarLayout({ topnav, sidenav, children }) {
    const [navHeight, setNavHeight] = useState(null);
    const [navWidth, setNavWidth] = useState(null);
    const navRef = useRef();
    const dimensions = useResizeObserver(navRef);
    const { isMobileSidebarOpen, setGlobalContext } = useContext(GlobalContext);

    useEffect(() => {
        const { width, height } =
            dimensions || navRef.current.getBoundingClientRect();
        setNavHeight(height);
        setNavWidth(width);
        setGlobalContext((prev) => {
            return { ...prev, isMobileSidebarOpen: false };
        });
    }, [dimensions, setGlobalContext, navWidth]);

    const minContentHeight = {
        minHeight: `calc(100vh - ${navHeight}px)`,
    };
    const maxContentHeight = {
        maxHeight: `calc(100vh - ${navHeight}px)`,
    };

    return (
        <div className="overflow-hidden font-brand-gray-700">
            <header ref={navRef} className={`z-50 relative`}>
                {topnav}
            </header>
            <div className="flex" style={minContentHeight}>
                {isMobileSidebarOpen && (
                    <nav
                        style={{
                            ...maxContentHeight,
                            ...minContentHeight,
                            width: "100vw",
                        }}
                    >
                        {sidenav}
                    </nav>
                )}
                {!isMobileSidebarOpen && (
                    <>
                        {/* <nav
                            style={maxContentHeight}
                            className={`max-w-min flex-grow ${
                                navWidth < 767 ? "hidden" : "relative"
                            }`}
                        >
                            {sidenav}
                        </nav> */}
                        <section className="flex flex-grow justify-center h-full">
                            <div className="w-full max-w-8xl px-4 md:px-8 py-2">
                                {children}
                            </div>
                        </section>
                    </>
                )}
            </div>
        </div>
    );
}

SidebarLayout.propTypes = {
    /**
     * The content of the page. Typically you'll place your router in here to dynamically show content based on what's active in the sidebar.
     */
    children: PropTypes.any,
    /**
     * A completed sidenav component.
     */
    sidenav: PropTypes.element.isRequired,
    /**
     * The topnav of the page. Typically this will be the global nav.
     */
    topnav: PropTypes.element.isRequired,
};
