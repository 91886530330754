import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useReducer,
} from "react";
import { WhatIf } from "../../../../services/ActiveAuctionService";
import { AuctionContext } from "../../../context/AuctionContext";
import calculateSuggestedBid from "../../../helpers/calculateSuggestedBid";

import PlaceBidContextReducer from "./PlaceBidContextReducer";

// Initial state
const initialState = {
    tractInput: "",
    tractArray: [],
    tractInputFormatted: "",
    bidAmount: "",
    whatIf: null,
    suggestedBid: 1000,
    suggestedIncrement: 1000,
    runSideEffects: false,
    minimumBid: 1000,
    toMatch: null,
    toLead: null,
    bidAcres: null,
    bidLeads: false,
    biddingDisabled: true,
    errorText: "",
    placeBidModalOpen: false,
};

// Create context
export const PlaceBidContext = createContext(initialState);

// Provider component
export const PlaceBidProvider = ({ children }) => {
    const [state, dispatch] = useReducer(PlaceBidContextReducer, initialState);
    const {
        currentAuctionHighBids,
        currentAuctionId,
        currentAuctionBidSuggestions,
    } = useContext(AuctionContext);
    const [outsideBid, setOutsideBid] = React.useState(false);
    const placeBidCardRef = React.useRef(null);

    // Bid side effects
    const runBidSideEffects = useCallback(
        (value) => {
            WhatIf({ tracts: value.tracts, auction_id: currentAuctionId })
                .then((res) => {
                    const { bid, increment } = calculateSuggestedBid(
                        res.message.match,
                        currentAuctionBidSuggestions
                    );
                    // update the state with the what if data
                    editContext({
                        whatIf: res.message,
                        suggestedBid: bid,
                        suggestedIncrement: increment,
                        minimumBid: parseFloat(res.message.current) + increment,
                        toMatch: parseFloat(res.message.match),
                        toLead: parseFloat(res.message.match) + increment,
                        bidAcres: parseInt(res.message.request_acres),
                        runSideEffects: false,
                    });
                    // Re-run the bid input change handler to validate the bid with the new what-if
                    if (value.bid) {
                        dispatch({
                            type: "EDIT_BID_INPUT",
                            payload: value.bid,
                        });
                    }
                    if (value.outsideBid) {
                        handleBidInputChange(
                            (parseFloat(res.message.match) + increment) / 1000
                        );
                    }
                })
                .catch((err) => {
                    console.log(err);
                    return resetSideEffects();
                })
                .finally(() => {
                    setOutsideBid(false);
                });
        },
        [currentAuctionId, currentAuctionBidSuggestions]
    );

    useEffect(() => {
        if (state.runSideEffects) {
            runBidSideEffects({
                tracts: state.tractInput,
                bid: state.bidAmount,
                outsideBid: outsideBid,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.runSideEffects, runBidSideEffects]);

    // Actions
    function aliasLookup(value) {
        const bid = currentAuctionHighBids.find(
            (bid) => bid.bid_alias?.toLowerCase() === value.toLowerCase()
        );
        if (bid) {
            dispatch({
                type: "EDIT_TRACT_INPUT",
                payload: bid.bid_name,
            });
        }
    }
    function handleTractInputChange(value) {
        dispatch({
            type: "EDIT_TRACT_INPUT",
            payload: value,
        });
    }
    function handleBidInputChange(value) {
        dispatch({
            type: "EDIT_BID_INPUT",
            payload: value,
        });
    }
    function handleLeadClick() {
        dispatch({
            type: "HANDLE_LEAD_CLICK",
        });
    }
    function handleOutsideBid(tractString) {
        setOutsideBid(true);
        handleTractInputChange(tractString);
        placeBidCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
    function togglePlaceBidModal(bool) {
        dispatch({
            type: "EDIT_CONTEXT",
            payload: { placeBidModalOpen: bool },
        });
    }
    function editContext(val) {
        dispatch({
            type: "EDIT_CONTEXT",
            payload: val,
        });
    }
    function resetSideEffects() {
        dispatch({
            type: "RESET_SIDE_EFFECTS",
            payload: initialState,
        });
    }
    function resetAllContext() {
        dispatch({
            type: "RESET_ALL_CONTEXT",
            payload: initialState,
        });
    }
    return (
        <PlaceBidContext.Provider
            value={{
                ...state,
                handleTractInputChange,
                handleBidInputChange,
                aliasLookup,
                togglePlaceBidModal,
                handleLeadClick,
                handleOutsideBid,
                editContext,
                resetSideEffects,
                resetAllContext,
                placeBidCardRef,
            }}
        >
            {children}
        </PlaceBidContext.Provider>
    );
};
