import PropTypes from "prop-types";
import { forwardRef } from "react";

const Input = forwardRef(
    ({ htmlType, name, placeholder, borderless, className, ...props }, ref) => {
        const defaultStyle = `text-brand-gray-600  placeholder-opacity-50 text-base h-10 px-3 py-2 border border-brand-gray-300 rounded ${className}`;
        const borderlessStyle = `appearance-none block w-full h-10 px-3 py-2 border-0 border-b-2 border-white placeholder-white placeholder-opacity-50 text-base text-white font-bold bg-transparent focus:border-white focus:ring-0 ${className}`;

        return (
            <>
                <input
                    name={name ? name : htmlType}
                    type={htmlType}
                    autoComplete={htmlType}
                    placeholder={placeholder}
                    className={borderless ? borderlessStyle : defaultStyle}
                    ref={ref}
                    {...props}
                />
            </>
        );
    }
);

Input.propTypes = {
    className: PropTypes.string,
    /**
     * Applied to the name, type, and autocomplete html properties
     */
    htmlType: PropTypes.string,
    /**
     * The placeholder text for the input
     */
    placeholder: PropTypes.string,
    /**
     * Creates a one line input field
     */
    borderless: PropTypes.bool,
};

Input.defaultProps = {
    borderless: false,
};

export default Input;
