import React from "react";
import Icon from "./Icon";

export default function Alert({
    type = "danger",
    icon,
    iconColor,
    className,
    children,
    ...props
}) {
    const styles = {
        danger: "bg-danger border-danger-keyline",
        success: "bg-sucess-fill border-success",
        info: "bg-white border-gary-400",
    };
    const icons = {
        danger: { type: "exclamation-circle", color: "text-cta" },
        success: { type: "check", color: "text-success" },
        info: { type: "info", color: "text-gray-400" },
    };
    return (
        <div
            className={`flex border rounded p-2 ${styles[type]} ${className}`}
            {...props}
        >
            <Icon
                type={icon ? icon : icons[type].type}
                color={iconColor ? iconColor : icons[type].color}
                className="mr-2 flex-shrink-0"
            />
            {children}
        </div>
    );
}
