import apiClient from "../utilities/ApiClient";

export const CurrentBids = async (auctionID) => {
    try {
        let res = await apiClient.get(`/auctions/${auctionID}/bids/all`);
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const CurrentTractCount = async (currentAuctionId) => {
    try {
        let res = await apiClient.get(
            `auctions/${currentAuctionId}/tract-count`
        );
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const RebuildAuction = async (currentAuctionId) => {
    try {
        let res = await apiClient.post(`auctions/${currentAuctionId}/rebuild`);
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const WhatIf = async (data) => {
    try {
        let res = await apiClient.post(
            `auctions/${data.auction_id}/what-if`,
            data
        );
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const Aliases = async (auctionId) => {
    try {
        let res = await apiClient.get(`auctions/${auctionId}/aliases`);
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const Completions = async (params) => {
    try {
        let res = await apiClient.get(
            `auctions/${params.auction_id}/completions`,
            {
                params: params,
            }
        );
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const UpdateCompletionCount = async (params) => {
    try {
        let res = await apiClient.put(
            `auctions/${params.auction_id}/update-completion-count`,
            params
        );
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
