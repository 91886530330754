import { useChannel, useEvent, usePresenceChannel } from "@harelpls/use-pusher";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import { AdminAuctionContext } from "../../../context/AdminAuctionContext";
import useChatQueries from "../hooks/useChatQueries";
import useNewMessage from "../hooks/useNewMessage";

import AdminChatContextReducer from "./AdminChatContextReducer";

const initialState = {
    currentSelectedBidder: "ADMIN",
    runSideEffects: false,
    pageInitalized: true,
    activeUsers: [],
};

export const AdminChatContext = createContext(initialState);

export const AdminChatProvider = ({ children }) => {
    const { currentAuctionId } = useContext(AdminAuctionContext);
    const [state, dispatch] = useReducer(AdminChatContextReducer, initialState);
    const chatChannel = useChannel(`private-admin-chat-${currentAuctionId}`);
    const { handleNewMessage } = useNewMessage(state);
    const { members } = usePresenceChannel(
        `presence-on-auction-${currentAuctionId}`
    );
    const {
        useCurrentUserMessages,
        useChatMessagesAll,
        useAuctionUsers,
        markAsRead,
    } = useChatQueries(state);

    // React query calls
    const { data: users } = useAuctionUsers();
    const { data: messages } = useCurrentUserMessages();

    // Set the active users
    useEffect(() => {
        dispatch({
            type: "EDIT_CONTEXT",
            payload: { activeUsers: Object.keys(members) },
        });
    }, [members]);

    // Run side effects for new messages
    useEvent(chatChannel, "App\\Events\\ChatMessageReceivedAdmin", (data) =>
        handleNewMessage(data)
    );

    // Actions
    function editContext(val) {
        dispatch({
            type: "EDIT_CONTEXT",
            payload: val,
        });
    }
    function resetSideEffects() {
        dispatch({
            type: "RESET_SIDE_EFFECTS",
            payload: initialState,
        });
    }
    function resetAllContext() {
        dispatch({
            type: "RESET_ALL_CONTEXT",
            payload: initialState,
        });
    }

    if (!users) return <div>Loading...</div>;

    return (
        <AdminChatContext.Provider
            value={{
                ...state,
                users,
                messages,
                chatMessagesAll: useChatMessagesAll.data,
                markAsRead,
                editContext,
                resetSideEffects,
                resetAllContext,
            }}
        >
            {children}
        </AdminChatContext.Provider>
    );
};
