export const allowedTractKeys = [
    "+",
    "-",
    ".",
    "Backspace",
    "ArrowLeft",
    "ArrowRight",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "Tab",
];
