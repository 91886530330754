import React, { useContext } from "react";
import formatUSD from "../../../../utilities/formatUSD";
import { PlaceBidContext } from "../context/PlaceBidContext";

export default function BidDetails() {
    const { bidAmount, bidAcres: bidAcreCount } = useContext(PlaceBidContext);
    return (
        <div className="rounded-lg bg-gray-100 px-4 py-2">
            <div className="mb-2 font-medium">Bid Details</div>
            <div className="flex items-center bg-gray-200 p-2 rounded-md">
                <div>Acres:</div>
                <div className="flex-1 text-right">
                    <b>{Math.round(bidAcreCount)}</b>
                </div>
            </div>
            <div className="flex items-center p-3">
                <div>PPA:</div>
                <div className="flex-1 text-right">
                    <b>
                        {(bidAmount * 1000) / bidAcreCount &&
                        (bidAmount * 1000) / bidAcreCount !== Infinity
                            ? formatUSD(
                                  Math.round((bidAmount * 1000) / bidAcreCount)
                              )
                            : ""}
                    </b>
                </div>
            </div>
        </div>
    );
}
