import { Switch, Redirect, useRouteMatch } from "react-router-dom";

import PrivateRoute from "../../components/PrivateRoute";

import AdminAuctionsTabNav from "../components/AdminAuctionsTabNav";
import AuctionHeader from "../components/AuctionHeader";
import Input from "./Input";
import Bids from "./Bids";
import WhatIf from "./WhatIf";
import Completions from "./Completions";
import BidBoard from "./BidBoard";
import Chat from "./Chat";
import Online from "./Online";
import Rebuild from "./Rebuild";
import Tracts from "./Tracts";
import Settings from "./Settings";

export default function AuctionInput() {
    const { url } = useRouteMatch();

    return (
        <>
            <div className="mb-8">
                <AuctionHeader />
                <AdminAuctionsTabNav base={`${url}`} />
            </div>
            <Switch>
                <PrivateRoute exact path={`${url}/input`}>
                    <Input />
                </PrivateRoute>
                <PrivateRoute path={`${url}/bids`}>
                    <Bids />
                </PrivateRoute>
                <PrivateRoute path={`${url}/what-if`}>
                    <WhatIf />
                </PrivateRoute>
                <PrivateRoute path={`${url}/tracts`}>
                    <Tracts />
                </PrivateRoute>
                <PrivateRoute path={`${url}/completions`}>
                    <Completions />
                </PrivateRoute>
                <PrivateRoute path={`${url}/bid-board`}>
                    <BidBoard />
                </PrivateRoute>
                <PrivateRoute path={`${url}/chat`}>
                    <Chat />
                </PrivateRoute>
                <PrivateRoute path={`${url}/online`}>
                    <Online />
                </PrivateRoute>
                <PrivateRoute path={`${url}/settings`}>
                    <Settings />
                </PrivateRoute>
                <PrivateRoute path={`${url}/rebuild`}>
                    <Rebuild />
                </PrivateRoute>
                <Redirect from={`${url}/`} to={`${url}/dashboard`} />
            </Switch>
        </>
    );
}
