import React, { useContext } from "react";
import classNames from "../../../../utilities/classNames";
import formatUSD from "../../../../utilities/formatUSD";
import { PlaceBidContext } from "../context/PlaceBidContext";
import { allowedTractKeys } from "../helpers/allowedTractKeys";
import useTractInputChange from "../hooks/useTractInputChange";

const TractInput = React.forwardRef((_, ref) => {
    const handleChange = useTractInputChange();
    const {
        tractInputFormatted,
        whatIf,
        toLead,
        biddingDisabled,
        errorText,
        handleLeadClick,
    } = useContext(PlaceBidContext);
    const handleKeyDown = (e) => {
        if (!allowedTractKeys.includes(e.key) && !e.key.match(/[a-z]/i)) {
            e.preventDefault();
        }
    };
    return (
        <div className="mt-1 flex rounded-md shadow-sm">
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <input
                    htmlType="text"
                    autoComplete="off"
                    placeholder="Select a Tract"
                    className={`text-brand-gray-600  placeholder-opacity-50 text-base h-12 px-3 py-2 w-full border rounded-r-none ${
                        biddingDisabled && errorText
                            ? "border-red-700"
                            : "border-brand-gray-300"
                    } rounded`}
                    onChange={handleChange}
                    value={tractInputFormatted}
                    onKeyDown={handleKeyDown}
                    ref={ref}
                />
                {whatIf && whatIf.match !== "0" && (
                    <div className="absolute right-2 top-2 mt-1 mr-1">
                        <span className="text-brand-gray-600 text-xs">
                            {formatUSD(whatIf.match)} to match
                        </span>
                    </div>
                )}
            </div>
            <button
                type="button"
                className={classNames(
                    "hidden sm:inline-flex -ml-px text-xs h-12 relative items-center space-x-2 px-4 py-2 border rounded-r-md  font-medium hover:bg-opacity-60 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500",
                    whatIf
                        ? "bg-cta text-white border-cta"
                        : "bg-gray-300 text-black border-gray-300 pointer-events-none cursor-default"
                )}
                onClick={handleLeadClick}
            >
                <span>
                    {whatIf
                        ? `BID ${formatUSD(toLead)} TO LEAD`
                        : "Enter Tract(s)"}
                </span>
            </button>
        </div>
    );
});

export default TractInput;
