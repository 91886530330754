import formatUSD from "../../../../utilities/formatUSD";

const validateBid = (bidAmount, tractInput, combinations) => {
    const combinationIndex = combinations.findIndex(
        (combination) =>
            tractInput.split("+").every((tract) => {
                return combination.bid_name.split("+").includes(tract);
            }) &&
            combination.bid_name.split("+").every((tract) => {
                return tractInput.split("+").includes(tract);
            })
    );
    if (combinationIndex === -1) {
        return {
            disabled: false,
            message: "",
        };
    }
    if (
        parseFloat(combinations[combinationIndex].bid_amount) >=
        parseFloat(bidAmount * 1000)
    ) {
        return {
            disabled: true,
            message: `Bid must exceed ${formatUSD(
                combinations[combinationIndex].bid_amount
            )}`,
        };
    }
    return {
        disabled: false,
        message: "",
    };
};

export default validateBid;
