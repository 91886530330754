import { format } from "date-fns";
import { useState, createContext, useEffect, useContext } from "react";
import { useParams, Redirect } from "react-router-dom";
import { SingleAuction } from "../../services/AuctionService";
import { AuthContext } from "../../contexts/AuthContext";
import { GlobalContext } from "../../contexts/GlobalContext";
import { useQueryClient } from "react-query";
import { CurrentTractCount } from "../../services/ActiveAuctionService";
import getCurrentCombinations from "../helpers/CreateTractData";
import getTopCompletion from "../helpers/GetTopCompletion";
import { useChannel, useEvent, usePresenceChannel } from "@harelpls/use-pusher";
import ChatUser from "../components/ChatUser";
import PipViewer from "../components/PipViewer";
import WheelSpinner from "../../components/WheelSpinner";
import { useGetAuctionBids, useGetBidder } from "../../services/usePlaceBid";
import jitter from "../../utilities/jitter";
import useAuctionStartEndTime from "../../hooks/useAuctionStartEndTime";

const initialState = {
    currentAuctionTitle: null,
    currentAuctionImage: null,
    currentAuctionStream: null,
    currentAuctionDate: null,
    currentAuctionId: null,
    currentAuctionTracts: null,
    currentAuctionPremium: null,
    currentAuctionTractCount: null,
    currentAuctionTagline: null,
    currentAuctionCombinations: [],
    currentAuctionHighBids: [],
    currentAuctionCompletion: null,
    currentAuctionBids: null,
    currentTractImages: null,
    currentAuctionRecentBid: null,
    currentAuctionBidClose: null,
    currentAuctionBidOpen: null,
    currentAuctionBidSuggestions: null,
    userIsRecentBidder: false,
    onlineAuctionClosed: false,
    bidsLoading: true,
    videoIsDetached: false,
    auctionPendingClose: false,
};
export const AuctionContext = createContext(initialState);

export default function AuctionContextProvider({ children }) {
    const [auctionState, setAuctionState] = useState(initialState);
    const [auctionDataLoading, setAuctionDataLoading] = useState(true);
    const { setGlobalContext } = useContext(GlobalContext);
    const { loggedInUser } = useContext(AuthContext);
    const params = useParams();
    const queryClient = useQueryClient();
    const { data: bidder, isLoading: bidderLoading } = useGetBidder({
        auction_id: params.auctionID,
    });
    const { currentAuctionBidOpen, currentAuctionBidClose } =
        useAuctionStartEndTime({
            auctionID: params.auctionID,
            auctionPendingClose: auctionState.auctionPendingClose,
            onAuctionClosed: () =>
                setAuctionState((p) => ({
                    ...p,
                    onlineAuctionClosed: true,
                    auctionPendingClose: false,
                })),
        });

    const auctionChannel = useChannel(`private-auction-${params.auctionID}`);
    const chatChannel = useChannel(
        `private-chat-${params.auctionID}-${loggedInUser.id}`
    );
    const bidChannel = useChannel(`bids-${params.auctionID}`);
    const auctionPresenceChannel = usePresenceChannel(
        `presence-on-auction-${params.auctionID}`
    );
    const whatifsChannel = useChannel(`whatifs-${params.auctionID}`);

    useEvent(bidChannel, "App\\Events\\BidApproved", (data) => {
        jitter(
            queryClient.invalidateQueries([
                "currentBids",
                params.auctionID.toString(),
            ])
        );
        setAuctionState((state) => {
            return !data.data.hasOwnProperty("bid_id")
                ? state
                : {
                      ...state,
                      currentAuctionHighBids: data.data.high_bids,
                  };
        });
    });

    useEvent(auctionChannel, "App\\Events\\BidRemoved", (data) => {
        jitter(
            queryClient.invalidateQueries([
                "currentBids",
                params.auctionID.toString(),
            ])
        );
        setAuctionState((state) => {
            return !data.data.hasOwnProperty("bid_id")
                ? state
                : {
                      ...state,
                      currentAuctionHighBids: data.data.high_bids,
                  };
        });
    });

    useEvent(bidChannel, "App\\Events\\BidRejected", (data) => {
        if (data.data.auction_id.toString() === params.auctionID.toString()) {
            jitter(
                queryClient.invalidateQueries([
                    "currentBids",
                    params.auctionID.toString(),
                ])
            );
        }
    });

    useEvent(
        bidChannel,
        "App\\Events\\AuctionTimeChange",
        ({ message: auction }) => {
            queryClient.setQueryData(["start-end-time", params.auctionID], {
                auction_start: auction.auction_bid_open * 1000,
                auction_end: auction.auction_bid_close * 1000,
            });
            setAuctionState((state) => {
                return {
                    ...state,
                    onlineAuctionClosed: auction.auction_ended,
                };
            });
        }
    );
    useEvent(
        bidChannel,
        "App\\Events\\BidIncrementsUpdated",
        ({ message: auction }) => {
            setAuctionState((state) => {
                return {
                    ...state,
                    currentAuctionBidSuggestions: JSON.parse(
                        auction.auction_bid_increments
                    ),
                };
            });
        }
    );

    useEffect(() => {
        (async () => {
            setAuctionDataLoading(true);
            const auctionData = await SingleAuction(params.auctionID);
            const tractCount = await CurrentTractCount(params.auctionID);

            setAuctionState((prev) => {
                return {
                    ...prev,
                    currentAuctionTitle: auctionData.auction_name,
                    currentAuctionImage: auctionData.featured_image?.src,
                    currentAuctionStream: auctionData.auction_live_embed_link,
                    currentAuctionPremium: auctionData.auction_bp
                        ? `${parseFloat(auctionData.auction_bp)}%`
                        : null,
                    currentAuctionHighBids: auctionData.high_bids,
                    currentAuctionDate: format(
                        parseInt(auctionData.auction_date_time) * 1000,
                        "M/dd/yy h:mma"
                    ),
                    currentAuctionId: params.auctionID,
                    currentAuctionTracts: auctionData.tracts,
                    currentAuctionTagline: auctionData.auction_tagline,
                    currentAuctionTractCount: tractCount.data
                        ? tractCount.data
                        : 1,
                    currentTractImages: auctionData.tract_images.sort(
                        (a, b) => a.tract_image_order - b.tract_image_order
                    ),
                    currentAuctionBidSuggestions: JSON.parse(
                        auctionData.auction_bid_increments
                    ),
                };
            });
            setGlobalContext((prev) => {
                return {
                    ...prev,
                    currentAuctionTitle: auctionData.auction_name,
                    currentAuctionDate: format(
                        parseInt(auctionData.auction_date_time) * 1000,
                        "M/dd/yy h:mma"
                    ),
                };
            });
            setAuctionDataLoading(false);
        })();
        return () => {
            setGlobalContext((prev) => {
                return {
                    ...prev,
                    currentAuctionTitle: null,
                    currentAuctionDate: null,
                };
            });
        };
    }, [params, setGlobalContext]);

    const {
        data: bidData,
        isLoading: bidsLoading,
        error: bidsError,
    } = useGetAuctionBids({ auction_id: params.auctionID });

    useEffect(() => {
        if (
            !bidsLoading &&
            !bidsError &&
            auctionState.currentAuctionTractCount
        ) {
            const fullBidDetails = getCurrentCombinations(
                auctionState.currentAuctionTracts,
                bidData.data.bids
            );
            const recentBid = () => {
                const approvedBids = bidData.data?.bids.filter(
                    (bid) => bid.bid_approved === 1
                );
                const sortedBids = approvedBids.sort(
                    (a, b) =>
                        new Date(b.bid_timestamp) - new Date(a.bid_timestamp)
                );
                if (sortedBids.length <= 0) return null;
                const recentBid = sortedBids[0];
                const userIsRecentBid =
                    sortedBids[0].bidder.bidder_number ===
                    bidder?.bidder_number;
                recentBid.userIsRecentBid = userIsRecentBid;
                return recentBid;
            };
            const topCompletion = getTopCompletion(bidData.data.completions);
            setAuctionState((prev) => {
                return {
                    ...prev,
                    currentAuctionBids: bidData.data,
                    currentAuctionCombinations: fullBidDetails,
                    currentAuctionCompletion: topCompletion,
                    currentAuctionRecentBid: recentBid(),
                    userIsRecentBidder: recentBid()?.userIsRecentBid,
                    bidsLoading: false,
                };
            });
        }
    }, [
        bidsLoading,
        bidsError,
        bidData,
        auctionState.currentAuctionTractCount,
        auctionState.currentAuctionTracts,
        bidder,
    ]);

    useEffect(() => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 1024)
            return setAuctionState((p) => ({ ...p, videoIsDetached: true }));
    }, []);

    // Redirect user if they accessed this page and they're not an admin or a contact on this auction
    if (
        !loggedInUser.is_admin &&
        !loggedInUser.auctions.includes(parseInt(params.auctionID))
    ) {
        return <Redirect to="/" />;
    }

    if (auctionDataLoading || bidderLoading) {
        return (
            <div className="flex items-center justify-center mt-64 ">
                <WheelSpinner size="64" />
            </div>
        );
    }

    return (
        <AuctionContext.Provider
            value={{
                ...auctionState,
                setAuctionState,
                bidder,
                chatChannel,
                whatifsChannel,
                bidChannel,
                auctionChannel,
                auctionPresenceChannel,
                currentAuctionBidOpen,
                currentAuctionBidClose,
            }}
        >
            {children}
            <ChatUser />
            <PipViewer />
        </AuctionContext.Provider>
    );
}
