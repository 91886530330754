import React, { useContext } from "react";
import Button from "../../../../components/Button";
import { PlaceBidContext } from "../context/PlaceBidContext";

export default function PlaceBidActions() {
    const { biddingDisabled } = useContext(PlaceBidContext);
    return (
        <Button
            htmlType="submit"
            className={`py-2 ${biddingDisabled ? "cursor-not-allowed" : ""}`}
            disabled={biddingDisabled}
            size="sm"
        >
            Place Bid
        </Button>
    );
}
