export function Card({
    children,
    border = "border border-brand-gray-300",
    className,
    ...rest
}) {
    return (
        <div
            className={`bg-white rounded-sm p-3 md:p-6 overflow-hidden ${border} ${className}`}
            {...rest}
        >
            {children}
        </div>
    );
}

export function CardTitle({ children, className, ...rest }) {
    return (
        <div
            className={`mb-3 font-medium text-brand-gray-600 ${className}`}
            {...rest}
        >
            {children}
        </div>
    );
}
