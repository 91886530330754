import React from "react";
import { Switch, Redirect, useRouteMatch } from "react-router-dom";

import AuctionContextProvider from "./context/AuctionContext";

import GlobalNav from "../components/GlobalNav";
import SidebarLayout from "../components/SidebarLayout";
import UserSideNav from "./components/UserSideNav";

import Auctions from "./pages/Auctions";
import Notifications from "./pages/Notifications";
import Settings from "./pages/Settings";
import PrivateRoute from "../components/PrivateRoute";
import BiddingRouter from "./pages/BiddingRouter";

export default function UserHome() {
    const { url } = useRouteMatch();

    return (
        <SidebarLayout topnav={<GlobalNav />} sidenav={<UserSideNav />}>
            <Switch>
                <PrivateRoute path={`${url}auctions`}>
                    <Auctions />
                </PrivateRoute>
                <PrivateRoute path={`${url}notifications`}>
                    <Notifications />
                </PrivateRoute>
                <PrivateRoute path={`${url}settings`}>
                    <Settings />
                </PrivateRoute>
                <PrivateRoute path={`${url}bid/:auctionID`}>
                    <AuctionContextProvider>
                        <BiddingRouter />
                    </AuctionContextProvider>
                </PrivateRoute>
                <Redirect from="/" to="/auctions/upcoming" />
            </Switch>
        </SidebarLayout>
    );
}
