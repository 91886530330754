import React, { useState, useCallback, useEffect } from "react";
import { checkLogin } from "../services/SessionService";
import AppLoading from "../components/LoadingApp";

export const AuthContext = React.createContext();

export default function AuthContextProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(false);
    const [isCheckingAuth, setIsCheckingAuth] = useState(true);

    const check = useCallback(() => {
        setIsCheckingAuth(true);
        checkLogin()
            .then(function (res) {
                setIsLoggedIn(res ? true : false);
                setLoggedInUser(res);
                setIsCheckingAuth(false);
            })
            .catch((error) => {
                setIsLoggedIn(false);
                setLoggedInUser(false);
                setIsCheckingAuth(false);
            });
    }, []);

    useEffect(() => {
        check();
    }, [check]);

    if (isCheckingAuth) {
        return <AppLoading />;
    }

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                loggedInUser,
                setIsLoggedIn,
                setLoggedInUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
