import { useContext } from "react";
import TabNav, { Item } from "../../components/TabNav";
import { AuthContext } from "../../contexts/AuthContext";
import { useGetFavoriteAuctions } from "../../services/useFavoriteAuctions";

export default function UserAuctionsTabNav() {
    const { loggedInUser } = useContext(AuthContext);
    const { data: favoritesData } = useGetFavoriteAuctions({
        user_id: loggedInUser.id,
    });
    return (
        <TabNav>
            <Item link={`/auctions/upcoming`}>Upcoming</Item>
            <Item link={`/auctions/past`}>Past</Item>
            {favoritesData && favoritesData.length > 0 && (
                <Item link={`/auctions/favorites`}>Favorites</Item>
            )}
        </TabNav>
    );
}
