import {
    defaultIncrements,
    sortSuggestions,
} from "../../utilities/suggestedBid";

const calculateSuggestedBid = (amount, suggestions) => {
    let suggestionArray = [];

    if (!suggestions) {
        suggestionArray = sortSuggestions(defaultIncrements);
    } else {
        suggestionArray = sortSuggestions(suggestions);
    }

    if (!amount) {
        amount = 0;
    }

    amount = amount.toString();
    amount = amount.replace(/\)|\(|[,]/g, "");
    amount = parseFloat(amount);
    const toSuggest = suggestionArray.find(
        (suggestion) => parseFloat(suggestion.amount) <= amount
    );

    const suggest = {};
    if (!toSuggest) {
        suggest.bid = amount + 1000;
        suggest.increment = 1000;
    } else {
        suggest.bid = amount + parseFloat(toSuggest.suggest);
        suggest.increment = parseFloat(toSuggest.suggest);
    }

    return suggest;
};

export default calculateSuggestedBid;
