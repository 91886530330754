import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../components/PrivateRoute";
import TractMap from "./TractMap";
import Bidding from "./Bidding";
import { PlaceBidProvider } from "../components/PlaceBidCard/context/PlaceBidContext";

export default function BiddingRouter() {
    const { url } = useRouteMatch();
    return (
        <PlaceBidProvider>
            <Switch>
                <PrivateRoute path={`${url}/bidding`}>
                    <Bidding />
                </PrivateRoute>
                <PrivateRoute path={`${url}/tracts`}>
                    <TractMap />
                </PrivateRoute>
                <Redirect from={`${url}/`} to={`${url}/bidding`} />
            </Switch>
        </PlaceBidProvider>
    );
}
