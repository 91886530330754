import React, { useContext, useMemo } from "react";
import { InputWithIcon } from "../../../../components/InputWithIcon";
import classNames from "../../../../utilities/classNames";
import formatUSD from "../../../../utilities/formatUSD";
import abbrNum from "../../../helpers/abbrNum";
import addCommasToNumber from "../../../helpers/addCommasToNumber";
import { PlaceBidContext } from "../context/PlaceBidContext";

export default function BidInput() {
    const {
        bidAmount,
        suggestedIncrement,
        tractInput,
        minimumBid,
        handleBidInputChange,
        errorText,
        biddingDisabled,
        togglePlaceBidModal,
        bidAcres,
    } = useContext(PlaceBidContext);

    const handleIncrementClick = () => {
        handleBidInputChange(
            bidAmount === ""
                ? minimumBid / 1000
                : (parseFloat(bidAmount) * 1000 + suggestedIncrement) / 1000
        );
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            if (biddingDisabled) return;
            e.stopPropagation();
            togglePlaceBidModal(true);
        }
    };

    const bidPlaceholder = useMemo(
        () =>
            minimumBid && tractInput !== ""
                ? `Minimum Bid: ${minimumBid / 1000}`
                : "Enter Bid",
        [minimumBid, tractInput]
    );

    return (
        <>
            <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <div className="w-full">
                        <InputWithIcon
                            htmlType="number"
                            icon="dollar"
                            className="w-full h-12 rounded-r-none"
                            error={errorText !== "" && biddingDisabled}
                            placeholder={bidPlaceholder}
                            value={bidAmount}
                            onKeyDown={handleKeyDown}
                            onChange={(e) =>
                                handleBidInputChange(e.target.value)
                            }
                            onWheel={(e) => {
                                e.target.blur();
                            }}
                            rightContent={
                                <HelperText
                                    bidAmount={bidAmount}
                                    bidAcres={bidAcres}
                                />
                            }
                        />
                    </div>
                </div>
                {suggestedIncrement && (
                    <button
                        type="button"
                        className={classNames(
                            "hidden sm:inline-flex -ml-px text-xs h-12 relative  items-center space-x-2 px-4 py-2 border rounded-r-md  font-medium hover:bg-opacity-60 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-300 text-black border-gray-300 mt-1"
                        )}
                        onClick={handleIncrementClick}
                    >
                        <span>+&nbsp;${abbrNum(suggestedIncrement, 0)}</span>
                    </button>
                )}
            </div>
            {bidAmount > 0 && bidAcres !== null && (
                <span className="inline-flex sm:hidden text-gray-400 text-sm mt-2">
                    {formatUSD((bidAmount * 1000) / bidAcres)}
                    /ac&nbsp;for&nbsp;{bidAcres}&nbsp;acres
                </span>
            )}
        </>
    );
}

const HelperText = ({ bidAmount, bidAcres }) => (
    <div className="w-full text-sm text-gray-400">
        {bidAmount > 0 ? (
            <div className="flex items-center">
                <span>x1000 = </span>
                <span className="text-green-700 font-bold">
                    &nbsp;${addCommasToNumber(bidAmount * 1000)}{" "}
                </span>
                {bidAcres !== null && (
                    <span className="hidden sm:inline-flex text-gray-400 text-xs ml-1">
                        @&nbsp;{formatUSD((bidAmount * 1000) / bidAcres)}
                        /ac&nbsp;for&nbsp;{bidAcres}&nbsp;acres
                    </span>
                )}
            </div>
        ) : (
            "x1000"
        )}
    </div>
);
