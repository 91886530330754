import { Fragment, useContext, useEffect, useState } from "react";
import { AuctionContext } from "../context/AuctionContext";
import { Card, CardTitle } from "../../components/Card";
import { Table } from "../../components/Table";
import WheelSpinner from "../../components/WheelSpinner";
import formatUSD from "../../utilities/formatUSD";
import EmptyTableState from "./EmptyTableState";
import { format } from "date-fns";
import {
    CalendarIcon,
    ChevronDownIcon,
    ChevronUpIcon,
} from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";

export default function BidHistoryCard() {
    const { bidsLoading: isLoading, currentAuctionBids } =
        useContext(AuctionContext);

    const [bidData, setBidData] = useState([]);
    const [showMobile, setShowMobile] = useState(false);

    useEffect(() => {
        if (currentAuctionBids) setBidData(currentAuctionBids.bids);
    }, [currentAuctionBids]);

    return (
        <Card style={{ maxHeight: 645 }} className="mb-16 md:mb-0">
            <CardTitle>
                <button
                    onClick={() => setShowMobile((p) => !p)}
                    className="flex justify-between items-center w-full"
                >
                    <div className="font-medium">BIDDING HISTORY</div>
                    {showMobile ? (
                        <ChevronDownIcon className="text-gray-400 h-8 w-8 block md:hidden" />
                    ) : (
                        <ChevronUpIcon className="text-gray-400 h-8 w-8 block md:hidden" />
                    )}
                </button>
            </CardTitle>
            {isLoading && (
                <div className="flex justify-center items-center h-3/4">
                    <WheelSpinner size="64" />
                </div>
            )}
            <div
                style={{ maxHeight: 550 }}
                className="-mb-3 md:mb-4 overflow-y-auto"
            >
                {!isLoading && (
                    <BidTable bids={bidData} showMobile={showMobile} />
                )}
            </div>
        </Card>
    );
}
function BidTable({ bids, showMobile }) {
    const { currentAuctionRecentBid, bidder, currentAuctionHighBids } =
        useContext(AuctionContext);

    return (
        <>
            {bids.length === 0 && (
                <EmptyTableState
                    title="No bids"
                    body="Looks like there hasn't been a bid yet."
                />
            )}
            {bids.length > 0 && (
                <>
                    <Transition
                        as={Fragment}
                        show={showMobile}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <ul className="divide-y divide-gray-200 md:hidden">
                            {bids.map((bid) => (
                                <li key={bid.bid_id}>
                                    <MobileCard
                                        bid={bid}
                                        highBidId={
                                            currentAuctionHighBids[0]?.bid_id
                                        }
                                    />
                                </li>
                            ))}
                        </ul>
                    </Transition>
                    <Table className="hidden md:block">
                        <Table.Header>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Bidder</Table.HeaderCell>
                            <Table.HeaderCell>Bid Amount</Table.HeaderCell>
                        </Table.Header>
                        <Table.Body>
                            {bids.map((bid, i) => (
                                <Table.Row
                                    className={`${
                                        currentAuctionRecentBid?.bid_id ===
                                        bid.bid_id
                                            ? currentAuctionRecentBid?.bidder
                                                  ?.bidder_number ===
                                              bidder?.bidder_number
                                                ? "bg-success bg-opacity-50"
                                                : "bg-cta bg-opacity-50"
                                            : i % 2 !== 0
                                            ? "bg-brand-gray-200"
                                            : "bg-none"
                                    }`}
                                    key={bid.bid_id}
                                >
                                    <Table.Cell>
                                        {format(
                                            new Date(bid.bid_timestamp),
                                            "M/dd/yy h:mm a"
                                        )}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {bid.bidder?.bidder_number
                                            ? `#${bid.bidder.bidder_number}`
                                            : ""}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatUSD(bid.bid_amount)}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </>
            )}
        </>
    );
}

const MobileCard = ({ bid, highBidId }) => {
    const { currentAuctionRecentBid, bidder } = useContext(AuctionContext);

    const Winning = () => (
        <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            Winning
        </p>
    );

    return (
        <div
            className={`py-4 px-2 ${
                currentAuctionRecentBid?.bid_id === bid.bid_id
                    ? currentAuctionRecentBid?.bidder?.bidder_number ===
                      bidder?.bidder_number
                        ? "bg-success bg-opacity-50"
                        : "bg-cta bg-opacity-50"
                    : "bg-none"
            }`}
        >
            <div className="flex items-center justify-between">
                <p className="text-sm leading-none font-bold text-green-800 truncate">
                    {formatUSD(bid.bid_amount)}
                    <span className="text-xxs leading-none pl-2 text-gray-500 font-normal">
                        {bid.bidder?.bidder_number
                            ? `(Bidder: #${bid.bidder.bidder_number})`
                            : ""}
                    </span>
                </p>
                <div className="ml-2 flex-shrink-0 flex">
                    {highBidId === bid.bid_id && <Winning />}
                </div>
            </div>
            <div className="mt-2 sm:flex sm:justify-between">
                <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                    <CalendarIcon
                        className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                        aria-hidden="true"
                    />
                    <p className="text-xs">
                        {format(new Date(bid.bid_timestamp), "M/dd/yy h:mm a")}
                    </p>
                </div>
            </div>
        </div>
    );
};
