import { useContext, useEffect } from "react";
import useDebounce from "../../../../hooks/useDebounce";
import { PlaceBidContext } from "../context/PlaceBidContext";

export default function useTractInputChange() {
    const {
        handleTractInputChange,
        editContext,
        tractInputFormatted,
        aliasLookup,
        resetSideEffects,
        whatIf,
    } = useContext(PlaceBidContext);
    // Increase the delay when the value ends in a symbol
    const tractSymbols = ["+", "-", "."];
    const debounceTractsInput = useDebounce(
        tractInputFormatted,
        !tractSymbols.includes(
            tractInputFormatted[tractInputFormatted?.length - 1]
        )
            ? 1500
            : 500000
    );

    useEffect(() => {
        if (debounceTractsInput === "") return;
        const tractAlphas = debounceTractsInput.match(/[a-z]/gi);
        const alias = !!tractAlphas ? tractAlphas.join("").toUpperCase() : null;
        if (!!alias) {
            aliasLookup(debounceTractsInput);
        } else {
            handleTractInputChange(debounceTractsInput);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceTractsInput]);

    /* 
     If a user changes the tract input, we erase the what-if by invoking resetSideEffects() 
     in the handleChange function below.This prevents the user from submitting an invalid  
     bid by changing the tract input and pressing enter before we have a chance to check for the 
     minimum bid.

     However, if the user changes back to the same tract input before the debounce comes back, the
     What-if wont rerun because the debounce value never changes. This causes the useEffect above to
     not fire again. This is a workaround to make the whatif rerun. Would love to find a better solution.
     */
    useEffect(() => {
        if (
            debounceTractsInput !== "" &&
            whatIf === null &&
            tractInputFormatted === debounceTractsInput
        ) {
            const tractAlphas = debounceTractsInput.match(/[a-z]/gi);
            const alias = !!tractAlphas
                ? tractAlphas.join("").toUpperCase()
                : null;
            if (!!alias) {
                aliasLookup(debounceTractsInput);
            } else {
                handleTractInputChange(debounceTractsInput);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceTractsInput, whatIf, tractInputFormatted]);

    const handleChange = (e) => {
        editContext({
            tractInputFormatted: e.target.value,
        });
        resetSideEffects();
    };
    return handleChange;
}
