const countAcres = (tractName, tractData) => {
    const tractArray = tractName.split("+");
    const tractAcres = tractData.map((t) => {
        if (tractArray.includes(t.tract_tract_number.toString())) {
            return parseFloat(t.tract_acres);
        } else {
            return 0;
        }
    });
    return tractAcres.reduce((p, c) => (p += c), 0);
};

export default countAcres;
