import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Modal from "../../components/Modal";
import { Table } from "../../components/Table";
import formatUSD from "../../utilities/formatUSD";

export default function PlaceBidModal({ open, bid, onConfirm, onCancel }) {
    return (
        <Modal isOpen={open}>
            <div className="min-h-screen px-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl">
                        <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                        >
                            Confirm Bid
                        </Dialog.Title>
                        <hr className="my-4" />
                        <div className="my-6">
                            <p className="text-sm text-gray-500">
                                Are you sure you want to place this bid?
                            </p>
                        </div>
                        <div className="mt-2">
                            <h4 className="font-bold text-gray-600">
                                Current Bid
                            </h4>
                        </div>
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell>Bidder #</Table.HeaderCell>
                                <Table.HeaderCell>Tract(s)</Table.HeaderCell>
                                <Table.HeaderCell>Acres</Table.HeaderCell>
                                <Table.HeaderCell>Bid Price</Table.HeaderCell>
                                <Table.HeaderCell>PPA</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>{bid.bidder_number}</Table.Cell>
                                    <Table.Cell>{bid.tract_name}</Table.Cell>
                                    <Table.Cell>{bid.total_acres}</Table.Cell>
                                    <Table.Cell>
                                        {formatUSD(bid.bid_amount)}
                                    </Table.Cell>
                                    <Table.Cell>{`${formatUSD(
                                        bid.bid_amount / bid.total_acres
                                    )}/ac`}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <div className="mt-4 flex items-center justify-center space-x-4">
                            <button
                                className="px-8 py-2 text-sm rounded-full bg-cta text-white border-transparent font-bold border text-center leading-none hover:bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => onConfirm()}
                            >
                                PLACE BID
                            </button>
                            <button
                                onClick={() => onCancel()}
                                className="text-sm font-bold text-gray-500 hover:text-gray-300"
                                autoFocus={false}
                            >
                                Cancel Bid
                            </button>
                        </div>
                    </div>
                </Transition.Child>
            </div>
        </Modal>
    );
}
