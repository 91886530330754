import { useContext } from "react";
import ReactPlayer from "react-player";
import { AuctionContext } from "../context/AuctionContext";
import BestWayCard from "../components/BestWayCard";
import HighBidCard from "../components/HighBidCard";
import PlaceBidCard from "../components/PlaceBidCard";
import YourBidsCard from "../components/YourBidsCard";
import BiddingTabNav from "../components/BiddingTabNav";
import OnlineAuctionDetails from "../components/OnlineAuctionDetails";
import YourBidsSingleTractCard from "../components/YourBidsSingleTractCard";
import BidHistoryCard from "../components/BidHistoryCard";
import classNames from "../../utilities/classNames";
export default function Bidding() {
    const {
        currentAuctionImage,
        currentAuctionStream,
        currentAuctionTractCount,
        videoIsDetached,
        setAuctionState,
    } = useContext(AuctionContext);

    return (
        <>
            <div className="mb-4">
                <BiddingTabNav />
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-16 h-auto">
                <div
                    className="space-y-4"
                    style={{
                        height: "max-content",
                    }}
                >
                    {currentAuctionStream ? (
                        <div
                            className={`${
                                videoIsDetached
                                    ? "hidden"
                                    : "h-content max-h-96"
                            }`}
                        >
                            <div
                                className="max-h-96"
                                style={{ minHeight: 352 }}
                            >
                                <ReactPlayer
                                    width="100%"
                                    url={currentAuctionStream}
                                    pip={true}
                                    controls={true}
                                    playing={true}
                                />
                            </div>
                            <div>
                                <button
                                    className="block w-full font-bold border text-center leading-none hover:bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 px-8 py-2 text-sm bg-brand-gray-200 text-brand-gray-600"
                                    onClick={() =>
                                        setAuctionState((p) => {
                                            return {
                                                ...p,
                                                videoIsDetached: true,
                                            };
                                        })
                                    }
                                >
                                    Detach Video
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="lg:h-full max-h-96 flex items-center justify-center">
                            <img
                                src={currentAuctionImage}
                                alt=""
                                className="w-auto max-h-96 m-auto"
                            />
                        </div>
                    )}
                    {currentAuctionTractCount > 1 ? (
                        <>
                            <div
                                className={classNames(
                                    videoIsDetached &&
                                        currentAuctionStream &&
                                        "-mt-4"
                                )}
                            >
                                <PlaceBidCard />
                            </div>

                            {!!currentAuctionStream && videoIsDetached && (
                                <div className="hidden lg:block">
                                    <YourBidsCard />
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="hidden lg:block">
                            <YourBidsSingleTractCard />
                        </div>
                    )}
                </div>
                {currentAuctionTractCount > 1 ? (
                    <div className="space-y-4 h-auto">
                        <BestWayCard />
                        <HighBidCard />
                        {(!videoIsDetached || !currentAuctionStream) && (
                            <div className="hidden lg:block">
                                <YourBidsCard />
                            </div>
                        )}
                        <div className="block lg:hidden">
                            <YourBidsCard />
                        </div>
                    </div>
                ) : (
                    <div className="space-y-4 h-auto">
                        <OnlineAuctionDetails />
                        <BidHistoryCard />
                        <div className="block lg:hidden">
                            <YourBidsSingleTractCard />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
