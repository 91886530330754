import { useEffect, Fragment, useState, useRef, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Modal from "../../components/Modal";
import { Table } from "../../components/Table";
import Icon from "../../components/Icon";
import formatUSD from "../../utilities/formatUSD";
import formatSequential from "../../utilities/formatSequential";
import ReactToPrint from "react-to-print";
import PrintHeader from "./PrintHeader";
import { AdminAuctionContext } from "../context/AdminAuctionContext";

export default function CompletionSalesReportModal({
    open,
    salesReportData,
    auctionName,
    auctionDate,
    onCancel,
}) {
    const [totals, setTotals] = useState({
        acres: 0,
        bid: 0,
        premium: 0,
        total: 0,
        ppa: 0,
        earnest: 0,
        balance: 0,
    });
    const tableRef = useRef();
    const { currentAuctionEarnest, currentAuctionPremium } =
        useContext(AdminAuctionContext);

    const countTractsAcres = (tracts) => {
        let acres = 0;
        tracts.forEach((tract) => {
            acres += parseFloat(tract.tract_acres);
        });
        return acres;
    };

    useEffect(() => {
        if (Object.keys(salesReportData).length === 0) return;
        setTotals({
            acres: salesReportData.bids.reduce((acc, bid) => {
                return countTractsAcres(bid.tracts) + acc;
            }, 0),
            bid: salesReportData.bids.reduce((acc, bid) => {
                return Math.round(parseFloat(bid.bid_amount)) + acc;
            }, 0),
            premium: salesReportData.bids.reduce((acc, bid) => {
                return (
                    Math.round(
                        parseFloat(bid.bid_amount) * currentAuctionPremium
                    ) + acc
                );
            }, 0),
            total: salesReportData.bids.reduce((acc, bid) => {
                return (
                    Math.round(
                        parseFloat(bid.bid_amount) +
                            parseFloat(bid.bid_amount) * currentAuctionPremium
                    ) + acc
                );
            }, 0),
            ppa:
                salesReportData.bids.reduce((acc, bid) => {
                    return (
                        Math.round(
                            parseFloat(bid.bid_amount) +
                                parseFloat(bid.bid_amount) *
                                    currentAuctionPremium
                        ) + acc
                    );
                }, 0) /
                salesReportData.bids.reduce((acc, bid) => {
                    return countTractsAcres(bid.tracts) + acc;
                }, 0),
            earnest: salesReportData.bids.reduce((acc, bid) => {
                return (
                    Math.round(
                        parseFloat(bid.bid_amount) +
                            parseFloat(bid.bid_amount) * currentAuctionPremium
                    ) *
                        currentAuctionEarnest +
                    acc
                );
            }, 0),
            balance: salesReportData.bids.reduce((acc, bid) => {
                return (
                    Math.round(
                        parseFloat(bid.bid_amount) +
                            parseFloat(bid.bid_amount) * currentAuctionPremium
                    ) -
                    Math.round(
                        parseFloat(bid.bid_amount) +
                            parseFloat(bid.bid_amount) * currentAuctionPremium
                    ) *
                        currentAuctionEarnest +
                    acc
                );
            }, 0),
        });
    }, [salesReportData, currentAuctionEarnest, currentAuctionPremium]);

    return (
        <Modal isOpen={open}>
            <div className="min-h-screen px-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div
                        id="salesReport"
                        className="inline-block w-full max-w-6xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl"
                    >
                        <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                        >
                            The {auctionName} Auction - {auctionDate}
                            <ReactToPrint
                                trigger={() => (
                                    <Icon
                                        type="print"
                                        width="24px"
                                        className="mr-2 float-right cursor-pointer no-print"
                                        color="brand-gray-500"
                                    />
                                )}
                                content={() => tableRef.current}
                            />
                            <div className="clear-right"></div>
                        </Dialog.Title>
                        <hr className="my-4" />
                        <div className="tableEl" ref={tableRef}>
                            <PrintHeader
                                auction={`${auctionName} Auction`}
                                title={`Sales Report`}
                            />
                            <Table>
                                <Table.Header>
                                    <Table.HeaderCell>
                                        Tract(s)
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Acres</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Bid Amount
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Premium</Table.HeaderCell>
                                    <Table.HeaderCell>Total</Table.HeaderCell>
                                    <Table.HeaderCell>PPA</Table.HeaderCell>
                                    <Table.HeaderCell>Earnest</Table.HeaderCell>
                                    <Table.HeaderCell>Balance</Table.HeaderCell>
                                    <Table.HeaderCell>Bidder</Table.HeaderCell>
                                </Table.Header>
                                <Table.Body>
                                    {Object.keys(salesReportData).length > 0 &&
                                        salesReportData.bids.map((bid, i) => {
                                            bid.bid_acres = countTractsAcres(
                                                bid.tracts
                                            );
                                            bid.bid_premium = Math.round(
                                                parseFloat(bid.bid_amount) *
                                                    currentAuctionPremium
                                            );
                                            bid.bid_amount = Math.round(
                                                parseFloat(bid.bid_amount)
                                            );

                                            if (
                                                !bid.bidder.bidder_earnest_money
                                            )
                                                bid.bidder.bidder_earnest_money = 0;
                                            bid.bidder_earnest_money =
                                                Math.round(
                                                    parseFloat(
                                                        bid.bidder
                                                            .bidder_earnest_money
                                                    )
                                                );

                                            return (
                                                <Table.Row
                                                    key={bid.bid_id}
                                                    className={
                                                        i % 2 !== 0 &&
                                                        "bg-brand-gray-200 striped"
                                                    }
                                                >
                                                    <Table.Cell>
                                                        {formatSequential(
                                                            bid.bid_name
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {Math.round(
                                                            bid.bid_acres
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {formatUSD(
                                                            bid.bid_amount
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {formatUSD(
                                                            bid.bid_premium
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {formatUSD(
                                                            bid.bid_amount +
                                                                bid.bid_premium
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {formatUSD(
                                                            (bid.bid_amount +
                                                                bid.bid_premium) /
                                                                bid.bid_acres
                                                        ) + `/ac`}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {formatUSD(
                                                            (bid.bid_amount +
                                                                bid.bid_premium) *
                                                                currentAuctionEarnest
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {formatUSD(
                                                            Math.round(
                                                                parseFloat(
                                                                    bid.bid_amount
                                                                ) +
                                                                    parseFloat(
                                                                        bid.bid_amount
                                                                    ) *
                                                                        currentAuctionPremium
                                                            ) -
                                                                Math.round(
                                                                    parseFloat(
                                                                        bid.bid_amount
                                                                    ) +
                                                                        parseFloat(
                                                                            bid.bid_amount
                                                                        ) *
                                                                            currentAuctionPremium
                                                                ) *
                                                                    currentAuctionEarnest
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        #
                                                        {
                                                            bid.bidder
                                                                .bidder_number
                                                        }
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    {Object.keys(salesReportData).length >
                                        0 && (
                                        <Table.Row className="bg-yellow-300">
                                            <Table.Cell>TOTAL</Table.Cell>
                                            <Table.Cell>
                                                {Math.round(totals.acres)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {formatUSD(totals.bid)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {formatUSD(totals.premium)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {formatUSD(totals.total)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {formatUSD(totals.ppa)}
                                                /ac
                                            </Table.Cell>
                                            <Table.Cell>
                                                {formatUSD(totals.earnest)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {formatUSD(totals.balance)}
                                            </Table.Cell>
                                            <Table.Cell></Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        </div>
                        <div className="mt-4 flex items-center justify-center space-x-4">
                            <button
                                onClick={onCancel}
                                className="text-sm font-bold text-gray-500 hover:text-gray-300"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Transition.Child>
            </div>
        </Modal>
    );
}
