import React from "react";
import PropTypes from "prop-types";

export default function Button({
    children,
    type,
    htmlType,
    size,
    rounded,
    className,
    disabled,
    loading,
    ...props
}) {
    const btnSize = {
        lg: `px-16 py-3.5 text-base ${rounded ? "rounded-full" : "rounded-lg"}`,
        md: `px-8 py-2 text-sm ${rounded ? "rounded-full" : "rounded-lg"} `,
        sm: `px-6 py-2 text-sm ${rounded ? "rounded-full" : "rounded-lg"} `,
        xs: `p-1 text-xxs ${rounded ? "rounded-full" : "rounded"} `,
    };
    const btnColor = {
        primary: "bg-cta text-white border-transparent",
        secondary: "bg-brand-gray-200 text-brand-gray-600 border-gray-600",
        light: "bg-white text-brand-gray-600 border-brand-gray-600",
        ghost: "bg-transparent text-btn-keylines border-btn-keylines",
        success: "bg-sucess-fill border-success text-success-fill",
    };

    return (
        <button
            type={htmlType}
            className={`${btnSize[size]}
            ${btnColor[type]} ${
                disabled
                    ? "bg-opacity-60 text-opacity-70 border-opacity-60"
                    : ""
            } font-bold border text-center 
            leading-none hover:bg-opacity-70 focus:outline-none 
            focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${className}`}
            {...props}
        >
            {children}
        </button>
    );
}

Button.propTypes = {
    /**
     * Indicates the size of the button
     */
    size: PropTypes.oneOf(["lg", "md", "sm", "xs"]),
    /**
     * Determines the background and font color of the button
     */
    type: PropTypes.oneOf([
        "primary",
        "secondary",
        "light",
        "ghost",
        "success",
    ]),
    /**
     * Increases the border-radius
     */
    rounded: PropTypes.bool,
    /**
     * The HTML type attribute of the button https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type
     */
    htmlType: PropTypes.string,
    /**
     * Optional click handler
     */
    onClick: PropTypes.func,
    /**
     * You can still pass custom classes using the traditional className attribute
     */
    className: PropTypes.string,
};

Button.defaultProps = {
    size: "md",
    type: "primary",
    htmlType: "button",
    rounded: false,
};
