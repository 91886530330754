import React from "react";

export default function usePlayChatSound() {
    const [audioPlaying, setAudioPlaying] = React.useState(false);

    const playChatSound = async () => {
        // Safari does not suppor audio playback without a user interaction
        // This handles that error. Maybe we toast on Safari?
        try {
            if (audioPlaying) return;
            setAudioPlaying(true);
            const audio = new Audio(
                window.location.origin + "/audio/new-message.mp3"
            );
            await audio.play();
        } catch (error) {
            console.log("Browser does not support programmatic audio play");
        } finally {
            setAudioPlaying(false);
        }
    };

    return { playChatSound };
}
