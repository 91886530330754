const formatSequential = (tracts) => {
    const split = tracts.split("+");
    return split
        .slice(1)
        .reduce(
            (accumulator, current) => [
                ...accumulator,
                current - 1 === +accumulator[accumulator.length - 1]
                    ? "R"
                    : "P",
                current,
            ],
            ["P", split[0]]
        )
        .join("")
        .replace(/P([0-9]+)(R([0-9]+)){2,}/g, "+$1-$3")
        .replace(/P|R/g, "+")
        .slice(1);
};

export default formatSequential;
