import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";
import apiClient from "../../../../utilities/ApiClient";
import { AdminAuctionContext } from "../../../context/AdminAuctionContext";
import { PlaceBidContext } from "../context/PlaceBidContext";

export default function usePlaceAdminBid() {
    const { resetAllContext, togglePlaceBidModal } =
        useContext(PlaceBidContext);
    const { currentAuctionId } = useContext(AdminAuctionContext);
    const { addToast } = useToasts();
    const [canSubmit, setCanSubmit] = useState(true);
    const placeAdminBid = useMutation(
        (bidData) => {
            if (!canSubmit) throw new Error("Prevented duplicate bid");
            setCanSubmit(false);
            return apiClient.post(
                "admin/" + currentAuctionId + "/place-bid",
                bidData
            );
        },
        {
            onSettled: () => setTimeout(() => setCanSubmit(true), 1000),
            onMutate: () => {
                togglePlaceBidModal(false);
                resetAllContext();
            },
            onError: (error, bidData) => {
                console.error(error.message);
                const duplicateBid = error.message.includes(
                    "Prevented duplicate bid"
                );
                if (duplicateBid) return;
                addToast(
                    <ErrorMessage
                        title="Error placing bid"
                        msg={`${bidData.tracts} @${
                            bidData.amount * 1000
                        } for bidder ${bidData.bidder_number}`}
                    />,
                    { appearance: "warning", autoDismiss: true }
                );
            },
        }
    );
    return placeAdminBid.mutate;
}

const ErrorMessage = ({ title, msg }) => (
    <>
        <h1 className="font-bold w-56">{title}</h1>
        <p>{msg}</p>
    </>
);
