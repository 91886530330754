import React from "react";

export default function WheelSpinner({ color, size }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ? size : 226}
            height={size ? size : 226}
            viewBox={`0 0 ${size ? `226 226` : "226 226"}`}
            version="1.1"
            className={`${
                color ? color : "text-cta"
            } inline-block overflow-visible`}
            preserveAspectRatio="xMidYMid meet"
        >
            <g
                id="Page-1"
                stroke="currentColor"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                className="wheel"
            >
                <g id="wheel" transform="translate(8.000000, 8.000000)">
                    <circle
                        id="Fill-1"
                        strokeWidth="16"
                        fillRule="nonzero"
                        cx="105"
                        cy="105"
                        r="105"
                    />
                    <circle
                        id="Oval"
                        fill="currentColor"
                        cx="105"
                        cy="105"
                        r="31"
                    />
                    <line
                        x1="105.5"
                        y1="0.5"
                        x2="105.5"
                        y2="210.5"
                        id="Line"
                        strokeWidth="13"
                        strokeLinecap="square"
                    />
                    <line
                        x1="210.5"
                        y1="105.5"
                        x2="0.5"
                        y2="105.5"
                        id="Line"
                        strokeWidth="13"
                        strokeLinecap="square"
                    />
                    <line
                        x1="31.253788"
                        y1="31.253788"
                        x2="179.746212"
                        y2="179.746212"
                        id="Line"
                        strokeWidth="13"
                        strokeLinecap="square"
                    />
                    <line
                        x1="179.746212"
                        y1="31.253788"
                        x2="31.253788"
                        y2="179.746212"
                        id="Line"
                        strokeWidth="13"
                        strokeLinecap="square"
                    />
                </g>
            </g>
        </svg>
    );
}
