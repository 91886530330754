export default function EmptyTableState({
    title,
    body,
    padding = "p-12",
    className = "",
    background = "bg-gray-50",
}) {
    return (
        <div
            className={`relative flex flex-col items-center justify-center w-full h-auto border-2 border-gray-300 border-dashed rounded-lg text-center  ${padding} ${background} ${className}`}
        >
            <h4 className="mt-2 block font-medium text-gray-900">{title}</h4>
            <p className="mt-2 block text-sm text-gray-600">{body}</p>
        </div>
    );
}
