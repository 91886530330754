import PropTypes from "prop-types";
import React from "react";
import SchraderLogoWhite from "../../images/SchraderLogoWhite.svg";

export default function AuthLayout({ children, title }) {
    return (
        <div
            className="min-h-screen bg-gradient-red flex 
                       flex-col justify-center items-center p-8 font-secondary"
        >
            <img
                className="mx-auto h-22 w-auto"
                src={SchraderLogoWhite}
                alt="Schrader Logo"
            />
            <h2 className="mt-24 mb-16 text-center text-2xl md:text-3xl font-bold text-white">
                {title}
            </h2>

            {children}
        </div>
    );
}

AuthLayout.propTypes = {
    /**
     * The title of the page
     */
    title: PropTypes.string.isRequired,
    /**
     * The elements to render inside the form tags
     */
    children: PropTypes.any,
};
