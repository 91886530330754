export default function TractParser(tracts_str = "") {
    // Replace '..' with a '-'
    const doublePeriod = /\.{2}/g;
    if (tracts_str.match(doublePeriod))
        tracts_str = tracts_str.replace(doublePeriod, "-");
    // Replace '.' with a '+'
    const singlePeriod = /\./g;
    if (tracts_str.match(singlePeriod))
        tracts_str = tracts_str.replace(singlePeriod, "+");

    // Split the string into an array of tract numbers by '+'
    let tract_numbers = tracts_str.split("+");

    // Find the items with a - and push the range onto the array
    const range_items = tract_numbers.filter((item) => item.includes("-"));

    range_items.forEach((item) => {
        const range = item.split("-");
        if (parseInt(range[0]) > parseInt(range[1]))
            throw new Error("Invalid range");
        for (let i = parseInt(range[0]); i <= parseInt(range[1]); i++) {
            tract_numbers.push(i.toString());
        }
    });

    // Remove the range items from the array
    tract_numbers = tract_numbers.filter((item) => !item.includes("-"));

    // Remove any duplicates
    tract_numbers = [...new Set(tract_numbers)];

    const tractArray = tract_numbers.sort((a, b) => parseInt(a) - parseInt(b));
    const tractString = tractArray.join("+");

    return { tractArray, tractString };
}
