import React, { useContext } from "react";
import { isValid } from "date-fns";
import { AuctionContext } from "../../../context/AuctionContext";
import AuctionTimer from "../../AuctionTimer";

export default function CardHeader() {
    const { currentAuctionBidClose } = useContext(AuctionContext);
    return (
        <div className="flex justify-between items-center mb-4">
            <div className="font-medium text-brand-gray-600">PLACE A BID</div>
            {isValid(new Date(currentAuctionBidClose)) &&
                currentAuctionBidClose > 0 && <AuctionTimer />}
        </div>
    );
}
