import React, { useContext } from "react";
import { PlaceBidContext } from "../context/PlaceBidContext";

export default function BidderInput() {
    const { bidderNumber, handleBidderNumberChange } =
        useContext(PlaceBidContext);
    return (
        <div className="flex items-center mt-3 mb-3">
            <div className="bold w-18 text-sm">Bidder #</div>
            <div className="ml-6 flex-1">
                <input
                    value={bidderNumber}
                    onChange={(e) => handleBidderNumberChange(e.target.value)}
                    className="w-full h-8 p-2 rounded-md border-solid border-gray-200 border"
                />
            </div>
        </div>
    );
}
