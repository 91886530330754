import {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback,
} from "react";
import TractImageService from "../../services/TractImageService";
import { AdminAuctionContext } from "./AdminAuctionContext";

const initalState = {
    files: [],
    colorizedFiles: [],
    currentMapFile: null,
    tractMapShowing: false,
    currentColorizedFile: null,
};

export const TractMapContext = createContext(initalState);

export default function TractMapProvider({ children }) {
    const [tractMapContext, setTractMapContext] = useState(initalState);
    const { currentAuctionId } = useContext(AdminAuctionContext);

    const getCurrentAuctionImages = useCallback(async () => {
        const tractImages = await TractImageService.GetTractImages(
            currentAuctionId
        );
        tractImages.data.forEach(async (img) => {
            setTractMapContext((prev) => {
                const newFile = {
                    name: img.name,
                    preview: `${process.env.REACT_APP_BASE_URL}/api/image/${img.name}`,
                    id: img.id.toString(),
                    order: img.order,
                };

                return { ...prev, files: [...prev.files, newFile] };
            });
            if (img.colorized_name) {
                setTractMapContext((prev) => {
                    const newFile = {
                        name: img.colorized_name,
                        preview: `${process.env.REACT_APP_BASE_URL}/api/image/${img.colorized_name}`,
                        id: img.id.toString(),
                        tractData: img.data ? JSON.parse(img.data) : null,
                    };

                    return {
                        ...prev,
                        colorizedFiles: [...prev.colorizedFiles, newFile],
                    };
                });
            }
        });

        setTractMapContext((prev) => {
            return {
                ...prev,
                files: prev.files.sort((a, b) => a.order - b.order),
            };
        });
    }, [currentAuctionId]);

    useEffect(() => {
        if (tractMapContext.files.length === 0) getCurrentAuctionImages();
    }, [tractMapContext.files.length, getCurrentAuctionImages]);

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        tractMapContext.files.forEach((file) =>
            URL.revokeObjectURL(file.preview)
        );
        tractMapContext.colorizedFiles.forEach((file) =>
            URL.revokeObjectURL(file.preview)
        );
    }, [tractMapContext.files, tractMapContext.colorizedFiles]);

    const deleteTractImage = async (id, preview) => {
        try {
            await TractImageService.DeleteTractImage(id);
            setTractMapContext((prev) => {
                return {
                    ...prev,
                    files: prev.files.filter((file) => file.id !== id),
                };
            });
        } catch (error) {
            console.log(error);
        }
    };

    const uploadTractImage = async (file) => {
        try {
            const data = new FormData();
            data.append("image", file);
            data.append("auction_id", currentAuctionId);
            const imageUpload = await TractImageService.UploadTractImage(data);
            setTractMapContext((prev) => {
                const newFile = Object.assign(file, {
                    preview: `${process.env.REACT_APP_BASE_URL}/api/image/${imageUpload.name}`,
                    id: imageUpload.id.toString(),
                });

                return { ...prev, files: [...prev.files, newFile] };
            });
        } catch (error) {
            console.log(error);
        }
    };

    const reorderImages = (files) => {
        files.forEach((img, i) => {
            if (parseInt(img.order) !== i + 1)
                TractImageService.UpdateTractImage(img.id, {
                    tract_image_order: i + 1,
                });
        });
    };

    return (
        <TractMapContext.Provider
            value={{
                ...tractMapContext,
                setTractMapContext,
                uploadTractImage,
                deleteTractImage,
                reorderImages,
            }}
        >
            {children}
        </TractMapContext.Provider>
    );
}
