import React, { useRef, useEffect, useState, useContext } from "react";
import Button from "../../components/Button";
import DragTract from "./DragTract";
import { TractMapContext } from "../context/TractMapContext";
import TractImageService from "../../services/TractImageService";
import { AdminAuctionContext } from "../context/AdminAuctionContext";
import hexToRGB from "../../utilities/hexToRGB";
import uniqueColors from "../../utilities/uniqueColors";

export default function TractMap() {
    const {
        currentMapFile,
        currentColorizedFile,
        tractMapShowing,
    } = useContext(TractMapContext);
    if (!tractMapShowing) return <></>;
    return (
        <MapCanvas
            colorizedFile={currentColorizedFile}
            file={currentMapFile}
            id={currentMapFile.id}
        />
    );
}

function MapCanvas({ colorizedFile, file }) {
    const { setTractMapContext, colorizedFiles } = useContext(TractMapContext);
    const { currentAuctionTractCount } = useContext(AdminAuctionContext);
    const canvasRef = useRef();
    const tractCount = currentAuctionTractCount;
    const [canvasDimensions, setcanvasDimensions] = useState({
        width: 0,
        height: 0,
    });
    const [tractObjs, setTractObjs] = useState([]);
    const [strokeData, setStrokeData] = useState(null);
    const [tractPosData, setTractPosData] = useState({});
    const { width: canvasWidth, height: canvasHeight } = canvasDimensions;

    useEffect(() => {
        const consumedTracts = colorizedFiles.reduce((previous, current) => {
            return [
                ...previous,
                ...current.tractData.reduce(
                    (previous, current) => [...previous, current.tract],
                    []
                ),
            ];
        }, []);
        if (colorizedFile) {
            setTractObjs(colorizedFile.tractData);
        }
        const remainingTracts = [];
        for (let i = 1; i <= tractCount; i++) {
            if (!consumedTracts.includes(i)) {
                remainingTracts.push({
                    tract: i,
                    color: hexToRGB(uniqueColors[i]),
                    position: { x: 0, y: 0 },
                });
            }
        }
        setTractObjs((prev) => [...prev, ...remainingTracts]);
    }, [tractCount, colorizedFile, colorizedFiles]);

    useEffect(() => {
        if (canvasDimensions.width && canvasDimensions.height) {
            let canvas = canvasRef.current;
            let ctx = canvas.getContext("2d");

            // setCanvasWidth(canvasDimensions.width);
            // setCanvasHeight(canvasDimensions.height);

            const img = new Image();
            img.crossOrigin = "Anonymous";
            img.src = colorizedFile ? colorizedFile.preview : file.preview;
            img.onload = () => {
                ctx.drawImage(
                    img,
                    0,
                    0,
                    canvasDimensions.width,
                    canvasDimensions.height
                );
            };
        } else {
            let canvas = canvasRef.current;
            let ctx = canvas.getContext("2d");
            const img = new Image();
            img.crossOrigin = "Anonymous";
            img.src = file.preview;

            img.onload = () => {
                const widthMultiplier = img.width / img.height;
                img.width = 500 * widthMultiplier;
                img.height = 500;
                setcanvasDimensions({
                    width: img.width,
                    height: img.height,
                });
                ctx.drawImage(img, 0, 0, img.width, img.height);
                // save the original (unfilled) canvas
                // so we can reference where the black bounding lines are
                setStrokeData(ctx.getImageData(0, 0, img.width, img.height));
            };
        }
    }, [canvasDimensions, canvasHeight, canvasWidth, colorizedFile, file]);

    const fillCanvas = (e, fillColor, tractNumber) => {
        let canvas = canvasRef.current;

        var ctx = canvas.getContext("2d");

        var canvasOffset = canvas.getBoundingClientRect();
        var offsetX = canvasOffset.left;
        var offsetY = canvasOffset.top;
        const tractLocations = [];

        // define the grid area
        // lines can extend beyond grid but
        // floodfill wont happen outside beyond the grid
        const gridRect = {
            x: 0,
            y: 0,
            width: canvasWidth,
            height: canvasHeight,
        };

        // fillData contains the floodfilled canvas data
        var fillData = ctx.getImageData(
            0,
            0,
            canvasDimensions.width,
            canvasDimensions.height
        );

        if (Object.keys(tractPosData).includes(tractNumber.toString())) {
            tractPosData[tractNumber].forEach((pixelLoc) => {
                fillData.data[pixelLoc] = strokeData.data[pixelLoc];
                fillData.data[pixelLoc + 1] = strokeData.data[pixelLoc + 1];
                fillData.data[pixelLoc + 2] = strokeData.data[pixelLoc + 2];
                fillData.data[pixelLoc + 3] = 255;
            });
            ctx.putImageData(fillData, 0, 0);
        }

        // Thank you William Malone for this great floodFill algorithm!
        // http://www.williammalone.com/articles/html5-canvas-javascript-paint-bucket-tool/
        //////////////////////////////////////////////

        function floodFill(startX, startY, startR, startG, startB) {
            var newPos;
            var x;
            var y;
            var pixelPos;
            var neighborLeft;
            var neighborRight;
            var pixelStack = [[startX, startY]];

            while (pixelStack.length) {
                newPos = pixelStack.pop();
                x = newPos[0];
                y = newPos[1];

                // Get current pixel position
                pixelPos = (y * canvasWidth + x) * 4;

                // Go up as long as the color matches and are inside the canvas
                while (
                    y >= 0 &&
                    matchStartColor(pixelPos, startR, startG, startB)
                ) {
                    y -= 1;
                    pixelPos -= canvasWidth * 4;
                }

                pixelPos += canvasWidth * 4;
                y += 1;
                neighborLeft = false;
                neighborRight = false;

                // Go down as long as the color matches and in inside the canvas
                while (
                    y <= canvasHeight - 1 &&
                    matchStartColor(pixelPos, startR, startG, startB)
                ) {
                    y += 1;

                    fillData.data[pixelPos] = fillColor.r;
                    fillData.data[pixelPos + 1] = fillColor.g;
                    fillData.data[pixelPos + 2] = fillColor.b;
                    fillData.data[pixelPos + 3] = 255;

                    tractLocations.push(pixelPos);

                    if (x > 0) {
                        if (
                            matchStartColor(
                                pixelPos - 4,
                                startR,
                                startG,
                                startB
                            )
                        ) {
                            if (!neighborLeft) {
                                // Add pixel to stack
                                pixelStack.push([x - 1, y]);
                                neighborLeft = true;
                            }
                        } else if (neighborLeft) {
                            neighborLeft = false;
                        }
                    }

                    if (x < canvasWidth - 1) {
                        if (
                            matchStartColor(
                                pixelPos + 4,
                                startR,
                                startG,
                                startB
                            )
                        ) {
                            if (!neighborRight) {
                                // Add pixel to stack
                                pixelStack.push([x + 1, y]);
                                neighborRight = true;
                            }
                        } else if (neighborRight) {
                            neighborRight = false;
                        }
                    }

                    pixelPos += canvasWidth * 4;
                }
            }
        }

        function matchStartColor(pixelPos, startR, startG, startB) {
            // get the color to be matched
            var r = strokeData.data[pixelPos],
                g = strokeData.data[pixelPos + 1],
                b = strokeData.data[pixelPos + 2],
                a = strokeData.data[pixelPos + 3];

            // If current pixel of the outline image is black-ish
            if (matchstrokeColor(r, g, b, a)) {
                return false;
            }

            // get the potential replacement color
            r = fillData.data[pixelPos];
            g = fillData.data[pixelPos + 1];
            b = fillData.data[pixelPos + 2];

            // If the current pixel matches the clicked color
            if (r === startR && g === startG && b === startB) {
                return true;
            }

            // If current pixel matches the new color
            if (r === fillColor.r && g === fillColor.g && b === fillColor.b) {
                return false;
            }

            return true;
        }

        function matchstrokeColor(r, g, b) {
            // never recolor the initial black divider strokes
            // must check for near black because of anti-aliasing
            return r + g + b < 10;
        }

        // Start a floodfill
        // 1. Get the color under the mouseclick
        // 2. Replace all of that color with the new color
        // 3. But respect bounding areas! Replace only contiguous color.
        function paintAt(startX, startY) {
            // get the clicked pixel's [r,g,b,a] color data
            var pixelPos = (startY * canvasWidth + startX) * 4,
                r = fillData.data[pixelPos],
                g = fillData.data[pixelPos + 1],
                b = fillData.data[pixelPos + 2],
                a = fillData.data[pixelPos + 3];

            // this pixel's already filled
            if (r === fillColor.r && g === fillColor.g && b === fillColor.b) {
                return;
            }

            // this pixel is part of the original black image--don't fill
            if (matchstrokeColor(r, g, b, a)) {
                return;
            }

            // execute the floodfill
            floodFill(startX, startY, r, g, b);

            // put the colorized data back on the canvas
            ctx.putImageData(fillData, 0, 0);

            setTractPosData((prev) => {
                return {
                    ...prev,
                    [tractNumber]: tractLocations,
                };
            });
        }

        // end floodFill algorithm
        //////////////////////////////////////////////

        // get the pixel colors under x,y
        function getColors(x, y) {
            var data = ctx.getImageData(x, y, 1, 1).data;
            return {
                r: data[0],
                g: data[1],
                b: data[2],
                a: data[3],
            };
        }

        function handleMouseDown(e) {
            e.preventDefault();

            // get the mouse position
            let x = parseInt(e.clientX - offsetX);
            let y = parseInt(e.clientY - offsetY);

            if (
                x < gridRect.x + 5 ||
                x > gridRect.x + gridRect.width ||
                y < gridRect.y + 5 ||
                y > gridRect.y + gridRect.height
            ) {
                return;
            }

            // get the pixel color under the mouse
            var px = getColors(x, y);

            // floodfill the region bounded by black lines
            paintAt(x, y, px.r, px.g, px.b);
        }

        handleMouseDown(e);
    };

    const isLocationFilled = (e, tractNum) => {
        var canvasOffset = canvasRef.current.getBoundingClientRect();
        var offsetX = canvasOffset.left;
        var offsetY = canvasOffset.top;
        const posKeys = Object.keys(tractPosData);

        let x = parseInt(e.clientX - offsetX);
        let y = parseInt(e.clientY - offsetY);

        const inIndex = posKeys.findIndex((tract) =>
            tractPosData[tract].includes((y * canvasWidth + x) * 4)
        );

        if (
            inIndex >= 0 &&
            posKeys[inIndex].toString() !== tractNum.toString()
        ) {
            const prevTract = posKeys[inIndex];
            // Set the prevous tract back to 0
            setTractObjs((prev) => {
                const tractObjIndex = prev.findIndex(
                    (obj) => obj.tract.toString() === prevTract.toString()
                );
                prev[tractObjIndex].position = { x: 0, y: 0 };
                return prev;
            });
            // Remove its fill data from the state
            setTractPosData((prev) => {
                const keysWithoutLast = Object.keys(prev).filter(
                    (tract) => tract.toString() !== prevTract.toString()
                );
                const newState = {};
                keysWithoutLast.forEach((tract) => {
                    newState[tract] = prev[tract];
                });
                return newState;
            });
        }
    };

    return (
        <div className="flex justify-center" style={{ height: 500 }}>
            <div>
                <canvas
                    width={canvasDimensions.width}
                    height={canvasDimensions.height}
                    ref={canvasRef}
                />
            </div>
            <div className="w-80 pl-6 flex flex-col" style={{ height: 500 }}>
                <h2 className="font-medium text-gray-700">Edit Tracts</h2>
                <p className="text-gray-500 mb-2">
                    Drag and drop the pucks on their corresponding tract
                </p>
                <div className="flex flex-wrap">
                    {tractObjs.map((obj) => (
                        <DragTract
                            tractNumber={obj.tract}
                            tractColor={obj.color}
                            position={obj.position}
                            key={obj.tract}
                            drop={(e, d) => {
                                isLocationFilled(e, obj.tract);

                                var canvasOffset = canvasRef.current.getBoundingClientRect();
                                var offsetX = canvasOffset.left;
                                var offsetY = canvasOffset.top;

                                setTractObjs((prev) => {
                                    const thisIndex = prev.findIndex(
                                        (t) => t.tract === obj.tract
                                    );
                                    prev[thisIndex].position = {
                                        x: d.x,
                                        y: d.y,
                                    };
                                    prev[thisIndex].clickLocation = {
                                        x: e.clientX,
                                        y: e.clientY,
                                        offset: { x: offsetX, y: offsetY },
                                    };
                                    return prev;
                                });
                                fillCanvas(e, obj.color, obj.tract);
                            }}
                        />
                    ))}
                </div>
                <div className="mt-auto">
                    <div className="space-x-2 flex mb-2">
                        <Button
                            className="block w-full"
                            type="light"
                            onClick={() => {
                                setTractObjs((prev) =>
                                    prev.map((tract) => {
                                        return {
                                            ...tract,
                                            position: { x: 0, y: 0 },
                                        };
                                    })
                                );
                                let canvas = canvasRef.current;
                                let ctx = canvas.getContext("2d");
                                const img = new Image();
                                img.crossOrigin = "Anonymous";
                                img.src = file.preview;

                                img.onload = () => {
                                    ctx.drawImage(
                                        img,
                                        0,
                                        0,
                                        canvasWidth,
                                        canvasHeight
                                    );
                                };
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            className="block w-full"
                            type="light"
                            onClick={() =>
                                setTractMapContext((prev) => {
                                    return {
                                        ...prev,
                                        tractMapShowing: false,
                                    };
                                })
                            }
                        >
                            Cancel
                        </Button>
                    </div>
                    <Button
                        className="block w-full"
                        onClick={() => {
                            canvasRef.current.toBlob(async (blob) => {
                                const formData = new FormData();
                                const newFile = new File(
                                    [blob],
                                    `colorized_${file.name}`,
                                    {
                                        type: "image/png",
                                    }
                                );
                                formData.append("image", newFile);
                                formData.append("tract_image_id", file.id);
                                formData.append(
                                    "tract_data",
                                    JSON.stringify(
                                        tractObjs.filter(
                                            (obj) =>
                                                obj.position.x +
                                                    obj.position.y !==
                                                0
                                        )
                                    )
                                );
                                formData.append(
                                    "tract_image_colorized_name",
                                    `colorized_${file.name}`
                                );

                                const uploadTractImg = await TractImageService.UploadTractImageColorized(
                                    formData,
                                    file.id
                                );

                                setTractMapContext((prev) => {
                                    const canvasFile = Object.assign({
                                        preview: `${process.env.REACT_APP_BASE_URL}/api/image/${uploadTractImg.colorized_name}`,
                                        id: file.id,
                                        tractData: tractObjs,
                                    });
                                    return {
                                        ...prev,
                                        colorizedFiles: [
                                            ...prev.colorizedFiles,
                                            canvasFile,
                                        ],
                                        currentMapFile: null,
                                        tractMapShowing: false,
                                        currentColorizedFile: null,
                                    };
                                });
                            });
                        }}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
}
