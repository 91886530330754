import { useState, useEffect } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import usePagination from "../helpers/usePagination";

export default function Pagination({ count = 1, onPageChange }) {
    const [current, setCurrent] = useState(1);
    const { items } = usePagination({ count, page: current });
    useEffect(() => {
        onPageChange(current);
    }, [current, onPageChange]);
    const Button = ({ value, onClick, ...props }) => {
        return (
            <button
                onClick={() => onClick(value)}
                className={`${
                    current === value
                        ? "z-10 border-cta text-cta bg-cta bg-opacity-20"
                        : "border-gray-300 text-gray-500 hover:bg-gray-50"
                } bg-white relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                {...props}
            >
                {value}
            </button>
        );
    };
    return (
        <div className="bg-white py-3 flex items-center justify-between border-t border-gray-200">
            <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                    <nav
                        className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                        aria-label="Pagination"
                    >
                        <button
                            onClick={() => setCurrent((c) => (c -= 1))}
                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            disabled={current === 1}
                        >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                            />
                        </button>
                        {items.map((num, i) => {
                            if (num.type.includes("ellipsis")) {
                                return (
                                    <Button
                                        key={"ellipsis" + i}
                                        value="..."
                                        disabled
                                    />
                                );
                            } else if (num.type === "page") {
                                return (
                                    <Button
                                        key={`${num.value}-${i}`}
                                        value={num.page}
                                        onClick={setCurrent}
                                    />
                                );
                            }
                            return null;
                        })}
                        <button
                            onClick={() => setCurrent((c) => (c += 1))}
                            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            disabled={count === current}
                        >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                            />
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    );
}
