import { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";

export default function Modal({ isOpen, children }) {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-70"
                onClose={() => console.log("closed")}
            >
                {children}
            </Dialog>
        </Transition>
    );
}
