import { useState } from "react";
import { useContext } from "react";
import apiClient from "../../utilities/ApiClient";
import Button from "../../components/Button";
import { AdminAuctionContext } from "../context/AdminAuctionContext";
import { RebuildAuction } from "../../services/ActiveAuctionService";
import { useEvent } from "@harelpls/use-pusher";
import Spinner from "../../components/Spinner";
import { useToasts } from "react-toast-notifications";

export default function Rebuild() {
    const [rebuildOutput, setRebuildOutput] = useState([]);
    const [isStarting, setIsStarting] = useState(false);
    const {
        currentAuctionId,
        currentAuctionTitle,
        currentAuctionIsLive,
        auctionChannel,
        currentAuctionIsSingleTract,
    } = useContext(AdminAuctionContext);

    useEvent(auctionChannel, "App\\Events\\RebuildOutputReceived", (data) => {
        setRebuildOutput((prevState) => [...prevState, data.data.message]);
    });

    const { addToast } = useToasts();

    return (
        <div className="py-5">
            <div className="flex items-center text-xl text-right">
                {currentAuctionIsSingleTract && (
                    <div className="font-bold">Start or start auction</div>
                )}
                {!currentAuctionIsSingleTract && (
                    <div className="font-bold">
                        Start, stop or rebuild auction
                    </div>
                )}
                <div className="flex-1 text-right">
                    {!currentAuctionIsLive && (
                        <Button
                            className="py-3"
                            onClick={async () => {
                                try {
                                    if (isStarting) {
                                        return;
                                    }
                                    setIsStarting(true);
                                    let res = await apiClient.get(
                                        `auctions/${currentAuctionId}/tract-count`
                                    );
                                    let auction_tract_count = res.data.data
                                        ? res.data.data
                                        : 0;
                                    let auctionData = {
                                        auction_name:
                                            currentAuctionTitle.replace(
                                                /\s/g,
                                                ""
                                            ),
                                        tract_count: auction_tract_count,
                                    };
                                    await apiClient.post(
                                        `auctions/${currentAuctionId}/begin`,
                                        auctionData
                                    );
                                    await RebuildAuction(currentAuctionId);
                                    setIsStarting(false);
                                } catch (e) {
                                    addToast(
                                        <div>
                                            <h2>{e.response.data.message}</h2>
                                        </div>,
                                        {
                                            appearance: "error",
                                            autoDismiss: true,
                                            id: "rebuild-error",
                                        }
                                    );
                                    setIsStarting(false);
                                }
                            }}
                            disabled={currentAuctionIsLive}
                        >
                            <div className="flex space-x-2 items-center">
                                <div>Start Auction</div>
                                <div>
                                    {isStarting && <Spinner width="20" />}
                                </div>
                            </div>
                        </Button>
                    )}
                    {currentAuctionIsLive && (
                        <>{currentAuctionTitle} is currently in progress</>
                    )}
                    {currentAuctionIsLive && (
                        <Button
                            className="py-3 ml-4"
                            onClick={async () => {
                                await apiClient.post(
                                    `auctions/${currentAuctionId}/end`
                                );
                            }}
                            disabled={!currentAuctionIsLive}
                        >
                            End Auction
                        </Button>
                    )}
                    {currentAuctionIsLive && (
                        <Button
                            className="ml-4 py-3"
                            onClick={async () => {
                                setRebuildOutput(["Proccessing rebuild..."]);
                                await apiClient.post(
                                    `auctions/${currentAuctionId}/rebuild`
                                );
                            }}
                            disabled={!currentAuctionIsLive}
                        >
                            Run Rebuild
                        </Button>
                    )}
                </div>
            </div>
            <hr className="mt-8" />
            <ul className="text-gray-500 text-base mt-8 text-left max-w-sm overflow-x-auto">
                {rebuildOutput.map((message, i) => (
                    <li className="break-words py-2" key={i}>
                        {message}
                    </li>
                ))}
            </ul>
        </div>
    );
}
