const getAuctionTimezone = (tz_enum) => {
    switch (tz_enum) {
        case 1:
            return "EST";
        case 2:
            return "CST";
        case 3:
            return "MST";
        case 4:
            return "PST";
        default:
            return "EST";
    }
};

export const getAuctionLongTimezone = (tz_abbr) => {
    switch (tz_abbr) {
        case "PST":
            return "America/Los_Angeles";
        case "MST":
            return "America/Denver";
        case "CST":
            return "America/Chicago";
        case "EST":
            return "America/New_York";
        default:
            return "America/New_York";
    }
};

export default getAuctionTimezone;
