import { useContext, useEffect, useState } from "react";
import { ClockIcon } from "@heroicons/react/outline";
import {
    addMilliseconds,
    differenceInSeconds,
    intervalToDuration,
} from "date-fns";
import { AuctionContext } from "../context/AuctionContext";
import useMountEffect from "../../utilities/useMountEffect";
import useGetServerTimeOffset from "../../services/ServerTimeService";

const AuctionTimer = () => {
    const {
        currentAuctionBidClose,
        currentAuctionBidOpen,
        setAuctionState,
        onlineAuctionClosed,
        auctionPendingClose,
    } = useContext(AuctionContext);

    const [timeRemaining, setTimeRemaining] = useState("");
    const [auctionOpen, setAuctionOpen] = useState(false);
    const [closeToClose, setCloseToClose] = useState(false);
    const {
        data: serverTimeOffset,
        isLoading: serverTimeLoading,
        isError: serverTimeError,
    } = useGetServerTimeOffset();

    useEffect(() => {
        if (!currentAuctionBidClose || !currentAuctionBidOpen) {
            setAuctionOpen(false);
            return setTimeRemaining("Auction Ended");
        }

        if (serverTimeError) {
            setAuctionOpen(false);
            return setTimeRemaining("Error Loading Time");
        }

        if (serverTimeLoading) {
            return setTimeRemaining("Calculating...");
        }

        const interval = setInterval(() => {
            const diff = intervalToDuration({
                start: addMilliseconds(new Date(), serverTimeOffset),
                end: new Date(currentAuctionBidClose),
            });

            const timeRemaining = differenceInSeconds(
                new Date(currentAuctionBidClose),
                addMilliseconds(new Date(), serverTimeOffset)
            );

            if (timeRemaining <= 0) {
                if (!auctionPendingClose && !onlineAuctionClosed) {
                    setAuctionState((p) => ({
                        ...p,
                        auctionPendingClose: true,
                    }));
                    return setTimeRemaining("Pending...");
                }
                if (onlineAuctionClosed) {
                    setAuctionOpen(false);
                    clearInterval(interval);
                    return setTimeRemaining("Auction Ended");
                }
                return;
            } else if (auctionPendingClose) {
                setAuctionState((p) => ({
                    ...p,
                    auctionPendingClose: false,
                }));
            }
            if (timeRemaining < 60) {
                setCloseToClose(true);
            } else {
                setCloseToClose(false);
            }

            let formatted = "";
            let highestUnit = -1;

            ["years", "months", "days", "hours", "minutes", "seconds"].forEach(
                (unit, i) => {
                    let amount = diff[unit];
                    if (amount === undefined) {
                        return;
                    }
                    if (amount <= 0 && highestUnit === -1) {
                        return;
                    }
                    highestUnit = i;
                    formatted += (amount + "").padStart(2, "0");
                    if (unit !== "seconds") {
                        formatted += ":";
                    }
                }
            );

            setTimeRemaining(formatted);
            setAuctionOpen(true);
        }, 1000);

        return () => clearInterval(interval);
    }, [
        currentAuctionBidClose,
        currentAuctionBidOpen,
        serverTimeOffset,
        serverTimeError,
        serverTimeLoading,
        onlineAuctionClosed,
        setAuctionState,
        auctionPendingClose,
    ]);

    useMountEffect(() => {
        if (!auctionOpen && auctionPendingClose) {
            setAuctionState((p) => ({
                ...p,
                auctionPendingClose: false,
            }));
        }
        setAuctionState((p) => ({ ...p, onlineAuctionClosed: !auctionOpen }));
    }, [auctionOpen]);

    return (
        <div className="flex items-center text-gray-600">
            <ClockIcon className="w-4 h-4 md:w-5 md:h-5 mr-1" />
            <p className="text-sm md:text-base">
                Time Left:{" "}
                <span
                    className={`font-bold text-gray-900 ml-1 ${
                        timeRemaining === "Auction Ended" || closeToClose
                            ? "text-cta"
                            : "text-gray-900 "
                    }`}
                >
                    {timeRemaining}
                </span>
            </p>
        </div>
    );
};

export default AuctionTimer;
