import { useContext } from "react";
import { useDropzone } from "react-dropzone";
import { TractMapContext } from "../context/TractMapContext";
import ImageList from "./ImageList";
import { useToasts } from "react-toast-notifications";

export default function UploadImage() {
    const { tractMapShowing, uploadTractImage } = useContext(TractMapContext);
    const { addToast } = useToasts();

    const { getRootProps, getInputProps } = useDropzone({
        accept: [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "image/bmp",
            "image/webp",
        ],
        onDrop: (acceptedFiles, rejectedFiles) => {
            acceptedFiles.forEach(async (file) => await uploadTractImage(file));
            rejectedFiles.forEach((file) =>
                addToast(
                    <ErrorMessage
                        title={`Unable to upload ${file.file.name}`}
                        msg={file.errors[0].message}
                    />,
                    { appearance: "warning", autoDismiss: true }
                )
            );
        },
    });

    return (
        <div
            className={`flex justify-center ${
                tractMapShowing ? "hidden" : "block"
            }`}
        >
            <section className="container flex justify-center p-1 space-x-4">
                <div style={{ width: 600 }}>
                    <div
                        {...getRootProps({
                            className: `dropzone`,
                        })}
                    >
                        <input {...getInputProps()} />
                        <DragImage />
                    </div>
                </div>
                <ImageList />
            </section>
        </div>
    );
}
const DragImage = () => {
    return (
        <button
            type="button"
            className="relative block border-2 border-gray-300 bg-gray-100 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            style={{ height: 500, width: 600 }}
        >
            <svg
                className="mx-auto h-16 w-16 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
                aria-hidden="true"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
            </svg>
            <span className="mt-2 block text-sm font-medium text-gray-900">
                Drag and drop an image here or click to browse files
            </span>
            <span className="mt-1 block text-xs max-w-sm text-center mx-auto font-medium text-gray-700">
                Bounding lines should be absolute black and have a width of at
                least&nbsp;5px. Supported formats are BMP, PNG, and JPG.
            </span>
        </button>
    );
};

const ErrorMessage = ({ title, msg }) => (
    <>
        <h1 className="font-bold w-56">{title}</h1>
        <p>{msg}</p>
    </>
);
