import React, { useContext } from "react";
import { AdminAuctionContext } from "../context/AdminAuctionContext";
import TractMap from "../components/TractMap";
import UploadImage from "../components/UploadImage";
import TractMapProvider from "../context/TractMapContext";
import AuctionInactiveMessage from "../components/AuctionInactiveMessage";

export default function Tracts() {
    const { currentAuctionIsLive } = useContext(AdminAuctionContext);

    return (
        <TractMapProvider>
            {!currentAuctionIsLive && <AuctionInactiveMessage />}
            <UploadImage />
            <TractMap />
        </TractMapProvider>
    );
}
