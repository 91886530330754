import formatSequential from "../../../../utilities/formatSequential";
import TractParser from "../../../../utilities/TractParser";
import validateBid from "../helpers/validateBid";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
    switch (action.type) {
        // Edit context
        case "EDIT_CONTEXT":
            return {
                ...state,
                ...action.payload,
            };
        // Reset a state property to it's inital state
        case "RESET_ONE_CONTEXT":
            return {
                ...state,
                [action.key]: action.payload[action.key],
            };
        case "RESET_ALL_CONTEXT":
            return {
                ...action.payload,
            };
        case "EDIT_TRACT_INPUT":
            try {
                const tractAlphas = action.payload.match(/[a-zA-Z]/g);
                const alias = !!tractAlphas
                    ? tractAlphas.join("").toUpperCase()
                    : "";
                const { tractString } = TractParser(
                    action.payload.replace(/[a-z]/gi, "")
                );
                return {
                    ...state,
                    tractInput: tractString,
                    tractInputFormatted: alias + formatSequential(tractString),
                    runSideEffects:
                        tractString !== state.tractInput ||
                        state.whatIf === null
                            ? true
                            : false,
                };
            } catch (error) {
                console.error(error.message);
                if (error.message === "Invalid range") {
                    return {
                        ...state,
                        tractInput: "",
                        tractInputFormatted: "",
                        errorText: "Invalid tract range",
                        runSideEffects: false,
                    };
                }
                return state;
            }
        case "EDIT_BID_INPUT":
            const { message, disabled } = validateBid(
                action.payload,
                state.tractInput,
                action.combinations
            );
            return {
                ...state,
                bidAmount: action.payload,
                biddingDisabled: disabled,
                errorText: message,
            };
        case "RESET_SIDE_EFFECTS":
            return {
                ...state,
                whatIf: null,
                bidderNumber: "",
                bidAcres: null,
                biddingDisabled: true,
                errorText: "",
                runSideEffects: false,
            };
        default:
            return state;
    }
};
