import { useContext, useEffect, useState } from "react";
import { format, isValid } from "date-fns";
import { Table } from "../../components/Table";
import Button from "../../components/Button";
import countAcres from "../../utilities/countAcres";
import formatSequential from "../../utilities/formatSequential";
import formatUSD from "../../utilities/formatUSD";
import { AdminAuctionContext } from "../context/AdminAuctionContext";
import {
    UniqueCombinations,
    UpdateBidAliases,
} from "../../services/BiddingService";
import WheelSpinner from "../../components/WheelSpinner";
import apiClient from "../../utilities/ApiClient";
import { useEvent } from "@harelpls/use-pusher";
import AuctionInactiveMessage from "../components/AuctionInactiveMessage";

export default function BidBoard() {
    const [bids, setBids] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {
        currentAuctionTracts,
        currentAuctionId,
        currentAuctionIsLive,
        auctionChannel,
    } = useContext(AdminAuctionContext);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            let bids = await UniqueCombinations(currentAuctionId);
            bids.bids.sort((a, b) => {
                const aIsSingleTract =
                    !a.bid_alias &&
                    !a.bid_name.includes("+") &&
                    !a.bid_name.includes("-");
                const bIsSingleTract =
                    !b.bid_alias &&
                    !b.bid_name.includes("+") &&
                    !b.bid_name.includes("-");

                if (aIsSingleTract && bIsSingleTract) {
                    return parseInt(a.bid_name) > parseInt(b.bid_name) ? 1 : -1;
                } else if (!aIsSingleTract && bIsSingleTract) {
                    return 1;
                } else if (aIsSingleTract && !bIsSingleTract) {
                    return -1;
                }
                if (!a.bid_alias && !b.bid_alias) {
                    return a.bid_name.localeCompare(b.bid_name, "en");
                }
                if (!a.bid_alias) return 1;
                if (!b.bid_alias) return -1;

                return a.bid_alias.localeCompare(b.bid_alias, "en");
            });
            setBids(bids.bids);
            setIsLoading(false);
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const bidNiceTime = (bidDateTime) => {
        if (isValid(new Date(bidDateTime))) {
            // let difference = new Date() - new Date(bidDateTime);
            // let differenceInDays = Math.round(
            //     Math.abs(difference / (24 * 60 * 60 * 1000))
            // );
            // if (differenceInDays > 7) {
            return format(Date.parse(bidDateTime), "MM/dd/yyyy h:mma");
            // }
            // return formatDistance(new Date(bidDateTime), new Date(), {
            //     addSuffix: true,
            // });
        } else {
            return "";
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let data = [];
        let bid_aliases = [];
        let bid_names = [];
        let original_aliases = [];
        let original_names = [];

        for (const element of event.target.elements) {
            if (element.name === "bid_name") {
                bid_names.push(element.value);
            }
            if (element.name === "bid_alias") {
                bid_aliases.push(element.value);
            }
            if (element.name === "original_alias") {
                original_aliases.push(element.value);
            }
            if (element.name === "original_name") {
                original_names.push(element.value);
            }
        }
        bid_names.forEach((e, i) => {
            data.push({
                bid_name: e,
                bid_alias: bid_aliases[i],
                original_name: original_names[i],
                original_alias: original_aliases[i] ?? "",
            });
        });

        setIsLoading(true);
        await UpdateBidAliases(currentAuctionId, data);
        RefreshBids();
    };

    const RefreshBids = async () => {
        await apiClient.post(`auctions/${currentAuctionId}/rebuild`);
    };

    useEvent(
        auctionChannel,
        "App\\Events\\RebuildOutputReceived",
        async ({ data }) => {
            if (data.message !== "Auction Rebuild Complete") return;
            let bids = await UniqueCombinations(currentAuctionId);
            bids.bids.sort((a, b) => {
                const aIsSingleTract =
                    !a.bid_alias &&
                    !a.bid_name.includes("+") &&
                    !a.bid_name.includes("-");
                const bIsSingleTract =
                    !b.bid_alias &&
                    !b.bid_name.includes("+") &&
                    !b.bid_name.includes("-");

                if (aIsSingleTract && bIsSingleTract) {
                    return parseInt(a.bid_name) > parseInt(b.bid_name) ? 1 : -1;
                } else if (!aIsSingleTract && bIsSingleTract) {
                    return 1;
                } else if (aIsSingleTract && !bIsSingleTract) {
                    return -1;
                }
                if (!a.bid_alias && !b.bid_alias) {
                    return a.bid_name.localeCompare(b.bid_name, "en");
                }
                if (!a.bid_alias) return 1;
                if (!b.bid_alias) return -1;

                return a.bid_alias.localeCompare(b.bid_alias, "en");
            });
            setBids(bids.bids);
            setIsLoading(false);
        }
    );

    return (
        <>
            {!currentAuctionIsLive && <AuctionInactiveMessage />}

            {isLoading && (
                <div className="text-center pt-6">
                    <WheelSpinner size="50" />
                </div>
            )}
            {!isLoading && (
                <form onSubmit={handleSubmit}>
                    <Table className="bg-white rounded-xl mb-8 border border-brand-gray-200">
                        <Table.Header>
                            <Table.HeaderCell className="text-center bg-brand-gray-200">
                                <div className="py-2">Alias</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell className="text-center bg-brand-gray-200">
                                Tracts
                            </Table.HeaderCell>
                            <Table.HeaderCell className="text-center bg-brand-gray-200">
                                Acres
                            </Table.HeaderCell>
                            <Table.HeaderCell className="text-center bg-brand-gray-200">
                                Bidder
                            </Table.HeaderCell>
                            <Table.HeaderCell className="text-center bg-brand-gray-200">
                                Bid Amount
                            </Table.HeaderCell>
                            <Table.HeaderCell className="text-center bg-brand-gray-200">
                                PPA
                            </Table.HeaderCell>
                            <Table.HeaderCell className="text-center bg-brand-gray-200">
                                Timestamp
                            </Table.HeaderCell>
                        </Table.Header>
                        <Table.Body>
                            {bids.map((bid, i) => (
                                <Table.Row
                                    className={
                                        i % 2 !== 0 && "bg-brand-gray-200"
                                    }
                                    key={bid.bid_id}
                                >
                                    <Table.Cell className="text-center py-2">
                                        {bid.bid_name.split("+").length > 1 && (
                                            <>
                                                <input
                                                    name="original_alias"
                                                    type="hidden"
                                                    defaultValue={bid.bid_alias}
                                                />
                                                <input
                                                    name="bid_alias"
                                                    className="border border-gray-400 rounded w-12 p-2 text-center"
                                                    defaultValue={bid.bid_alias}
                                                    onClick={(e) =>
                                                        e.target.select()
                                                    }
                                                />
                                            </>
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                        {!bid.bid_alias &&
                                        !bid.bid_name.includes("+") &&
                                        !bid.bid_name.includes("-") ? (
                                            <>
                                                {formatSequential(bid.bid_name)}
                                            </>
                                        ) : (
                                            <>
                                                <input
                                                    name="original_name"
                                                    type="hidden"
                                                    defaultValue={bid.bid_name}
                                                />
                                                <input
                                                    name="bid_name"
                                                    className="border border-gray-400 rounded p-2 text-center"
                                                    defaultValue={formatSequential(
                                                        bid.bid_name
                                                    )}
                                                    onClick={(e) =>
                                                        e.target.select()
                                                    }
                                                />
                                            </>
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                        {Math.round(
                                            countAcres(
                                                bid.bid_name,
                                                currentAuctionTracts
                                            )
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                        {bid.bidder_number && (
                                            <>{`#${bid.bidder_number}`}</>
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                        {formatUSD(Math.round(bid.bid_amount))}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">{`${formatUSD(
                                        Math.round(
                                            bid.bid_amount /
                                                countAcres(
                                                    bid.bid_name,
                                                    currentAuctionTracts
                                                )
                                        )
                                    )}/ac`}</Table.Cell>
                                    <Table.Cell className="text-center">
                                        {bidNiceTime(bid.bid_timestamp)}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>

                    <div className="text-center mb-12">
                        <Button size="lg" htmlType="submit">
                            Update Aliases
                        </Button>
                    </div>
                </form>
            )}
        </>
    );
}
