export default function Pager({
    from,
    to,
    total,
    current_page,
    last_page,
    onClickNext,
    onClickPrevious,
}) {
    const onFirstPage = () => {
        return current_page <= 1;
    };

    const onLastPage = () => {
        return last_page === current_page;
    };

    return (
        <nav
            className="bg-transparent px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
            aria-label="Pagination"
        >
            <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                    Showing
                    <span className="font-medium"> {from} </span>
                    to
                    <span className="font-medium"> {to} </span>
                    of
                    <span className="font-medium"> {total} </span>
                    results
                </p>
            </div>
            <div className="flex-1 flex justify-between sm:justify-end">
                <button
                    className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ${
                        onFirstPage()
                            ? "opacity-50 cursor-not-allowed"
                            : "opacity-100"
                    }`}
                    onClick={() =>
                        onClickPrevious(Math.max(current_page - 1, 1))
                    }
                    disabled={onFirstPage()}
                >
                    Previous
                </button>
                <button
                    className={`ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ${
                        onLastPage()
                            ? "opacity-50 cursor-not-allowed"
                            : "opacity-100"
                    }`}
                    onClick={() =>
                        onClickNext(Math.min(current_page + 1, last_page))
                    }
                    disabled={onLastPage()}
                >
                    Next
                </button>
            </div>
        </nav>
    );
}
