import { useContext } from "react";
import { Redirect } from "react-router-dom";
import { logoutUser } from "../services/SessionService";
import { AuthContext } from "../contexts/AuthContext";

export default function Login() {
    const authContext = useContext(AuthContext);

    (async () => {
        try {
            await logoutUser();
            authContext.setIsLoggedIn(false);
            authContext.setLoggedInUser({});
        } catch (error) {
            console.log(error);
        }
    })();

    return <Redirect to={"/login"} />;
}
