import apiClient from "../utilities/ApiClient";

export const loginUser = async (data) => {
    try {
        let res = await apiClient.post("login", data);
        return res.data.data;
    } catch (error) {
        return Promise.reject(new Error(error.response.data.message));
    }
};

export const checkLogin = async () => {
    try {
        let res = await apiClient.get("login");
        return res.data.data.user;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const sendForgotPasswordEmail = async (data) => {
    try {
        let res = await apiClient.post("password/email", data);
        return res.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const checkResetToken = async (data) => {
    try {
        let res = await apiClient.post("password/check-token", data);
        return res.data;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const resetPassword = async (data) => {
    try {
        let res = await apiClient.post("password/reset", data);
        return res.data;
    } catch (error) {
        console.error(error);
        return Promise.reject(error.response.data.data.error);
    }
};

export const logoutUser = async () => {
    try {
        await apiClient.delete("login");
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const forgotPassword = async (email) => {
    try {
        await apiClient.post("forgot-password", { email });
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const resetUserPassword = async (data) => {
    try {
        await apiClient.post("password/reset", data);
        return true;
    } catch (error) {
        console.log(error);
        return Promise.reject(error.response?.data?.data?.error);
    }
};

const SessionService = {
    loginUser,
    logoutUser,
    checkLogin,
};

export default SessionService;
