import { Fragment, useContext, useEffect, useState } from "react";
import { AuctionContext } from "../context/AuctionContext";
import { Card, CardTitle } from "../../components/Card";
import { Table } from "../../components/Table";
import WheelSpinner from "../../components/WheelSpinner";
import formatUSD from "../../utilities/formatUSD";
import formatSequential from "../../utilities/formatSequential";
import { AuthContext } from "../../contexts/AuthContext";
import sortHighBids from "../../utilities/sortHighBids";
import EmptyTableState from "./EmptyTableState";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";
import Button from "../../components/Button";
import { PlaceBidContext } from "./PlaceBidCard/context/PlaceBidContext";

export default function HighBidCard() {
    const {
        bidsLoading: isLoading,
        currentAuctionTracts,
        currentAuctionHighBids,
    } = useContext(AuctionContext);

    const [bidData, setBidData] = useState([]);
    const [showMobile, setShowMobile] = useState(false);

    useEffect(() => {
        if (currentAuctionHighBids)
            setBidData(sortHighBids(currentAuctionHighBids));
    }, [currentAuctionHighBids]);

    return (
        <Card style={{ maxHeight: 320 }}>
            <CardTitle>
                <button
                    onClick={() => setShowMobile((p) => !p)}
                    className="flex justify-between items-center w-full"
                >
                    <div className="font-medium">POSTED HIGH BIDS</div>
                    {showMobile ? (
                        <ChevronDownIcon className="text-gray-400 h-8 w-8 block md:hidden" />
                    ) : (
                        <ChevronUpIcon className="text-gray-400 h-8 w-8 block md:hidden" />
                    )}
                </button>
            </CardTitle>

            {isLoading && (
                <div className="flex justify-center items-center h-3/4">
                    <WheelSpinner size="64" />
                </div>
            )}
            <div
                style={{ maxHeight: 235 }}
                className="-mb-3 md:mb-4 overflow-y-auto"
            >
                {!isLoading && currentAuctionTracts.length > 0 && (
                    <>
                        <HighBidTable bids={bidData} showMobile={showMobile} />
                    </>
                )}
                {!isLoading && currentAuctionTracts.length === 0 && (
                    <HighBidTableNoTracts bids={bidData} />
                )}
            </div>
        </Card>
    );
}
function HighBidTable({ bids, showMobile }) {
    const { currentAuctionRecentBid, bidder } = useContext(AuctionContext);
    const { loggedInUser } = useContext(AuthContext);

    return (
        <>
            {bids.length === 0 && (
                <div className="mb-4 h-full">
                    <EmptyTableState
                        title="No bids"
                        body="Looks like a bid hasn't been placed yet."
                    />
                </div>
            )}
            <Transition
                as={Fragment}
                show={showMobile}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <ul className="divide-y divide-gray-200 md:hidden">
                    {bids.map((bid) => (
                        <li key={bid.bid_id}>
                            <MobileCard bid={bid} />
                        </li>
                    ))}
                </ul>
            </Transition>
            {bids.length > 0 && (
                <Table className="hidden md:block">
                    <Table.Header>
                        <Table.HeaderCell>Alias</Table.HeaderCell>
                        <Table.HeaderCell>Tracts</Table.HeaderCell>
                        <Table.HeaderCell>Acres</Table.HeaderCell>
                        <Table.HeaderCell>Bidder</Table.HeaderCell>
                        <Table.HeaderCell>Bid Amount</Table.HeaderCell>
                        <Table.HeaderCell>PPA</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {bids.map((data, i) => (
                            <Table.Row
                                className={`${
                                    currentAuctionRecentBid?.bid_id ===
                                    data.bid_id
                                        ? currentAuctionRecentBid?.bidder
                                              ?.bidder_number ===
                                          bidder?.bidder_number
                                            ? "bg-success bg-opacity-50"
                                            : "bg-cta bg-opacity-50"
                                        : i % 2 !== 0
                                        ? "bg-brand-gray-200"
                                        : "bg-none"
                                }`}
                                key={data.bid_id}
                            >
                                <Table.Cell>{data.bid_alias}</Table.Cell>
                                <Table.Cell>
                                    {formatSequential(data.bid_name)}
                                </Table.Cell>
                                <Table.Cell>
                                    {Math.round(parseFloat(data.acres))}
                                </Table.Cell>
                                <Table.Cell>
                                    {data.user_id === loggedInUser.id ? (
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                            YOU
                                        </span>
                                    ) : (
                                        data.bidder_number && (
                                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                                #{data.bidder_number}
                                            </span>
                                        )
                                    )}
                                </Table.Cell>
                                <Table.Cell>
                                    {formatUSD(data.bid_amount)}
                                </Table.Cell>
                                <Table.Cell>{`${formatUSD(
                                    data.ppa
                                )}/ac`}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )}
        </>
    );
}

const HighBidTableNoTracts = ({ bids }) => {
    return (
        <Table>
            <Table.Header>
                <Table.HeaderCell>Alias</Table.HeaderCell>
                <Table.HeaderCell>Tracts</Table.HeaderCell>
                <Table.HeaderCell>Bidder</Table.HeaderCell>
                <Table.HeaderCell>Bid Amount</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
                {bids.map((data, i) => (
                    <Table.Row
                        className={i % 2 !== 0 && "bg-brand-gray-200"}
                        key={i}
                    >
                        <Table.Cell>{data.bid_alias}</Table.Cell>
                        <Table.Cell>{data.bid_name}</Table.Cell>
                        <Table.Cell>{data.bidder_number}</Table.Cell>
                        <Table.Cell>{data.bid_amount}</Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
};

const MobileCard = ({ bid }) => {
    const { currentAuctionRecentBid, bidder } = useContext(AuctionContext);
    const { loggedInUser } = useContext(AuthContext);
    const { handleOutsideBid } = useContext(PlaceBidContext);

    return (
        <div
            className={`py-4 px-2 flex items-center justify-between ${
                currentAuctionRecentBid?.bid_id === bid.bid_id
                    ? currentAuctionRecentBid?.bidder?.bidder_number ===
                      bidder?.bidder_number
                        ? "bg-success bg-opacity-50"
                        : "bg-cta bg-opacity-50"
                    : "bg-none"
            }`}
        >
            <div>
                <div className="flex items-center justify-between">
                    <div className="text-sm leading-none text-gray-900 truncate">
                        <span className="text-gray-500 text-xs">Tract(s):</span>{" "}
                        {bid.bid_alias ? bid.bid_alias : ""}{" "}
                        {formatSequential(bid.bid_name)}
                    </div>
                </div>
                <div className="flex items-center text-sm font-bold text-green-800 truncate pt-2">
                    <span className="">{formatUSD(bid.bid_amount)}</span>
                    <div className="ml-2 flex-shrink-0 flex">
                        {bid.user_id === loggedInUser.id ? (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                YOU
                            </span>
                        ) : (
                            bid.bidder_number && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                    #{bid.bidder_number}
                                </span>
                            )
                        )}
                    </div>
                </div>
                <span className="text-xs text-gray-500 font-normal">
                    (
                    {`${formatUSD(bid.ppa)}/ac for ${Math.round(
                        parseFloat(bid.acres)
                    )} acres`}
                    )
                </span>
            </div>
            <Button
                onClick={() => handleOutsideBid(bid.bid_name)}
                type="secondary"
                size="sm"
            >
                <span className="text-sm">BID NOW</span>
            </Button>
        </div>
    );
};
