import { useContext } from "react";
import { Table } from "../../../../components/Table";
import classNames from "../../../../utilities/classNames";
import countAcres from "../../../../utilities/countAcres";
import formatSequential from "../../../../utilities/formatSequential";
import formatUSD from "../../../../utilities/formatUSD";
import { AuctionContext } from "../../../context/AuctionContext";
import { PlaceBidContext } from "../context/PlaceBidContext";

export default function PlaceBidTable() {
    const {
        currentAuctionCombinations: data,
        videoIsDetached,
        currentAuctionTracts,
    } = useContext(AuctionContext);
    const { tractArray, handleTractInputChange } = useContext(PlaceBidContext);

    const onSelectTracts = (value) => {
        const currentTracts = tractArray;
        const newTracts = value.split("+");
        const newArray = currentTracts.concat(newTracts);
        const arrayNoWhitespace = newArray.filter((tract) => parseInt(tract));
        const removeDuplicates = [...new Set(arrayNoWhitespace)];
        const sortedArray = removeDuplicates.sort((a, b) => a - b);
        const tractString = sortedArray.join("+");

        return handleTractInputChange(tractString);
    };

    const onDeselectTracts = (value) => {
        const currentTracts = tractArray;
        const tractsToRemove = value.split("+");
        const tractsRemoved = currentTracts.filter(
            (tract) => !tractsToRemove.includes(tract)
        );
        const sortedArray = tractsRemoved.sort((a, b) => a - b);
        const tractString = sortedArray.join("+");

        return handleTractInputChange(tractString);
    };

    return (
        <div
            className={classNames(
                videoIsDetached ? "max-h-48" : "max-h-64",
                "overflow-y-auto"
            )}
        >
            <Table>
                <Table.Header>
                    <Table.HeaderCell>
                        <span className="sr-only">Select Tract(s)</span>
                    </Table.HeaderCell>
                    <Table.HeaderCell>Alias</Table.HeaderCell>
                    <Table.HeaderCell>Tract(s)</Table.HeaderCell>
                    <Table.HeaderCell>Acres</Table.HeaderCell>
                    <Table.HeaderCell>Current Bid</Table.HeaderCell>
                    <Table.HeaderCell>PPA</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {data.map((tract) => {
                        const tractArr = tract.tractName.split("+");
                        return (
                            <Table.Row key={tract.tractName}>
                                <Table.Cell>
                                    <div className="flex items-center h-5">
                                        <input
                                            id="settings-option-0"
                                            name="privacy_setting"
                                            type="checkbox"
                                            className="focus:ring-indigo-500 h-4 w-4 text-cta cursor-pointer border-gray-300"
                                            checked={tractArr.every((t) =>
                                                tractArray.includes(t)
                                            )}
                                            onChange={(e) =>
                                                e.target.checked
                                                    ? onSelectTracts(
                                                          tract.tractName
                                                      )
                                                    : onDeselectTracts(
                                                          tract.tractName
                                                      )
                                            }
                                        />
                                    </div>
                                </Table.Cell>
                                <Table.Cell>
                                    {tract.alias ? `${tract.alias} ` : ""}
                                </Table.Cell>
                                <Table.Cell>
                                    {formatSequential(tract.tractName)}
                                </Table.Cell>
                                <Table.Cell>
                                    {!!tract.acres
                                        ? Math.round(parseFloat(tract.acres))
                                        : Math.round(
                                              parseFloat(
                                                  countAcres(
                                                      tract.tractName,
                                                      currentAuctionTracts
                                                  )
                                              )
                                          )}
                                </Table.Cell>
                                <Table.Cell>
                                    {tract.highBid
                                        ? formatUSD(tract.highBid)
                                        : ""}
                                </Table.Cell>
                                <Table.Cell>
                                    {tract.ppa
                                        ? `${formatUSD(tract.ppa)}/ac`
                                        : ""}
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </div>
    );
}
