import { useContext } from "react";
import addCommasToNumber from "../../../../user/helpers/addCommasToNumber";
import { PlaceBidContext } from "../context/PlaceBidContext";

export default function BidInput() {
    const { bidAmount, handleBidInputChange } = useContext(PlaceBidContext);

    return (
        <>
            <div className="flex mt-1 relative">
                <div className="bold w-18 mt-3 text-sm">Amount</div>
                <div className="ml-6 flex-1">
                    <input
                        value={bidAmount}
                        onChange={(e) => handleBidInputChange(e.target.value)}
                        className="w-full h-8 p-2 rounded-md border-solid border-gray-200 border"
                        type="number"
                        onWheel={(e) => {
                            e.target.blur();
                        }}
                    />
                    <div className="absolute inset-y-0 right-6 pr-2 flex items-center pointer-events-none">
                        <div className="w-full text-sm text-gray-400">
                            {bidAmount > 0 ? (
                                <span>
                                    x1000 ={" "}
                                    <span className="text-green-700 font-bold">
                                        ${addCommasToNumber(bidAmount * 1000)}
                                    </span>
                                </span>
                            ) : (
                                "x1000"
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
