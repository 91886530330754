import { useQuery } from "react-query";
import apiClient from "../utilities/ApiClient";

const ServerTimeService = async () => {
    try {
        let res = await apiClient.get("server-time");
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const useGetServerTimeOffset = () => {
    return useQuery(
        "server-time-offset",
        () =>
            ServerTimeService().then((res) => {
                const offset = res.time * 1000 - Date.now();
                return offset;
            }),
        {
            refetchInterval: 1000 * 60 * 5, // refresh every 5 minutes
        }
    );
};

export default useGetServerTimeOffset;
