import { useEffect, useState } from "react";
import AuthLayout from "./components/AuthLayout";
import { Link, Redirect, useLocation } from "react-router-dom";
import { checkResetToken, resetUserPassword } from "../services/SessionService";
import Button from "../components/Button";
import Input from "../components/Input";
import Alert from "../components/Alert";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ResetPassword() {
    const [message, setMessage] = useState("");
    const [error, setError] = useState(null);
    const [validToken, setValidToken] = useState(false);
    const [token, setToken] = useState(null);
    const [email, setEmail] = useState("");
    const [passwordReset, setPasswordReset] = useState(false);
    let query = useQuery();

    useEffect(() => {
        async function checkToken() {
            try {
                let urlToken = query.get("token");
                let params = {
                    token: urlToken,
                };
                let res = await checkResetToken(params);
                setValidToken(res.data.valid);
                setToken(urlToken);
                setEmail(res.data.email);
            } catch (error) {
                console.log(error);
            }
        }

        checkToken();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const new_password = data.get("new_password");
        const password_confirm = data.get("confirm_password");
        try {
            let params = {
                email,
                token,
                password: new_password,
                password_confirmation: password_confirm,
            };
            let res = await resetUserPassword(params);
            if (res) {
                setMessage("Password Reset!");
                setInterval(() => {
                    setPasswordReset(true);
                }, 2000);
            }
        } catch (error) {
            console.log(error);
            setError(error[Object.keys(error)[0]][0]);
        }
    };

    if (passwordReset) {
        return <Redirect to={"/"} />;
    }

    return (
        <AuthLayout title="Update Your Password">
            {message !== "" && (
                <Alert type="success" className="mb-8 mx-auto max-w-sm">
                    {message}
                </Alert>
            )}
            {error && <Alert className="mb-8 mx-auto max-w-sm">{error}</Alert>}

            {validToken !== true && (
                <>
                    <div className="text-white text-center p-6 rounded-lg bg-black mb-6">
                        The token you have provided is either invalid or has
                        expired.
                    </div>
                    <Link
                        className="block mt-5 text-bold text-center text-sm text-white hover:text-gray-300 focus:outline-none"
                        to="/login"
                    >
                        Back to Login
                    </Link>
                </>
            )}

            {validToken && (
                <form className="w-full" onSubmit={(e) => handleSubmit(e)}>
                    <div className="max-w-xs mx-auto">
                        <div className="mb-10">
                            <Input
                                borderless
                                htmlType="password"
                                placeholder="New Password"
                                name="new_password"
                                required
                            />
                            <br />
                            <Input
                                borderless
                                htmlType="password"
                                placeholder="Confirm Password"
                                name="confirm_password"
                                required
                            />
                        </div>
                        <Button
                            className="block mt-12 mx-auto font-secondary"
                            size="lg"
                            type="light"
                            htmlType="submit"
                            rounded
                        >
                            Submit
                        </Button>
                        <Link
                            className="block mt-5 text-bold text-center text-sm text-white hover:text-gray-300 focus:outline-none"
                            to="/login"
                        >
                            Back to Login
                        </Link>
                    </div>
                </form>
            )}
        </AuthLayout>
    );
}
