import React, { useContext } from "react";
import { AdminAuctionContext } from "../../../context/AdminAuctionContext";
import { PlaceBidContext } from "../context/PlaceBidContext";
import { allowedTractKeys } from "../helpers/allowedTractKeys";
import useTractInputChange from "../hooks/useTractInputChange";

const TractInput = React.forwardRef((_, ref) => {
    const handleChange = useTractInputChange();
    const { tractInputFormatted, tractRef } = useContext(PlaceBidContext);
    const { currentAuctionAcres } = useContext(AdminAuctionContext);
    const handleKeyDown = (e) => {
        if (!allowedTractKeys.includes(e.key) && !e.key.match(/[a-z]/i)) {
            e.preventDefault();
        }
    };
    return (
        <div className="flex">
            <div className="bold w-18 mt-2 text-sm">Tract(s)</div>
            <div className="ml-6 flex-1">
                <div className="flex">
                    <div className="w-3/4 flex-1 relative">
                        <input
                            value={tractInputFormatted}
                            onChange={handleChange}
                            ref={tractRef}
                            onKeyDown={(e) => {
                                handleKeyDown(e);
                            }}
                            className={`w-full h-8 p-2 rounded-md border-solid border-gray-200 border`}
                        />
                    </div>
                </div>
                <div className="py-1 text-xs text-gray-400">
                    Auction has {Math.round(currentAuctionAcres)} acres
                </div>
            </div>
        </div>
    );
});

export default TractInput;
