import { format } from "date-fns";
import React, { useContext, useEffect } from "react";
import apiClient from "../../../../utilities/ApiClient";
import { AdminAuctionContext } from "../../../context/AdminAuctionContext";
import { AdminChatContext } from "../context/AdminChatContext";

const ChatMessageAdmin = () => {
    const { currentAuctionId } = useContext(AdminAuctionContext);
    const { messages, currentSelectedBidder } = useContext(AdminChatContext);
    const [messageContent, setMessageContent] = React.useState("");
    const messageLogRef = React.useRef(null);

    useEffect(() => {
        if (!messages) return;
        messageLogRef.current.scrollTop = messageLogRef.current.scrollHeight;
    }, [messages]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let dateTime = new Date().toISOString().slice(0, 19).replace("T", " "); // mysql YYYY-MM-DD h:i:s equivelant

        let data = {
            message: {
                message_auction_id: currentAuctionId,
                message_body: messageContent,
                message_from_admin: 1,
                message_read: 0,
                message_bidder_contact_id:
                    currentSelectedBidder !== "ADMIN"
                        ? currentSelectedBidder.bidder_contact_id
                        : null,
                message_created_on: dateTime,
                message_bidder_id:
                    currentSelectedBidder !== "ADMIN"
                        ? currentSelectedBidder.bidder_id
                        : null,
            },
        };

        try {
            await apiClient.post("chat/save", data);
            setMessageContent("");
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <form
            onSubmit={handleSubmit}
            className="w-3/4 p-6 relative flex flex-col"
            id="messageWindow"
        >
            <div
                className="overflow-y-auto overflow-x-auto admin-message-window flex-1"
                id="windowScroll"
                ref={messageLogRef}
            >
                <div className="flex flex-col">
                    {messages &&
                        messages.map((message, i) => (
                            <React.Fragment key={message.message_id}>
                                <DateStamp
                                    date={message.message_created_on}
                                    prevDate={
                                        i !== 0
                                            ? messages[i - 1].message_created_on
                                            : "01/01/1970" // Forces the first date to be displayed
                                    }
                                />
                                {/* Red, right-aligned message */}
                                {message.message_from_admin === 1 && (
                                    <AdminMessage
                                        message={message.message_body}
                                        global={
                                            message.message_bidder_id === null
                                        }
                                        timestamp={message.message_created_on}
                                    />
                                )}
                                {/* Gray, left-aligned message */}
                                {message.message_from_admin === 0 && (
                                    <UserMessage
                                        message={message.message_body}
                                        name={
                                            currentSelectedBidder
                                                ? `${currentSelectedBidder.user.contact_first_name} ${currentSelectedBidder.user.contact_last_name}`
                                                : ""
                                        }
                                        global={
                                            message.message_bidder_contact_id ===
                                            null
                                        }
                                        timestamp={message.message_created_on}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                </div>
            </div>
            <div className="absolute bottom-0 left-0 w-full bg-brand-gray-100">
                <textarea
                    value={messageContent}
                    onChange={(e) => setMessageContent(e.target.value)}
                    onKeyDown={(e) => {
                        // submit if key is enter
                        if (e.key === "Enter") {
                            e.preventDefault();
                            handleSubmit(e);
                        }
                    }}
                    className="border rounded-md resize-none border-gray-200 text-gray-600 text-sm focus:outline-none outline-none chat-input admin-chat-input user-chat-input"
                    placeholder="Your message..."
                ></textarea>
            </div>
        </form>
    );
};

const AdminMessage = ({ message, global, timestamp }) => (
    <div className="mb-4 self-end">
        <div className="w-full flex flex-col items-end">
            <div className="text-xs text-red-700 text-right">ADMIN</div>
            <div className="p-4 box-border inline-block rounded-lg text-sm bg-cta text-white ml-auto">
                {message}
            </div>

            <div className="text-xs mt-1 pl-1 ml-auto w-full text-right">
                {global && (
                    <span className="text-gray-500">Sent to Everyone at </span>
                )}
                <span className="text-gray-500">
                    {format(new Date(timestamp), "h:mm aa")}
                </span>
            </div>
        </div>
    </div>
);

const UserMessage = ({ message, name, timestamp }) => (
    <div className="mb-4">
        <div className="text-xs">
            <span className="text-gray-700">{name}</span>
        </div>
        <div className="p-4 text-sm inline-block rounded-lg bg-gray-200">
            {message}
        </div>
        <div className="text-xs mt-1 pl-1">
            <span className="text-gray-500">
                {format(new Date(timestamp), "h:mm aa")}
            </span>
        </div>
    </div>
);

const DateStamp = ({ date, prevDate }) => {
    const formattedDate = format(new Date(date), "MMM d, yyyy");
    const formattedPrev = format(new Date(prevDate), "MMM d, yyyy");

    if (formattedDate === formattedPrev) {
        return null;
    }

    return (
        <div className="text-xs text-gray-500 w-full text-center">
            {formattedDate}
        </div>
    );
};

export default ChatMessageAdmin;
