import { useRef } from "react";
import Draggable from "react-draggable";

export default function DragTract({ drop, tractNumber, ...rest }) {
    const nodeRef = useRef(null);
    return (
        <Draggable
            handle={`.tract${tractNumber}`}
            scale={1}
            onStop={(e, d) => drop(e, d)}
            nodeRef={nodeRef}
            {...rest}
        >
            <div
                className={`tract${tractNumber} border-2 border-black rounded-full cursor-move text-center w-10 h-7 mr-2 mb-2`}
                ref={nodeRef}
            >
                <div className="font-bold px-2 rounded-full bg-white">
                    {tractNumber}
                </div>
            </div>
        </Draggable>
    );
}
