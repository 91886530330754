import { useCallback, useContext, useEffect, useState, useRef } from "react";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import { Table } from "../../components/Table";
import Icon from "../../components/Icon";
import { ApproveBid, RejectBid } from "../../services/BiddingService";
import { CurrentBids } from "../../services/ActiveAuctionService";
import countAcres from "../../utilities/countAcres";
import formatSequential from "../../utilities/formatSequential";
import formatUSD from "../../utilities/formatUSD";
import { AdminAuctionContext } from "../context/AdminAuctionContext";
import EditBidModal from "../components/EditBidModal";
import WheelSpinner from "../../components/WheelSpinner";
import { format } from "date-fns";
import { useQueryClient } from "react-query";
import ReactToPrint from "react-to-print";
import PrintHeader from "../components/PrintHeader";
import AuctionInactiveMessage from "../components/AuctionInactiveMessage";
import { useToasts } from "react-toast-notifications";

export default function Bids() {
    const [bids, setBids] = useState([]);
    const [bidsOrder, setBidsOrder] = useState("desc");
    const [bidsAreLoading, setBidsAreLoading] = useState(false);
    const [loadingBids, setLoadingBids] = useState([]);
    const [editBidData, setEditBidData] = useState({
        bid_amount: "",
        bid_bidder_id: "",
        bid_name: "",
    });
    const [showEditBidModal, setShowEditBidModal] = useState(false);
    const {
        currentAuctionId,
        currentAuctionBids,
        currentAuctionTracts,
        currentAuctionTitle,
        currentAuctionIsLive,
    } = useContext(AdminAuctionContext);
    const queryClient = useQueryClient();
    const tableRef = useRef();
    const { addToast } = useToasts();

    useEffect(() => {
        if (currentAuctionBids) setBids(currentAuctionBids.bids);
    }, [currentAuctionBids]);

    const approveBid = async (id) => {
        try {
            setLoadingBids((prev) => [...prev, id]);
            await ApproveBid(id)
                .then((res) => async () => {
                    await queryClient.invalidateQueries([
                        "currentBids",
                        currentAuctionId,
                    ]);
                })
                .catch((err) =>
                    addToast(
                        <div>
                            <h2>{err.response.data.message}</h2>
                        </div>,
                        { appearance: "warning", autoDismiss: true }
                    )
                );

            setTimeout(
                () =>
                    setLoadingBids((prev) =>
                        prev.filter((bidID) => bidID !== id)
                    ),
                500
            );
        } catch (error) {
            console.log(error);
        }
    };

    const rejectBid = async (id) => {
        try {
            setLoadingBids((prev) => [...prev, id]);
            await RejectBid(id);

            await queryClient.invalidateQueries([
                "currentBids",
                currentAuctionId,
            ]);
            setTimeout(
                () =>
                    setLoadingBids((prev) =>
                        prev.filter((bidID) => bidID !== id)
                    ),
                500
            );
        } catch (error) {
            console.log(error);
        }
    };

    const openEditBidModal = async (bid) => {
        setShowEditBidModal(true);
        bid.bidder_number = bid.bidder.bidder_number;
        setEditBidData(bid);
    };

    const reverseBids = () => {
        bidsOrder === "desc" ? setBidsOrder("asc") : setBidsOrder("desc");
        bids.reverse();
    };

    const SortButton = () => {
        return (
            <Button onClick={() => reverseBids()} className="mb-3">
                {bidsOrder === "desc" && <>Reverse &darr;</>}
                {bidsOrder === "asc" && <>Reverse &uarr;</>}
            </Button>
        );
    };

    const RenderApproveRow = useCallback(
        ({ approved, bidID, onApprove, onReject, isLoading, key }) => {
            if (isLoading) {
                return (
                    <div className="mx-auto w-3" key={key}>
                        <Spinner width={24} />
                    </div>
                );
            }
            switch (approved) {
                case 2:
                    return (
                        <div className="flex space-x-2 items-center justify-center">
                            <Button
                                onClick={() => onApprove(bidID)}
                                size="xs"
                                type="success"
                                className="bid-btn"
                            >
                                accept
                            </Button>

                            <XIcon className="text-cta w-6 h-6" />
                        </div>
                    );
                case 1:
                    return (
                        <CheckIcon className="text-success w-6 h-6 mx-auto" />
                    );
                default:
                    return (
                        <div
                            className="flex space-x-2 items-center justify-center"
                            key={key}
                        >
                            <Button
                                onClick={() => onApprove(bidID)}
                                size="xs"
                                type="success"
                                className="bid-btn"
                            >
                                accept
                            </Button>
                            <Button
                                onClick={() => onReject(bidID)}
                                size="xs"
                                className="bid-btn"
                            >
                                reject
                            </Button>
                        </div>
                    );
            }
        },
        []
    );

    if (bidsAreLoading) {
        return (
            <div className="text-center pt-6">
                <WheelSpinner size="50" />
            </div>
        );
    }

    return (
        <>
            <iframe id="iframePrintContents" title="Print"></iframe>

            {!currentAuctionIsLive && <AuctionInactiveMessage />}

            <div className="flex">
                <div className="w-1/2">
                    <SortButton />
                </div>
                <div className="w-1/2 text-right">
                    <ReactToPrint
                        trigger={() => (
                            <Icon
                                type="print"
                                width="24px"
                                className="mr-2 float-right cursor-pointer no-print"
                                color="brand-gray-500"
                            />
                        )}
                        content={() => tableRef.current}
                    />
                </div>
            </div>
            <div ref={tableRef} className="tableEl">
                <PrintHeader
                    title="All Bids"
                    auction={`${currentAuctionTitle} Auction`}
                />
                <Table className="rounded-2xl" id="bids-table">
                    <Table.Header>
                        <Table.HeaderCell className="text-center">
                            Alias
                        </Table.HeaderCell>
                        <Table.HeaderCell className="text-center">
                            Tracts
                        </Table.HeaderCell>
                        <Table.HeaderCell className="text-center">
                            Acres
                        </Table.HeaderCell>
                        <Table.HeaderCell className="text-center">
                            Bidder
                        </Table.HeaderCell>
                        <Table.HeaderCell className="text-center">
                            Bid Amount
                        </Table.HeaderCell>
                        <Table.HeaderCell className="text-center">
                            PPA
                        </Table.HeaderCell>
                        <Table.HeaderCell className="text-center">
                            Timestamp
                        </Table.HeaderCell>
                        <Table.HeaderCell className="text-center">
                            Approved
                        </Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {bids.map((bid, i) => (
                            <Table.Row
                                className={i % 2 !== 0 && "bg-brand-gray-200"}
                                key={bid.bid_id}
                            >
                                <Table.Cell className="text-center">
                                    <div className="text-center">
                                        {bid.bid_alias}
                                    </div>
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                    <div
                                        className="cursor-pointer text-blue-600"
                                        onClick={() => openEditBidModal(bid)}
                                    >
                                        {formatSequential(bid.bid_name)}
                                    </div>
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                    {Math.round(
                                        countAcres(
                                            bid.bid_name,
                                            currentAuctionTracts
                                        )
                                    )}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                    <div
                                        className="cursor-pointer text-blue-600"
                                        onClick={() => openEditBidModal(bid)}
                                    >
                                        {bid.bidder?.bidder_number ? (
                                            <>{`#${bid.bidder.bidder_number}`}</>
                                        ) : (
                                            <>n/a</>
                                        )}
                                    </div>
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                    <div
                                        className="cursor-pointer text-blue-600"
                                        onClick={() => openEditBidModal(bid)}
                                    >
                                        {formatUSD(bid.bid_amount)}
                                    </div>
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                    {`${formatUSD(
                                        Math.round(
                                            bid.bid_amount /
                                                countAcres(
                                                    bid.bid_name,
                                                    currentAuctionTracts
                                                )
                                        )
                                    )}/ac`}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                    {format(
                                        Date.parse(bid.bid_created_on),
                                        "yyyy-MM-dd @ h:mma"
                                    )}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                    <RenderApproveRow
                                        approved={bid.bid_approved}
                                        bidID={bid.bid_id}
                                        onApprove={(id) => approveBid(id)}
                                        onReject={(id) => rejectBid(id)}
                                        isLoading={loadingBids.includes(
                                            bid.bid_id
                                        )}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
            <EditBidModal
                open={showEditBidModal}
                bidData={editBidData}
                onCancel={() => setShowEditBidModal(false)}
                onConfirm={async () => {
                    setShowEditBidModal(false);
                    setBidsAreLoading(true);
                    let bids = await CurrentBids(currentAuctionId);
                    setBidsAreLoading(false);
                    setBids(bids.data.bids);
                }}
            />
        </>
    );
}
