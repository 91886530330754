import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useRef,
} from "react";
import { WhatIf } from "../../../../services/ActiveAuctionService";
import { AdminAuctionContext } from "../../../context/AdminAuctionContext";

import PlaceBidContextReducer from "./PlaceBidContextReducer";

const initialState = {
    tractInput: "",
    tractInputFormatted: "",
    bidAmount: "",
    bidderNumber: "",
    whatIf: null,
    runSideEffects: false,
    bidAcres: null,
    biddingDisabled: true,
    errorText: "",
    placeBidModalOpen: false,
};

export const PlaceBidContext = createContext(initialState);

export const PlaceBidProvider = ({ children }) => {
    const [state, dispatch] = useReducer(PlaceBidContextReducer, initialState);
    const {
        currentAuctionId,
        currentAuctionAliases,
        currentAuctionCombinations,
    } = useContext(AdminAuctionContext);
    const formIncomplete =
        !state.tractInput || !state.bidAmount || !state.bidderNumber;
    const tractRef = useRef();

    // Bid side effects
    const runBidSideEffects = useCallback(
        (value) => {
            WhatIf({ tracts: value.tracts, auction_id: currentAuctionId })
                .then((res) => {
                    // update the state with the what if data
                    editContext({
                        whatIf: res.message,
                        bidAcres: parseFloat(res.message.request_acres),
                        runSideEffects: false,
                    });
                    // Re-run the bid input change handler to validate the bid with the new what-if
                    if (value.bid) {
                        dispatch({
                            type: "EDIT_BID_INPUT",
                            payload: value.bid,
                            combinations: currentAuctionCombinations,
                        });
                    }
                    // Check if an existing bidder exists for this combination
                    const combination = currentAuctionCombinations.find(
                        (combination) => combination.bid_name === value.tracts
                    );
                    dispatch({
                        type: "EDIT_CONTEXT",
                        payload: {
                            bidderNumber: combination
                                ? combination.bidder_number
                                : "",
                        },
                    });
                })
                .catch((err) => {
                    console.log(err);
                    return resetSideEffects();
                });
        },
        [currentAuctionId, currentAuctionCombinations]
    );

    useEffect(() => {
        if (state.runSideEffects) {
            runBidSideEffects({
                tracts: state.tractInput,
                bid: state.bidAmount,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.runSideEffects, runBidSideEffects]);

    // Actions
    function aliasLookup(value) {
        const tractAlphas = value.match(/[a-z]/gi);
        const alias = !!tractAlphas ? tractAlphas.join("").toUpperCase() : null;
        const bid = currentAuctionAliases.find(
            (bid) => bid.bid_alias?.toLowerCase() === alias.toLowerCase()
        );
        if (bid) {
            dispatch({
                type: "EDIT_TRACT_INPUT",
                payload: alias + bid.bid_name,
            });
        } else {
            dispatch({
                type: "EDIT_TRACT_INPUT",
                payload: value,
            });
        }
    }
    function handleTractInputChange(value) {
        dispatch({
            type: "EDIT_TRACT_INPUT",
            payload: value,
        });
    }
    function handleBidInputChange(value) {
        dispatch({
            type: "EDIT_BID_INPUT",
            payload: value,
            combinations: currentAuctionCombinations,
        });
    }
    function handleBidderNumberChange(value) {
        dispatch({
            type: "EDIT_CONTEXT",
            payload: { bidderNumber: value },
        });
    }
    function togglePlaceBidModal(bool) {
        dispatch({
            type: "EDIT_CONTEXT",
            payload: { placeBidModalOpen: bool },
        });
    }
    function editContext(val) {
        dispatch({
            type: "EDIT_CONTEXT",
            payload: val,
        });
    }
    function resetSideEffects() {
        dispatch({
            type: "RESET_SIDE_EFFECTS",
            payload: initialState,
        });
    }
    function resetAllContext() {
        dispatch({
            type: "RESET_ALL_CONTEXT",
            payload: initialState,
        });
        setTimeout(() => tractRef.current.focus(), 250);
    }
    return (
        <PlaceBidContext.Provider
            value={{
                ...state,
                biddingDisabled: state.biddingDisabled || formIncomplete,
                handleTractInputChange,
                handleBidInputChange,
                handleBidderNumberChange,
                aliasLookup,
                togglePlaceBidModal,
                editContext,
                resetSideEffects,
                resetAllContext,
                tractRef,
            }}
        >
            {children}
        </PlaceBidContext.Provider>
    );
};
