import axios from "axios";
import apiClient from "../utilities/ApiClient";

const UploadTractImage = async (data) => {
    const res = await apiClient.post("tracts/image", data, {
        headers: {
            "content-type": "multipart/form-data",
        },
    });

    return res.data;
};

const UploadTractImageColorized = async (data, id) => {
    const res = await apiClient.post(`tracts/colorized-image/${id}`, data, {
        headers: {
            "content-type": "multipart/form-data",
        },
    });

    window.location.reload();

    return res.data;
};

const UpdateTractImage = async (image_id, data) => {
    const res = await apiClient.put(`tracts/image/${image_id}`, data);

    return res;
};

const GetTractImages = async (auctionID) => {
    const { data } = await apiClient.get(`tracts/image/${auctionID}`);

    return data;
};

// TODO: Replace with link to S3
const GetTractImageBuffer = async (imgName) => {
    const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/image/${imgName}`
    );

    return data;
};

const DeleteTractImage = async (imgID) => {
    const res = await apiClient.delete(`tracts/image/${imgID}`);
    return res;
};

const TractImageService = {
    UploadTractImage,
    GetTractImages,
    GetTractImageBuffer,
    UploadTractImageColorized,
    DeleteTractImage,
    UpdateTractImage,
};

export default TractImageService;
