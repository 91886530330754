import axios from "axios";
import { showDD } from "./DumpProvider";

const apiClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1/`,
    withCredentials: true,
});

const getCsrfToken = async () => {
    await apiClient
        .get(`${process.env.REACT_APP_BASE_URL}/sanctum/csrf-cookie`)
        .catch((error) => {
            console.log(error);
        });
};

apiClient.interceptors.request.use(
    async (config) => {
        if (
            ["POST", "PUT", "PATCH", "DELETE"].indexOf(
                config.method.toUpperCase()
            ) > -1 &&
            config.url.indexOf("csrf") === -1 &&
            config.url.indexOf("login") > -1
        ) {
            await getCsrfToken();
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    function (res) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (res.headers.hasOwnProperty("x-is-dd")) {
            showDD(res.data);
            return new Error("Response is DD");
        }
        return res;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

export default apiClient;
