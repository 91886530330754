import formatUSD from "../../../../utilities/formatUSD";

const validateBid = (bid, minimum, match) => {
    if (bid === "" || match === null) {
        return {
            message: "",
            disabled: true,
            bidLeads: false,
        };
    }
    const currentBid = parseFloat(bid) * 1000;
    if (currentBid < minimum) {
        return {
            message: `Bid must meet or exceed ${formatUSD(minimum)}`,
            disabled: true,
            bidLeads: false,
        };
    } else if (currentBid < match) {
        return {
            message: `Your bid of ${formatUSD(currentBid)} is ${formatUSD(
                match - currentBid
            )} behind - Increase your bid to get in the lead`,
            disabled: false,
            bidLeads: false,
        };
    } else if (currentBid === match) {
        return {
            message: `Your bid of ${formatUSD(
                currentBid
            )} just ties and will not put you in the lead`,
            disabled: false,
            bidLeads: false,
        };
    } else {
        return {
            message: `Your bid of ${formatUSD(
                currentBid
            )} puts you in the lead by ${formatUSD(currentBid - match)}`,
            disabled: false,
            bidLeads: true,
        };
    }
};

export default validateBid;
