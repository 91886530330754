import { useState, useContext, useEffect, Fragment } from "react";
import {
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    XIcon,
} from "@heroicons/react/solid";
import { ClockIcon } from "@heroicons/react/outline";
import { AuctionContext } from "../context/AuctionContext";
import { Card, CardTitle } from "../../components/Card";
import { Table } from "../../components/Table";
import { AuthContext } from "../../contexts/AuthContext";
import formatUSD from "../../utilities/formatUSD";
import EmptyTableState from "./EmptyTableState";
import { Transition } from "@headlessui/react";
import formatSequential from "../../utilities/formatSequential";
import countAcres from "../../utilities/countAcres";

export default function YourBidsCard() {
    const [yourBids, setYourBids] = useState([]);
    const {
        currentAuctionBids,
        currentAuctionCombinations,
        currentAuctionTracts,
    } = useContext(AuctionContext);
    const { loggedInUser } = useContext(AuthContext);
    const [showMobile, setShowMobile] = useState(false);

    useEffect(() => {
        if (!currentAuctionBids) return;
        const usersBids = currentAuctionBids.bids.filter(
            (bid) => bid?.bidder?.bidder_contact_id === loggedInUser.id
        );
        const usersBidsFormatted = usersBids.map((bid) => {
            let matchingCombination = currentAuctionCombinations.find(
                (combination) => combination.tractName === bid.bid_name
            );
            if (!matchingCombination.acres) {
                matchingCombination = {
                    acres: countAcres(bid.bid_name, currentAuctionTracts),
                };
            }
            return {
                ...bid,
                bid_acres: matchingCombination.acres,
                bid_amount: bid.bid_amount,
                bid_ppa: (bid.bid_amount / matchingCombination.acres).toFixed(
                    0
                ),
            };
        });
        setYourBids(usersBidsFormatted);
    }, [
        currentAuctionBids,
        loggedInUser.id,
        currentAuctionCombinations,
        currentAuctionTracts,
    ]);

    return (
        <Card style={{ maxHeight: 320 }} className="md:h-full card">
            <CardTitle>
                <button
                    onClick={() => setShowMobile((p) => !p)}
                    className="flex justify-between items-center w-full"
                >
                    <div className="font-medium">YOUR BIDS</div>
                    {showMobile ? (
                        <ChevronDownIcon className="text-gray-400 h-8 w-8 block md:hidden" />
                    ) : (
                        <ChevronUpIcon className="text-gray-400 h-8 w-8 block md:hidden" />
                    )}
                </button>
            </CardTitle>
            <div
                style={{ maxHeight: 235 }}
                className="-mb-3 md:mb-0 overflow-y-auto"
            >
                <YourBidsTable bids={yourBids} showMobile={showMobile} />
            </div>
        </Card>
    );
}

function YourBidsTable({ bids, showMobile }) {
    const { currentAuctionRecentBid } = useContext(AuctionContext);

    const RenderApprovedIcon = ({ approved, sm = false }) => {
        if (approved === 0)
            return (
                <div className="flex items-center">
                    <ClockIcon className="text-gray-400 w-5 h-5 mr-1" />
                    <p
                        className={`text-gray-400 font-bold ${
                            sm ? "text-sm" : ""
                        }`}
                    >
                        Pending
                    </p>
                </div>
            );
        if (approved === 2)
            return (
                <div className="flex items-center">
                    <XIcon className="text-cta w-5 h-5 mr-1" />
                    <p className={`text-cta font-bold ${sm ? "text-sm" : ""}`}>
                        Rejected
                    </p>
                </div>
            );

        return (
            <div className="flex items-center">
                <CheckIcon className="text-success w-5 h-5 mr-1" />
                <p className={`text-success font-bold ${sm ? "text-sm" : ""}`}>
                    Approved
                </p>
            </div>
        );
    };
    return (
        <>
            {bids.length === 0 && (
                <div className="mb-4">
                    <EmptyTableState
                        title="No bids"
                        body="Looks like you haven't placed a bid yet."
                        padding="p-4"
                    />
                </div>
            )}
            <Transition
                as={Fragment}
                show={showMobile}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <ul className="divide-y divide-gray-200 md:hidden">
                    {bids.map((bid) => (
                        <li key={bid.bid_id}>
                            <MobileCard
                                bid={bid}
                                icon={
                                    <RenderApprovedIcon
                                        approved={bid.bid_approved}
                                        sm
                                    />
                                }
                            />
                        </li>
                    ))}
                </ul>
            </Transition>
            {bids.length > 0 && (
                <Table className="hidden md:block">
                    <Table.Header>
                        <Table.HeaderCell>Alias</Table.HeaderCell>
                        <Table.HeaderCell>Tracts</Table.HeaderCell>
                        <Table.HeaderCell>Acres</Table.HeaderCell>
                        <Table.HeaderCell>Bid Amount</Table.HeaderCell>
                        <Table.HeaderCell>PPA</Table.HeaderCell>
                        <Table.HeaderCell>Approved</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {bids.map((bid, i) => (
                            <Table.Row
                                key={bid.bid_id}
                                className={`${
                                    currentAuctionRecentBid?.bid_id ===
                                    bid.bid_id
                                        ? "bg-success bg-opacity-50"
                                        : i % 2 !== 0
                                        ? "bg-brand-gray-200"
                                        : "bg-none"
                                }`}
                            >
                                <Table.Cell>{bid.bid_alias}</Table.Cell>
                                <Table.Cell>
                                    {formatSequential(bid.bid_name)}
                                </Table.Cell>
                                <Table.Cell>
                                    {Math.round(parseFloat(bid.bid_acres))}
                                </Table.Cell>
                                <Table.Cell>
                                    {formatUSD(bid.bid_amount)}
                                </Table.Cell>
                                <Table.Cell>
                                    {formatUSD(bid.bid_ppa)}/ac
                                </Table.Cell>
                                <Table.Cell>
                                    <RenderApprovedIcon
                                        approved={bid.bid_approved}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )}
        </>
    );
}

const MobileCard = ({ bid, icon }) => {
    const { currentAuctionRecentBid, bidder } = useContext(AuctionContext);

    return (
        <div
            className={`py-4 px-2 ${
                currentAuctionRecentBid?.bid_id === bid.bid_id
                    ? currentAuctionRecentBid?.bidder?.bidder_number ===
                      bidder?.bidder_number
                        ? "bg-success bg-opacity-50"
                        : "bg-cta bg-opacity-50"
                    : "bg-none"
            }`}
        >
            <div className="flex items-center justify-between">
                <div className="text-sm leading-none text-gray-900 truncate">
                    <span className="text-gray-500 text-xs">Tract(s):</span>{" "}
                    {bid.bid_alias ? bid.bid_alias : ""}{" "}
                    {formatSequential(bid.bid_name)}
                </div>
                <div className="ml-2 flex-shrink-0 flex">{icon}</div>
            </div>
            <div className="flex items-center text-sm font-bold text-green-800 truncate py-2">
                <span className="">{formatUSD(bid.bid_amount)}</span>
                <span className="text-xxs pl-2 text-gray-500 font-normal my-auto">
                    ({`${formatUSD(bid.bid_ppa)}/ac`})
                </span>
            </div>
        </div>
    );
};
