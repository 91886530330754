import { useContext, useEffect, useState } from "react";
import { AuctionContext } from "../context/AuctionContext";
import { AuthContext } from "../../contexts/AuthContext";
import { Card, CardTitle } from "../../components/Card";
import { Table } from "../../components/Table";
import WheelSpinner from "../../components/WheelSpinner";
import formatUSD from "../../utilities/formatUSD";
import formatSequential from "../../utilities/formatSequential";
import { sortCompletion } from "../../utilities/sortCompletions";
import EmptyTableState from "./EmptyTableState";

export default function BestWayCard() {
    const { bidsLoading: isLoading, currentAuctionCompletion } =
        useContext(AuctionContext);
    const [completionData, setCompletionData] = useState(null);

    useEffect(() => {
        if (!currentAuctionCompletion) return;
        setCompletionData(sortCompletion(currentAuctionCompletion));
    }, [currentAuctionCompletion]);

    return (
        <>
            {completionData && (
                <Card
                    border="border border-gray-300 sm:border-2 sm:border-gray-700"
                    style={{ maxHeight: 320 }}
                    className="h-auto card"
                >
                    <CardTitle>
                        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                            <div className="font-medium text-left">
                                BEST WAY TO SELL THE PROPERTY
                            </div>
                            {!!completionData.completion_sale_price && (
                                <div className="font-bold text-gray-700">
                                    Total:{" "}
                                    {formatUSD(
                                        completionData.completion_sale_price
                                    )}
                                </div>
                            )}
                        </div>
                    </CardTitle>
                    {isLoading && (
                        <div className="flex justify-center items-center h-3/4">
                            <WheelSpinner size="64" />
                        </div>
                    )}
                    <div
                        style={{ maxHeight: 235 }}
                        className="-mb-3 md:mb-0 overflow-y-auto"
                    >
                        {!isLoading && (
                            <BestWayTable completion={completionData} />
                        )}
                    </div>
                </Card>
            )}
        </>
    );
}

function BestWayTable({ completion }) {
    const { currentAuctionRecentBid, bidder } = useContext(AuctionContext);
    const { loggedInUser } = useContext(AuthContext);
    return (
        <>
            {completion.bids.length === 0 && (
                <div className="mb-4">
                    <EmptyTableState
                        title="Nothing here"
                        body="There isn't enough bids to determine this yet."
                        padding="p-4"
                    />
                </div>
            )}

            <ul className="divide-y divide-gray-200 md:hidden">
                {completion.bids.map((bid) => (
                    <li key={bid.bid_id}>
                        <MobileCard bid={bid} />
                    </li>
                ))}
            </ul>
            {completion.bids && completion.bids.length > 0 && (
                <Table className="hidden md:block">
                    <Table.Header>
                        <Table.HeaderCell>Alias</Table.HeaderCell>
                        <Table.HeaderCell>Tract(s)</Table.HeaderCell>
                        <Table.HeaderCell>Acres</Table.HeaderCell>
                        <Table.HeaderCell>Bidder</Table.HeaderCell>
                        <Table.HeaderCell>Total</Table.HeaderCell>
                        <Table.HeaderCell>PPA</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {completion.bids &&
                            completion.bids.map((data, i) => (
                                <Table.Row
                                    className={`${
                                        currentAuctionRecentBid?.bid_id ===
                                        data.bid_id
                                            ? currentAuctionRecentBid?.bidder
                                                  ?.bidder_number ===
                                              bidder?.bidder_number
                                                ? "bg-success bg-opacity-50"
                                                : "bg-cta bg-opacity-50"
                                            : i % 2 !== 0
                                            ? "bg-brand-gray-200"
                                            : "bg-none"
                                    }`}
                                    key={data.bid_name}
                                >
                                    <Table.Cell>{data.bid_alias}</Table.Cell>
                                    <Table.Cell>
                                        {formatSequential(data.bid_name)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {Math.round(parseFloat(data.acres))}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <div className="w-full">
                                            {data.bidder?.bidder_contact_id ===
                                            loggedInUser.id ? (
                                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                    YOU
                                                </span>
                                            ) : (
                                                data.bidder?.bidder_number && (
                                                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                                        #
                                                        {
                                                            data.bidder
                                                                .bidder_number
                                                        }
                                                    </span>
                                                )
                                            )}
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatUSD(data.bid_amount)}
                                    </Table.Cell>
                                    <Table.Cell>{`${formatUSD(
                                        data.ppa
                                    )}/ac`}</Table.Cell>
                                </Table.Row>
                            ))}
                    </Table.Body>
                </Table>
            )}
        </>
    );
}

const MobileCard = ({ bid }) => {
    const { currentAuctionRecentBid, bidder } = useContext(AuctionContext);
    const { loggedInUser } = useContext(AuthContext);

    return (
        <div
            className={`py-4 px-2 ${
                currentAuctionRecentBid?.bid_id === bid.bid_id
                    ? currentAuctionRecentBid?.bidder?.bidder_number ===
                      bidder?.bidder_number
                        ? "bg-success bg-opacity-50"
                        : "bg-cta bg-opacity-50"
                    : "bg-none"
            }`}
        >
            <div className="flex items-center justify-between">
                <div className="text-sm leading-none text-gray-900 truncate">
                    <span className="text-gray-500 text-xs">Tract(s):</span>{" "}
                    {bid.bid_alias ? bid.bid_alias : ""}{" "}
                    {formatSequential(bid.bid_name)}
                </div>
                <div className="ml-2 flex-shrink-0 flex">
                    {bid.bid_bidder_id?.toString() ===
                    loggedInUser.id.toString() ? (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            YOU
                        </span>
                    ) : (
                        bid.bidder?.bidder_number && (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                #{bid.bidder?.bidder_number}
                            </span>
                        )
                    )}
                </div>
            </div>
            <div className="flex items-center text-sm font-bold text-green-800 truncate py-2">
                <span className="">{formatUSD(bid.bid_amount)}</span>
                <span className="text-xxs pl-2 text-gray-500 font-normal my-auto">
                    (
                    {`${formatUSD(bid.ppa)}/ac for ${Math.round(
                        parseFloat(bid.acres)
                    )} acres`}
                    )
                </span>
            </div>
        </div>
    );
};
