import { useQuery, useMutation, useQueryClient } from "react-query";
import apiClient from "../utilities/ApiClient";

export const useGetFavoriteAuctions = ({ user_id }) => {
    return useQuery(
        "getFavorites",
        () => {
            return apiClient
                .get(`favorite-auctions?user_id=${user_id}`)
                .then((res) => res.data.data);
        },
        { refetchOnWindowFocus: false, enabled: !!user_id }
    );
};

export const useAddFavoriteAuction = () => {
    const queryCache = useQueryClient();
    return useMutation(
        (values) =>
            apiClient.post("favorite-auctions", values).then((res) => res.data),
        {
            onMutate: (values) => {
                queryCache.cancelQueries("getFavorites");

                const oldData = queryCache.getQueryData("getFavorites");

                queryCache.setQueryData("getFavorites", [
                    ...oldData,
                    values.favorited_auctions_auction_id,
                ]);

                return () => queryCache.setQueryData("getFavorites", oldData);
            },
            onError: (error, values, rollback) => {
                if (rollback) {
                    rollback();
                }
            },
            onSuccess: (data) => {
                queryCache.setQueryData("getFavorites", data.data.favorites);
            },
            onSettled: () => {
                queryCache.invalidateQueries("getFavorites");
            },
        }
    );
};

export const useRemoveFavoriteAuction = () => {
    const queryCache = useQueryClient();
    return useMutation(
        (values) =>
            apiClient
                .delete(`favorite-auctions/${values.auction_id}`)
                .then((res) => res.data),
        {
            onMutate: (values) => {
                queryCache.cancelQueries("getFavorites");

                const oldData = queryCache.getQueryData("getFavorites");

                queryCache.setQueryData(
                    "getFavorites",
                    oldData.filter(
                        (auction_id) => auction_id !== values.auction_id
                    )
                );

                return () => queryCache.setQueryData("getFavorites", oldData);
            },
            onError: (error, values, rollback) => {
                if (rollback) {
                    rollback();
                }
            },
            onSuccess: (data) => {
                queryCache.setQueryData("getFavorites", data.data.favorites);
            },
            onSettled: () => {
                queryCache.invalidateQueries("getFavorites");
            },
        }
    );
};
