import { useEffect, useContext, useState, useCallback, useRef } from "react";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import { Table } from "../../components/Table";
import Button from "../../components/Button";
import apiClient from "../../utilities/ApiClient";
import { AdminAuctionContext } from "../context/AdminAuctionContext";
import { Completions as CompletionsSvc } from "../../services/ActiveAuctionService";
import { UpdateCompletionCount } from "../../services/ActiveAuctionService";
import formatUSD from "../../utilities/formatUSD";
import sortCompletions from "../../utilities/sortCompletions";
import formatSequential from "../../utilities/formatSequential";
import { ApproveBid, RejectBid } from "../../services/BiddingService";
import { useEvent } from "@harelpls/use-pusher";
import { useQueryClient } from "react-query";
import Spinner from "../../components/Spinner";
import WhatIfCard from "../components/WhatIfCard";
import PlaceBidForm from "../components/PlaceBidForm";
import AuctionInactiveMessage from "../components/AuctionInactiveMessage";
import { useToasts } from "react-toast-notifications";
import { PlaceBidProvider } from "../components/PlaceBidForm/context/PlaceBidContext";
import { AdminChatContext } from "../components/AdminChat/context/AdminChatContext";
import CompletionCard from "../components/CompletionCard";

export default function Online() {
    const { addToast } = useToasts();
    const {
        currentAuctionId,
        currentAuctionBids,
        currentAuctionIsLive,
        auctionChannel,
        currentAuctionCompletionsToShow,
    } = useContext(AdminAuctionContext);
    const { chatMessagesAll: messages } = useContext(AdminChatContext);
    const [bids, setBids] = useState([]);
    const [completionsData, setCompletionsData] = useState([]);
    const [loadingBids, setLoadingBids] = useState([]);
    const queryClient = useQueryClient();
    const [completionsToShow, setCompletionsToShow] = useState(
        currentAuctionCompletionsToShow
    );
    const messageLogRef = useRef(null);

    useEffect(() => {
        if (currentAuctionBids) setBids(currentAuctionBids.bids);
    }, [currentAuctionBids]);

    useEffect(() => {
        if (!messages) return;
        messageLogRef.current.scrollTop = messageLogRef.current.scrollHeight;
    }, [messages]);

    useEvent(auctionChannel, "App\\Events\\CompletionsUpdated", () => {
        refreshCompletions(completionsToShow);
    });

    const approveBid = async (id) => {
        try {
            setLoadingBids((prev) => [...prev, id]);
            await ApproveBid(id).then((e) => {
                if (e.message.approved === true) {
                    addToast(e.message.message, {
                        appearance: "success",
                        autoDismiss: true,
                    });
                } else {
                    addToast(e.message.message, {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            });
            await queryClient.invalidateQueries([
                "currentBids",
                currentAuctionId,
            ]);
            setTimeout(
                () =>
                    setLoadingBids((prev) =>
                        prev.filter((bidID) => bidID !== id)
                    ),
                500
            );
        } catch (error) {
            console.log(error);
        }
    };

    const rejectBid = async (id) => {
        try {
            setLoadingBids((prev) => [...prev, id]);
            await RejectBid(id);
            await queryClient.invalidateQueries([
                "currentBids",
                currentAuctionId,
            ]);
            setTimeout(
                () =>
                    setLoadingBids((prev) =>
                        prev.filter((bidID) => bidID !== id)
                    ),
                500
            );
        } catch (error) {
            console.log(error);
        }
    };

    const RenderApproveRow = useCallback(
        ({ approved, bidID, onApprove, onReject, isLoading, key }) => {
            if (isLoading) {
                return (
                    <div className="mx-auto w-3" key={key}>
                        <Spinner width={24} />
                    </div>
                );
            }
            switch (approved) {
                case 2:
                    return (
                        <div className="flex">
                            <div className="w-1/2 text-right">
                                <Button
                                    onClick={() => onApprove(bidID)}
                                    size="xs"
                                    type="success"
                                    className="mr-3"
                                >
                                    accept
                                </Button>
                            </div>
                            <div className="w-1/2 text-left">
                                <XIcon className="text-cta w-6 h-6 ml-1" />
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    );
                case 1:
                    return (
                        <div className="flex">
                            <div className="w-1/2 text-right">
                                <CheckIcon className="text-success w-6 h-6 mr-5 float-right" />
                            </div>
                            <div className="w-1/2 text-left">
                                <Button
                                    onClick={() => onReject(bidID)}
                                    size="xs"
                                >
                                    reject
                                </Button>
                            </div>
                        </div>
                    );
                default:
                    return (
                        <div className="flex space-x-1" key={key}>
                            <div className="w-1/2 text-right">
                                <Button
                                    onClick={() => onApprove(bidID)}
                                    size="xs"
                                    type="success"
                                >
                                    accept
                                </Button>
                            </div>
                            <div className="w-1/2 text-left">
                                <Button
                                    onClick={() => onReject(bidID)}
                                    size="xs"
                                >
                                    reject
                                </Button>
                            </div>
                        </div>
                    );
            }
        },
        []
    );

    const refreshCompletions = (value) => {
        (async () => {
            let completions = await CompletionsSvc({
                auction_id: currentAuctionId,
                per_page: value,
            });
            await UpdateCompletionCount({
                auction_id: currentAuctionId,
                auction_input_completions: value,
            });
            setCompletionsToShow(value);
            setCompletionsData(sortCompletions(completions.data));
        })();
    };

    const bidderName = (message) => {
        if (message.hasOwnProperty("bidder")) {
            try {
                return `${message.bidder.user.contact_first_name} ${message.bidder.user.contact_last_name}`;
            } catch (e) {
                return "Admin";
            }
        } else {
            return "Admin";
        }
    };

    // post new message to server
    const postMessage = async (data) => {
        try {
            await apiClient.post("chat/save", data);
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    };

    const handleKeyPress = (e) => {
        if (e.charCode === 13) {
            let message = e.target.value;
            let dateTime = new Date()
                .toISOString()
                .slice(0, 19)
                .replace("T", " "); // mysql YYYY-MM-DD h:i:s equivelant

            let data = {
                message: {
                    message_auction_id: currentAuctionId,
                    message_body: message,
                    message_from_admin: 1,
                    message_read: 0,
                    message_bidder_contact_id: null,
                    message_bidder_id: null,
                    message_created_on: dateTime,
                },
                contact_id: null,
            };

            try {
                postMessage(data);
                setTimeout(function () {
                    e.target.value = "";
                }, 50);
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        (async () => {
            let completions = await CompletionsSvc({
                auction_id: currentAuctionId,
                per_page: currentAuctionCompletionsToShow,
            });
            setCompletionsData(sortCompletions(completions.data));
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <PlaceBidProvider>
            {!currentAuctionIsLive && <AuctionInactiveMessage />}
            <div className="mr-4">
                <div className="flex">
                    <div className="w-1/3">
                        <PlaceBidForm detailsOnBottom />
                    </div>

                    <div className="w-1/3 ml-8">
                        <WhatIfCard />
                    </div>
                    <div className="w-1/3 ml-8 border border-gray-300 bg-white rounded-xl pt-1 ">
                        <Table className="max-h-32 overflow-y-scroll">
                            <Table.Header>
                                <Table.HeaderCell className="text-center">
                                    Tracts
                                </Table.HeaderCell>
                                <Table.HeaderCell className="text-center">
                                    Bidder
                                </Table.HeaderCell>
                                <Table.HeaderCell className="text-center">
                                    Bid Amount
                                </Table.HeaderCell>
                                <Table.HeaderCell className="text-center">
                                    Approve
                                </Table.HeaderCell>
                            </Table.Header>

                            <Table.Body className="border-t border-gray-300">
                                {bids
                                    .filter((bid) => bid.bid_approved === 0)
                                    .map((bid, i) => (
                                        <Table.Row
                                            className={
                                                i % 2 !== 0 &&
                                                "bg-brand-gray-200"
                                            }
                                            key={bid.bid_id}
                                            height="h-8"
                                        >
                                            <Table.Cell className="text-center">
                                                {formatSequential(bid.bid_name)}
                                            </Table.Cell>
                                            <Table.Cell className="text-center">
                                                {bid.hasOwnProperty(
                                                    "bidder"
                                                ) && (
                                                    <>{`#${bid.bidder?.bidder_number}`}</>
                                                )}
                                                {!bid.hasOwnProperty(
                                                    "bidder"
                                                ) && <>n/a</>}
                                            </Table.Cell>
                                            <Table.Cell className="text-center">
                                                {formatUSD(bid.bid_amount)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <RenderApproveRow
                                                    approved={bid.bid_approved}
                                                    bidID={bid.bid_id}
                                                    onApprove={(id) =>
                                                        approveBid(id)
                                                    }
                                                    onReject={(id) =>
                                                        rejectBid(id)
                                                    }
                                                    isLoading={loadingBids.includes(
                                                        bid.bid_id
                                                    )}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                {/* </div> */}
                            </Table.Body>
                        </Table>

                        <div className="border-t border-gray-300 p-2">
                            <div className="text-xs font-medium text-gray-400 border-b border-gray-300 -mx-2 pb-2 pl-2">
                                Messages
                            </div>
                            <div
                                className="max-h-28 overflow-y-scroll -mr-2"
                                ref={messageLogRef}
                            >
                                {!!messages &&
                                    messages.map((message) => (
                                        <div
                                            className="text-xs text-gray-600 my-1"
                                            key={message.message_id}
                                        >
                                            <b>{bidderName(message)}:</b>{" "}
                                            {message.message_body}
                                        </div>
                                    ))}
                            </div>
                            <div className="border-t -mx-2 pt-1 border-solid border-gray-300">
                                <input
                                    className="w-full pt-1 pl-2 outline-none text-sm"
                                    placeholder="New message..."
                                    onKeyPress={handleKeyPress}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="mt-4 mb-2" />

                <div className="flex mt-2 mb-2 items-center">
                    <div className="text-lg bold pr-4">Top Completions</div>
                    <div className="flex-1 text-right">
                        <select
                            className="mt-1 h-10 rounded-md border-gray-200 text-sm"
                            onChange={(e) => refreshCompletions(e.target.value)}
                            defaultValue={currentAuctionCompletionsToShow}
                        >
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                        </select>
                    </div>
                </div>
                {completionsData.map((completion) => (
                    <CompletionCard
                        key={completion.completion_id}
                        completion={completion}
                    />
                ))}
            </div>
        </PlaceBidProvider>
    );
}
