import { useContext, useRef } from "react";
import { PlaceBidContext } from "./context/PlaceBidContext";
import { Card } from "../../../components/Card";
import PlaceBidErrorBoundry from "../PlaceBidErrorBoundry";
import TractInput from "./components/TractInput";
import BidInput from "./components/BidInput";
import PlaceBidActions from "./components/PlaceBidActions";
import PlaceBidTable from "./components/PlaceBidTable";
import PlaceBid from "./components/PlaceBid";
import CardHeader from "./components/CardHeader";
import { AuctionContext } from "../../context/AuctionContext";

export default function PlaceBidCard({ ...props }) {
    const tractInputRef = useRef();
    const { placeBidCardRef } = useContext(PlaceBidContext);
    const { auctionPendingClose } = useContext(AuctionContext);

    return (
        <div ref={placeBidCardRef}>
            <PlaceBidErrorBoundry>
                <Card {...props}>
                    <CardHeader />
                    <fieldset disabled={auctionPendingClose}>
                        <div className="space-y-2 justify-between mb-1.5">
                            <TractInput ref={tractInputRef} />
                            <BidInput />
                            <PlaceBidActions />
                        </div>
                        <PlaceBidTable />
                        <PlaceBid tractInputRef={tractInputRef} />
                    </fieldset>
                </Card>
            </PlaceBidErrorBoundry>
        </div>
    );
}
