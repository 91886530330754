import apiClient from "../utilities/ApiClient";

export const ApproveBid = async (bid_id) => {
    try {
        let res = await apiClient.put("auctions/bid/approve", { bid_id });
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const RejectBid = async (bid_id) => {
    try {
        let res = await apiClient.put("auctions/bid/unapprove", { bid_id });
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const UpdateBidAlias = async (bid_id, bid_alias) => {
    try {
        let res = await apiClient.put("auctions/bid/update-alias", {
            bid_id,
            bid_alias,
        });

        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const UpdateBidAliases = async (auction_id, data) => {
    try {
        let res = await apiClient.put(
            "auctions/" + auction_id + "/update-aliases",
            {
                aliases: data,
            }
        );
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const UpdateBidsName = async (bid_id, new_bid_name) => {
    try {
        let res = await apiClient.put("auctions/bid/update-name", {
            bid_id,
            new_bid_name,
        });

        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const UpdateBid = async (data) => {
    try {
        let res = await apiClient.put(`bid/${data.bid_id}/update`, data);
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const RemoveBid = async (bid_id) => {
    try {
        let res = await apiClient.delete(`bid/${bid_id}/destroy`);
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const UniqueCombinations = async (auctionID) => {
    try {
        let res = await apiClient.get(
            `auctions/${auctionID}/unique-combinations`,
            { params: {} }
        );
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
