import PropTypes from "prop-types";
import IconPaths from "./IconPaths";

export default function Icon({
    type,
    width,
    height,
    color,
    className,
    ...props
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={width}
            height={height ? height : width}
            className={`${color} ${className ? className : ""}`}
            {...props}
        >
            <IconPaths type={type} />
        </svg>
    );
}

Icon.propTypes = {
    /**
     * The icon name
     */
    type: PropTypes.string.isRequired,
    /**
     * The Tailwind class name for text color
     */
    color: PropTypes.string,
    /**
     * The height of the icon. If none is provided the height will equal the width prop value.
     */
    height: PropTypes.string,
    /**
     * The width of the icon
     */
    width: PropTypes.string,
    /**
     * Optional: the classes for the icon
     */
    className: PropTypes.string,
};

Icon.defaultProps = {
    color: "text-white",
    width: "25px",
};
