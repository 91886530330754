import { Link, useLocation } from "react-router-dom";

export default function TabNav({
    className,
    children,
    rightContent,
    ...props
}) {
    return (
        <>
            <div className="border-b border-gray-200 flex space-between items-center w-full">
                <nav className="-mb-px flex space-x-8">{children}</nav>
                {rightContent && (
                    <div className="ml-auto hidden md:block">
                        {rightContent}
                    </div>
                )}
            </div>
            {rightContent && (
                <div className="mt-4 block md:hidden">{rightContent}</div>
            )}
        </>
    );
}

export const Item = ({ children, link, className, ...props }) => {
    const isSelected = "border-cta text-cta";
    const location = useLocation();

    return (
        <Link
            to={link + location.search}
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm border-transparent text-gray-500 hover:text-cta hover:border-cta
            ${location.pathname.includes(link) ? isSelected : ""} ${className}`}
            {...props}
        >
            {children}
        </Link>
    );
};
