import { useContext, useEffect } from "react";
import AuctionInactiveMessage from "../components/AuctionInactiveMessage";
import Button from "../../components/Button";
import { AdminAuctionContext } from "../context/AdminAuctionContext";
import { AdminChatContext } from "../components/AdminChat/context/AdminChatContext";
import ChatMessageAdmin from "../components/AdminChat/components/ChatMessageAdmin";
import ChatAdminSidebar from "../components/AdminChat/components/ChatAdminSidebar";

export default function Chat() {
    const { currentAuctionIsLive } = useContext(AdminAuctionContext);
    const { editContext } = useContext(AdminChatContext);

    useEffect(() => {
        // Switch back to admin when user leaves chat
        // This is so we're not marking the last chatters
        // new messages as read
        return () => {
            editContext({ currentSelectedBidder: "ADMIN" });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {!currentAuctionIsLive && <AuctionInactiveMessage />}
            <div className="rounded-lg bg-white shadow-sm">
                <div className="uppercase p-6 border-b border-solid border-gray-300 bg-brand-gray-100">
                    Messaging
                </div>
                <div className="flex admin-chat-window">
                    <div className="w-1/4 overflow-y-scroll p-6 bg-brand-gray-100">
                        <Button
                            className="w-full"
                            onClick={() =>
                                editContext({
                                    currentSelectedBidder: "ADMIN",
                                })
                            }
                        >
                            Admin Messages
                        </Button>
                        <h4 className="pt-4 font-bold">Users</h4>
                        <ChatAdminSidebar />
                    </div>

                    <ChatMessageAdmin />
                </div>
            </div>
        </div>
    );
}
