import React, { useContext } from "react";
import { useQueryClient } from "react-query";
import { AuthContext } from "../../../../contexts/AuthContext";
import { AdminChatContext } from "../context/AdminChatContext";
import { AdminAuctionContext } from "../../../context/AdminAuctionContext";

const ChatAdminSidebar = () => {
    const { loggedInUser } = useContext(AuthContext);
    const {
        activeUsers,
        editContext,
        users,
        currentSelectedBidder,
        markAsRead,
    } = useContext(AdminChatContext);
    const { currentAuctionId } = useContext(AdminAuctionContext);
    const queryClient = useQueryClient();
    const handleClick = (user) => {
        editContext({ currentSelectedBidder: user });
        queryClient.invalidateQueries([
            "messages",
            currentAuctionId + "-" + user.bidder_contact_id,
        ]);
        markAsRead({
            bidder_id: user.bidder_id,
            auction_id: currentAuctionId,
        });
    };
    return users.map((bidder) => (
        <div key={bidder.bidder_id}>
            <div
                id={"chatUser-" + bidder.bidder_id}
                className={
                    "p-3 cursor-pointer rounded" +
                    (bidder.bidder_id === currentSelectedBidder.bidder_id
                        ? " font-bold bg-gray-100"
                        : "") +
                    (bidder.bidder_id === loggedInUser.id ? " hidden" : "") +
                    " chatUser"
                }
                onClick={() => handleClick(bidder)}
            >
                <div className="text-sm">
                    <span className="flex p-2">
                        {activeUsers.includes(
                            bidder.bidder_contact_id.toString()
                        ) !== true && (
                            <div className="w-3 h-3 rounded-full border border-solid bg-gray-300 border-gray-300 -mb-px mr-2"></div>
                        )}

                        {activeUsers.includes(
                            bidder.bidder_contact_id.toString()
                        ) === true && (
                            <div className="bg-green-700 w-3 h-3 rounded-full border border-solid border-green-700 -mb-px mr-2"></div>
                        )}

                        <div className="-mt-1">
                            {!bidder.user?.meta?.last_login && (
                                <span
                                    className="italic text-gray-600"
                                    title="Has not signed in yet."
                                >
                                    #{bidder.bidder_number} -
                                    {bidder.user.contact_first_name}{" "}
                                    {bidder.user.contact_last_name}{" "}
                                    <span className="text-gray-400">
                                        ({bidder.messages_count})
                                    </span>
                                </span>
                            )}

                            {bidder.user?.meta?.last_login && (
                                <span title="Has signed in.">
                                    #{bidder.bidder_number} -
                                    {bidder.user.contact_first_name}{" "}
                                    {bidder.user.contact_last_name}{" "}
                                    <span className="text-gray-400">
                                        ({bidder.messages_count})
                                    </span>
                                </span>
                            )}

                            {bidder.user.contact_company_name !== "" &&
                                !bidder.user?.meta?.last_login && (
                                    <span className="text-xs block italic">
                                        {bidder.user.contact_company_name}
                                    </span>
                                )}

                            {bidder.user.contact_company_name !== "" &&
                                bidder.user?.meta?.last_login && (
                                    <span className="text-xs block">
                                        {bidder.user.contact_company_name}
                                    </span>
                                )}
                        </div>
                        {bidder.latest_message !== null &&
                            parseInt(bidder.message_read) !== 1 && (
                                <div
                                    className="w-3 h-3 bg-cta rounded-full ml-auto"
                                    title="New Message(s)"
                                />
                            )}
                    </span>
                </div>
            </div>
        </div>
    ));
};

export default ChatAdminSidebar;
