import React, { useContext } from "react";
import { TractMapContext } from "../context/TractMapContext";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Button from "../../components/Button";
import { TrashIcon } from "@heroicons/react/outline";

export default function ImageList() {
    const { files, setTractMapContext, reorderImages } = useContext(
        TractMapContext
    );

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const newFiles = reorder(
            files,
            result.source.index,
            result.destination.index
        );

        reorderImages(newFiles);

        setTractMapContext((prev) => {
            return { ...prev, files: newFiles };
        });
    }

    return (
        <>
            {files.length > 0 && (
                <aside className="w-96 space-y-2">
                    <div className="flex flex-col" style={{ height: 500 }}>
                        <p className="text-gray-500 mb-2">
                            Drag cards in the order you'd like them to appear in
                            the&nbsp;auction.
                        </p>
                        <div className="overflow-y-scroll pr-4">
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="list">
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            <FileList
                                                files={files}
                                                editTract={(file) =>
                                                    setTractMapContext(
                                                        (prev) => {
                                                            const colorizedFile = prev.colorizedFiles.find(
                                                                (img) =>
                                                                    img.id ===
                                                                    file.id
                                                            );
                                                            return {
                                                                ...prev,
                                                                currentMapFile: file,
                                                                tractMapShowing: true,
                                                                currentColorizedFile: colorizedFile
                                                                    ? colorizedFile
                                                                    : null,
                                                            };
                                                        }
                                                    )
                                                }
                                            />
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                </aside>
            )}
        </>
    );
}

const FileList = React.memo(function FileList({ files, editTract }) {
    if (files.length === 0) {
        return <p>No Files!</p>;
    }
    return files.map((file, index) => (
        <ImageCard
            file={file}
            index={index}
            key={file.id}
            editTract={(file) => editTract(file)}
        />
    ));
});

const ImageCard = ({ file, index, editTract }) => {
    const { deleteTractImage } = useContext(TractMapContext);
    return (
        <Draggable draggableId={file.id} index={index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="border border-gray-200 bg-gray-100 rounded w-full flex px-3 py-1 my-2"
                >
                    <div className="max-w-4 h-14 overflow-hidden">
                        <img
                            src={file.preview}
                            className="block w-auto h-14"
                            alt=""
                        />
                    </div>
                    <div className="ml-2">
                        <p
                            className="overflow-ellipsis overflow-hidden whitespace-nowrap"
                            style={{ maxWidth: 272 }}
                        >
                            {file.name}
                        </p>
                        <Button
                            size="xs"
                            className="inline"
                            onClick={() => editTract(file)}
                        >
                            Edit Tract Data
                        </Button>
                    </div>
                    <button
                        className="ml-auto"
                        onClick={() => deleteTractImage(file.id, file.preview)}
                    >
                        <TrashIcon className="w-6 h-6 text-gray-700 hover:text-red-700" />
                    </button>
                </div>
            )}
        </Draggable>
    );
};
