import React from "react";

import Icon from "./Icon";

export function InputWithIcon({
    icon,
    htmlType = "text",
    className,
    error = false,
    rightContent = null,
    ...props
}) {
    return (
        <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Icon type={icon} width="20" color="text-gray-400" />
            </div>
            <input
                type={htmlType}
                className={`text-brand-gray-600  placeholder-opacity-50 text-base h-10 px-3 py-2 pl-10 border ${
                    error ? "border-red-700" : "border-brand-gray-300"
                } rounded ${className}`}
                {...props}
            />
            {rightContent && (
                <div className="absolute inset-y-0 right-4 pr-5 flex items-center pointer-events-none">
                    {rightContent}
                </div>
            )}
        </div>
    );
}
