import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

export default function PrivateRoute({ children, ...props }) {
    const authContext = useContext(AuthContext);
    const loginUrl = "/login";
    return (
        <Route
            {...props}
            render={() => {
                return authContext.isLoggedIn ? (
                    children
                ) : (
                    <Redirect to={loginUrl} />
                );
            }}
        />
    );
}
