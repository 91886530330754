const getCurrentCombinations = (tractData, bidData) => {
    // Parse out the individual tracts and store the data
    const individualTractArray = tractData.map((tract) =>
        tract.tract_tract_number.toString()
    );
    // Get unique combinations from bids
    const uniqueCombinations = getUniqueCombinations(
        bidData,
        individualTractArray
    );
    // Get tract data
    const tractInfo = getTractInfo(uniqueCombinations, tractData);
    // Get the highest bid for each combination
    const bidInfo = getBidInfo(tractInfo, bidData);

    return bidInfo;
};

const getUniqueCombinations = (bidData, tractArray) => {
    bidData.forEach((bid) => tractArray.push(bid.bid_name));
    tractArray = new Set(tractArray);
    return [...tractArray];
};

const getTractInfo = (combinations, tractData) => {
    const tractInfo = [];
    combinations.forEach((combination) => {
        const tractArray = combination.split("+");
        let acres = 0;
        tractArray.forEach((tract) => {
            const tractObj = tractData.find(
                (obj) => obj.tract_tract_number.toString() === tract
            );
            if (tractObj) {
                acres += parseFloat(tractObj.tract_acres);
            }
        });
        tractInfo.push({ tractName: combination, tractArray, acres });
    });
    return tractInfo;
};

const getBidInfo = (tracts, bidInfo) => {
    const accObj = {};
    bidInfo.forEach((bid) => {
        if (bid.bid_approved === 1) {
            if (accObj.hasOwnProperty(bid.bid_name)) {
                accObj[bid.bid_name].highBid =
                    bid.bid_amount > accObj[bid.bid_name].highBid
                        ? bid.bid_amount
                        : accObj[bid.bid_name].highBid;
                accObj[bid.bid_name].highBidUser =
                    bid.bid_amount > accObj[bid.bid_name].highBid
                        ? bid.bidder?.bidder_number
                        : accObj[bid.bid_name].highBidUser;
                accObj[bid.bid_name].highBidUserId =
                    bid.bid_amount > accObj[bid.bid_name].highBid
                        ? bid.bidder?.bidder_id
                        : accObj[bid.bid_name].highBidUserId;
                accObj[bid.bid_name].highBidID =
                    bid.bid_amount > accObj[bid.bid_name].highBid
                        ? bid.bid_id
                        : accObj[bid.bid_name].highBidID;
            } else {
                accObj[bid.bid_name] = {};
                accObj[bid.bid_name].highBid = bid.bid_amount;
                accObj[bid.bid_name].highBidID = bid.bid_id;
                accObj[bid.bid_name].highBidUser = bid.bidder?.bidder_number;
                accObj[bid.bid_name].highBidUserId = bid.bidder?.bidder_id;
                accObj[bid.bid_name].alias = bid.bid_alias;
            }
        }
    });
    const tractsWithHighBid = tracts.map((tract) => {
        return {
            ...tract,
            highBid: accObj.hasOwnProperty(tract.tractName)
                ? accObj[tract.tractName].highBid
                : null,
            highBidID: accObj.hasOwnProperty(tract.tractName)
                ? accObj[tract.tractName].highBidID
                : null,
            highBidUser: accObj.hasOwnProperty(tract.tractName)
                ? accObj[tract.tractName].highBidUser
                : null,
            highBidUserId: accObj.hasOwnProperty(tract.tractName)
                ? accObj[tract.tractName].highBidUserId
                : null,
            acres: accObj.hasOwnProperty(tract.tractName) ? tract.acres : null,
            ppa: accObj.hasOwnProperty(tract.tractName)
                ? accObj[tract.tractName].highBid / tract.acres
                : null,
            alias: accObj.hasOwnProperty(tract.tractName)
                ? accObj[tract.tractName].alias
                : null,
        };
    });
    return tractsWithHighBid;
};

export default getCurrentCombinations;
