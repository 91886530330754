import { Redirect, Switch, useRouteMatch } from "react-router-dom";

import AuctionsTabNav from "../components/UserAuctionsTabNav";
import Upcoming from "./Upcoming";
import Past from "./Past";
import PrivateRoute from "../../components/PrivateRoute";
import ErrorBoundary from "../../components/ErrorBoundary";
import Favorites from "./Favorites";

export default function Auctions() {
    const { url } = useRouteMatch();

    return (
        <ErrorBoundary>
            <div className="mb-8">
                <AuctionsTabNav base={`/`} />
            </div>
            <Switch>
                <PrivateRoute exact path={`${url}/upcoming`}>
                    <Upcoming />
                </PrivateRoute>
                <PrivateRoute exact path={`${url}/past`}>
                    <Past />
                </PrivateRoute>
                <PrivateRoute exact path={`${url}/favorites`}>
                    <Favorites />
                </PrivateRoute>
                <Redirect from="/" to={`${url}/upcoming`} />
            </Switch>
        </ErrorBoundary>
    );
}
