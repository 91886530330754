import { Dialog, Transition } from "@headlessui/react";
import { useEffect, useState, useContext, Fragment } from "react";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/solid";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import createUUID from "../../utilities/createUUID";
import formatUSD from "../../utilities/formatUSD";
import {
    defaultIncrements,
    sortSuggestions,
} from "../../utilities/suggestedBid";
import { AdminAuctionContext } from "../context/AdminAuctionContext";

export default function BidIncrementModal({ onClose, onSubmit, open }) {
    const { currentAuctionBidSuggestions } = useContext(AdminAuctionContext);
    const [bidIncrements, setBidIncrements] = useState([]);
    const [isEditing, setIsEditing] = useState([]);

    useEffect(() => {
        if (!currentAuctionBidSuggestions)
            return setBidIncrements(sortSuggestions(defaultIncrements));
        setBidIncrements(sortSuggestions(currentAuctionBidSuggestions));
    }, [currentAuctionBidSuggestions]);

    const handleSave = (values) => {
        const removePrevValue = bidIncrements.filter(
            (bidIncrement) => bidIncrement.id !== values.id
        );
        const newBidIncrements = [...removePrevValue, values];
        setBidIncrements(sortSuggestions(newBidIncrements));
        setIsEditing(isEditing.filter((id) => id !== values.id));
    };

    return (
        <>
            <Modal isOpen={open}>
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                Edit Bid Increments
                            </Dialog.Title>
                            <hr className="my-4" />
                            <div className="mt-2">
                                {bidIncrements.map((obj) =>
                                    isEditing.includes(obj.id) ? (
                                        <Edit
                                            amount={obj.amount}
                                            suggest={obj.suggest}
                                            key={obj.id}
                                            id={obj.id}
                                            onCancel={(id) =>
                                                setIsEditing((p) =>
                                                    p.filter((i) => i !== id)
                                                )
                                            }
                                            onSave={(values) =>
                                                handleSave(values)
                                            }
                                        />
                                    ) : (
                                        <View
                                            amount={obj.amount}
                                            suggest={obj.suggest}
                                            key={obj.id}
                                            id={obj.id}
                                            onEdit={(id) =>
                                                setIsEditing((p) => [...p, id])
                                            }
                                            onDelete={(id) =>
                                                setBidIncrements((p) =>
                                                    sortSuggestions(
                                                        p.filter(
                                                            (i) => i.id !== id
                                                        )
                                                    )
                                                )
                                            }
                                        />
                                    )
                                )}
                            </div>
                            <button className="my-6 text-cta flex items-center justify-center space-x-2">
                                <PlusIcon className="h-5 w-5" />
                                <span
                                    onClick={() => {
                                        const uuid = createUUID();
                                        setBidIncrements((p) => [
                                            ...p,
                                            {
                                                id: uuid,
                                                amount: "",
                                                suggest: "",
                                            },
                                        ]);
                                        setIsEditing((p) => [...p, uuid]);
                                    }}
                                    className="font-medium"
                                >
                                    Add New
                                </span>
                            </button>
                            <div className="mt-4 flex items-center justify-center space-x-4">
                                <Button
                                    onClick={() => {
                                        // filter out any empty values
                                        const filteredBidIncrements =
                                            bidIncrements.filter((value) => {
                                                return (
                                                    typeof value.amount ===
                                                        "number" &&
                                                    typeof value.suggest ===
                                                        "number"
                                                );
                                            });
                                        onSubmit(filteredBidIncrements);
                                    }}
                                >
                                    Save
                                </Button>
                                <button
                                    onClick={() => onClose()}
                                    className="text-sm font-bold text-gray-500 hover:text-gray-300"
                                    autoFocus={false}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Modal>
        </>
    );
}

const View = ({ amount, suggest, id, onEdit, onDelete }) => (
    <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-8 bg-gray-100 p-2 rounded">
        <div className="sm:col-span-3">
            <label className="block text-sm font-medium text-gray-700">
                Current Bid Amount
            </label>
            <div className="mt-1">
                <p className="w-full font-bold py-2">{formatUSD(amount)}</p>
            </div>
        </div>
        <div className="sm:col-span-3">
            <label className="block text-sm font-medium text-gray-700">
                Suggested Bid Amount
            </label>
            <div className="mt-1">
                <p className="w-full font-bold py-2">{formatUSD(suggest)}</p>
            </div>
        </div>
        <div className="sm:col-span-2 flex space-x-2 justify-end">
            <button
                onClick={() => onEdit(id)}
                className="text-indigo-600 font-medium"
            >
                <PencilIcon className="h-5 w-5" />
            </button>
            {amount !== 0 && (
                <button
                    onClick={() => onDelete(id)}
                    className="text-red-600 font-medium"
                >
                    <TrashIcon className="h-5 w-5" />
                </button>
            )}
        </div>
    </div>
);

const Edit = ({ amount, suggest, id, onCancel, onSave }) => {
    const [editValues, setEditValues] = useState({
        id,
        amount,
        suggest,
    });

    const handleChange = (e) => {
        setEditValues({
            ...editValues,
            [e.target.name]: parseFloat(e.target.value),
        });
    };
    return (
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-8">
            <div className="sm:col-span-3">
                <label className="block text-sm font-medium text-gray-700">
                    Current Bid Amount
                </label>
                <div className="mt-1">
                    {amount !== 0 && (
                        <input
                            name="amount"
                            type="number"
                            value={editValues.amount}
                            onChange={handleChange}
                            className="rounded border-solid border border-gray-300 w-full px-4 py-2 shadow-sm"
                        />
                    )}
                    {amount === 0 && (
                        <>
                            <p className="w-full font-bold pt-2">{amount}</p>
                            <p className="w-full text-xxs">
                                This is the default amount.
                            </p>
                        </>
                    )}
                </div>
            </div>
            <div className="sm:col-span-3">
                <label className="block text-sm font-medium text-gray-700">
                    Suggested Bid Amount
                </label>
                <div className="mt-1">
                    <input
                        name="suggest"
                        onChange={handleChange}
                        type="number"
                        value={editValues.suggest}
                        className="rounded border-solid border border-gray-300 w-full px-4 py-2 shadow-sm"
                    />
                </div>
            </div>
            <div className="sm:col-span-2 flex space-x-3 justify-end">
                {editValues.amount !== "" && editValues.suggest !== "" && (
                    <button
                        onClick={() => onSave(editValues)}
                        className="text-indigo-600 font-medium"
                    >
                        Save
                    </button>
                )}
                <button
                    onClick={() => onCancel(id)}
                    className="text-red-600 font-medium"
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};
