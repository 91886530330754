import { isBefore } from "date-fns";
import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { AuctionContext } from "../context/AuctionContext";

export default function PlaceBidErrorBoundry({ children }) {
    const { loggedInUser } = useContext(AuthContext);
    const {
        currentAuctionBidOpen,
        currentAuctionBidClose,
        currentAuctionId,
        onlineAuctionClosed,
    } = useContext(AuctionContext);
    if (!loggedInUser.auctions.includes(parseInt(currentAuctionId))) {
        return (
            <ErrorState
                title="You are not allowed to bid on this auction"
                body="Please contact Schrader Auctions to be able to bid."
            />
        );
    }

    if (currentAuctionBidOpen && isBefore(new Date(), currentAuctionBidOpen)) {
        return (
            <ErrorState
                title="Bidding is not open yet"
                body="Please wait until bidding opens."
            />
        );
    }

    if (currentAuctionBidClose && onlineAuctionClosed) {
        return (
            <ErrorState
                title="Bidding is closed"
                body="Bidding is closed for this auction."
            />
        );
    }

    return <>{children}</>;
}

const ErrorState = ({ title, body }) => {
    return (
        <div className="relative flex flex-col items-center justify-center w-full h-auto border-2 border-gray-300 border-dashed rounded-lg p-12 text-center bg-gray-50">
            <h4 className="mt-2 block font-medium text-gray-900">{title}</h4>
            <p className="mt-2 block text-sm text-gray-600">{body}</p>
        </div>
    );
};
