import { useContext, useEffect, useState } from "react";
import PlaceBidModal from "./PlaceBidModal";
import Button from "../../components/Button";
import { AuctionContext } from "../context/AuctionContext";
import formatUSD from "../../utilities/formatUSD";
import { InputWithIcon } from "../../components/InputWithIcon";
import abbrNum from "../helpers/abbrNum";
import calculateSuggestedBid from "../helpers/calculateSuggestedBid";
import { usePlaceBid } from "../../services/usePlaceBid";
import addCommasToNumber from "../helpers/addCommasToNumber";

export default function PlaceBidSingleTract() {
    const {
        currentAuctionId,
        currentAuctionHighBids,
        currentAuctionBidSuggestions,
        bidder,
    } = useContext(AuctionContext);
    const [bidAmount, setBidAmount] = useState("");
    const [bidPlaceholder, setBidPlaceholder] = useState("Enter a bid");
    const [modalOpen, setModalOpen] = useState(false);
    const [suggestedBid, setSuggestedBid] = useState(1000);
    const [suggestedIncrement, setSuggestedIncrement] = useState(1000);
    const [incButtonText, setIncButtonText] = useState("1k");
    const [placeBidBtnDisabled, setPlaceBidDisabled] = useState(true);
    const [bidErrorText, setBidErrorText] = useState("");
    const placeBid = usePlaceBid();

    // Toggle the place bid button if bid doesn't exceed current
    useEffect(() => {
        if (!bidAmount) {
            setPlaceBidDisabled(true);
            setBidErrorText("");
            return;
        }

        const newBid = parseFloat(bidAmount) * 1000;

        let highBid = currentAuctionHighBids[0]?.bid_amount;

        if (highBid) {
            if (
                parseFloat(highBid) + parseFloat(suggestedIncrement) >
                parseFloat(newBid)
            ) {
                setPlaceBidDisabled(true);
                return setBidErrorText(
                    `Your bid of ${formatUSD(
                        newBid
                    )} must meet or exceed ${formatUSD(
                        parseFloat(highBid) + parseFloat(suggestedIncrement)
                    )}`
                );
            }
        }

        setBidErrorText("");
        return setPlaceBidDisabled(false);
    }, [bidAmount, currentAuctionHighBids, suggestedIncrement]);

    useEffect(() => {
        const { bid, increment } = calculateSuggestedBid(
            currentAuctionHighBids[0]?.bid_amount,
            currentAuctionBidSuggestions
        );
        setSuggestedBid(bid);
        setSuggestedIncrement(increment);
        setIncButtonText(abbrNum(increment, 0));
        setBidPlaceholder(`Minimum: ${bid / 1000}`);
    }, [currentAuctionHighBids, currentAuctionBidSuggestions]);

    const handlePressEnterKey = () => {
        if (placeBidBtnDisabled) return;
        setModalOpen(true);
    };

    return (
        <>
            <div className="space-y-2 justify-between mb-1.5">
                <div>
                    <div className="flex items-center justify-between">
                        <div className="w-full md:w-11/12">
                            <InputWithIcon
                                placeholder={bidPlaceholder}
                                icon="dollar"
                                className="w-full h-12"
                                value={bidAmount}
                                onChange={(e) => setBidAmount(e.target.value)}
                                htmlType="number"
                                onWheel={(e) => {
                                    e.target.blur();
                                }}
                                error={bidErrorText !== ""}
                                onKeyDown={(e) => {
                                    if (e.key.toLowerCase() === "enter") {
                                        e.stopPropagation();
                                        handlePressEnterKey();
                                    }
                                }}
                                rightContent={
                                    <div className="w-full text-sm text-gray-400">
                                        {bidAmount > 0 ? (
                                            <span>
                                                x1000 ={" "}
                                                <span className="text-green-700 font-bold">
                                                    $
                                                    {addCommasToNumber(
                                                        bidAmount * 1000
                                                    )}
                                                </span>
                                            </span>
                                        ) : (
                                            "x1000"
                                        )}
                                    </div>
                                }
                            />
                        </div>
                        <div className="hidden sm:flex flex-col space-y-1 mt-1.5">
                            <Button
                                size="sm"
                                type="secondary"
                                className="ml-2 py-4"
                                onClick={() => {
                                    setBidAmount((prev) => {
                                        let num = parseFloat(prev);
                                        prev === ""
                                            ? (num = suggestedBid / 1000)
                                            : (num +=
                                                  suggestedIncrement / 1000);
                                        return num.toString();
                                    });
                                }}
                            >
                                +&nbsp;${incButtonText}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row items-center md:space-x-4">
                    <Button
                        className={`w-full md:w-auto h-10 ${
                            placeBidBtnDisabled ? "cursor-not-allowed" : ""
                        }`}
                        onClick={() => {
                            if (placeBidBtnDisabled) return;
                            setModalOpen(true);
                        }}
                        disabled={placeBidBtnDisabled}
                    >
                        PLACE BID
                    </Button>
                    {bidErrorText && (
                        <div className="text-red-700 text-xs md:text-sm h-4 mt-2 md:mt-0 text-center md:text-left">
                            {bidErrorText}
                        </div>
                    )}
                </div>
            </div>

            <PlaceBidModal
                open={modalOpen}
                bidder={bidder}
                singleTract={true}
                bid={{
                    bidAmount: bidAmount * 1000,
                    tractName: 1,
                    totalAcres: currentAuctionHighBids[0]?.total_acres,
                }}
                onConfirm={() => {
                    placeBid.mutate({
                        auction_id: currentAuctionId,
                        tracts: 1,
                        amount: bidAmount * 1000,
                        bidder_id: bidder.bidder_id,
                    });
                    setModalOpen(false);
                    setBidAmount("");
                }}
                onCancel={() => setModalOpen(false)}
            />
        </>
    );
}
