import { useContext } from "react";
import { ArrowSmUpIcon, HashtagIcon } from "@heroicons/react/solid";
import { Card } from "../../components/Card";
import formatUSD from "../../utilities/formatUSD";
import { AuctionContext } from "../context/AuctionContext";
import AuctionTimer from "./AuctionTimer";
import PlaceBidSingleTract from "./PlaceBidSingleTract";
import PlaceBidErrorBoundry from "./PlaceBidErrorBoundry";
import { AuthContext } from "../../contexts/AuthContext";

export default function OnlineAuctionDetails() {
    const {
        currentAuctionTitle,
        currentAuctionTagline,
        currentAuctionHighBids,
        currentAuctionBids,
    } = useContext(AuctionContext);
    const { loggedInUser } = useContext(AuthContext);

    return (
        <Card className="flex  flex-col justify-center">
            <h2 className="text-xl md:text-2xl font-bold capitalize text-brand-gray-700">
                {currentAuctionTitle.toLowerCase()}
            </h2>
            <p className="text-xs md:text-sm text-gray-400">
                {currentAuctionTagline}
            </p>
            <div className="flex flex-col divide-y pb-2 my-2 md:my-4 border-t">
                <span className="flex items-center text-gray-600 py-2">
                    <AuctionTimer />
                </span>
                <span className="flex items-center text-gray-600 py-2">
                    <ArrowSmUpIcon className="w-4 h-4 md:w-5 md:h-5 mr-1" />
                    <p className="flex items-center">
                        Current Bid:{" "}
                        <span className="font-bold text-green-700 ml-1 text-base md:text-lg">
                            {currentAuctionHighBids[0]?.bid_amount
                                ? formatUSD(
                                      currentAuctionHighBids[0]?.bid_amount
                                  )
                                : "No bids yet"}
                        </span>
                        {currentAuctionHighBids[0]?.user_id ===
                        loggedInUser.id ? (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 ml-2">
                                You're In
                            </span>
                        ) : (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 ml-2">
                                You're Out
                            </span>
                        )}
                    </p>
                </span>
                <span className="flex items-center text-gray-600 py-2">
                    <HashtagIcon className="w-4 h-4 md:w-5 md:h-5 mr-1" />
                    <p className="text-sm md:text-base">
                        Bids:{" "}
                        <span className="font-bold text-gray-900 ml-1">
                            {currentAuctionBids && currentAuctionBids.bids
                                ? currentAuctionBids.bids.length
                                : 0}
                        </span>
                    </p>
                </span>
            </div>
            <PlaceBidErrorBoundry>
                <div className="border px-2 md:px-4 py-4 bg-gray-50 shadow-sm bg-opacity-75">
                    <h4 className="text-gray-700 font-medium text-sm md:text-base">
                        PLACE BID
                    </h4>
                    <PlaceBidSingleTract />
                </div>
            </PlaceBidErrorBoundry>
        </Card>
    );
}
