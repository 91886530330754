import { useEffect, useState } from "react";
import {
    ActiveAuctions,
    DestroyBiddingEngine,
} from "../../services/AuctionService";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Spinner from "../../components/Spinner";
import { useToasts } from "react-toast-notifications";

export default function Active() {
    const [activeAuctions, setActiveAuctions] = useState([]);
    const [biddingEngines, setBiddingEngines] = useState([]);
    const [rogueBiddingEngines, setRogueBiddingEngines] = useState([]);
    const [loading, setLoading] = useState(true);
    const [terminationInProgress, setTerminationInProgress] = useState(false);
    const { addToast } = useToasts();

    useEffect(() => {
        getActiveAuctions();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getActiveAuctions = async () => {
        let auctions = await ActiveAuctions();
        setActiveAuctions(auctions.auctions);
        setBiddingEngines(auctions.bidding_engines);
        setLoading(false);
    };

    useEffect(() => {
        if (!activeAuctions || !biddingEngines) {
            return;
        }

        setRogueBiddingEngines(
            biddingEngines.filter(
                (engine) =>
                    !activeAuctions.some(
                        (auction) =>
                            parseInt(auction.auction_bidding_engine_port) ===
                            parseInt(engine.port)
                    )
            )
        );
    }, [biddingEngines, activeAuctions]);

    const destroyBiddingEngine = async (port) => {
        setTerminationInProgress(true);
        const resp = await DestroyBiddingEngine(port);
        addToast(
            <div>
                <h2>Killed bidding engine on port {resp.data}</h2>
            </div>,
            {
                appearance: "success",
                autoDismiss: true,
                id: "killed-bidding-engine",
            }
        );
        await getActiveAuctions();
        setTerminationInProgress(false);
    };

    return (
        <div className="pt-8">
            <div className="rounded-xl border border-gray-300 max-w-2xl m-auto">
                <h1 className="font-bold text-xl text-center p-5 border-solid border-b border-gray-300 bg-gray-100 rounded-t-xl">
                    Active Auctions
                </h1>
                <div className="p-5">
                    {activeAuctions.length < 1 && !loading && (
                        <div className="italic text-center">
                            No active auctions found
                        </div>
                    )}
                    {activeAuctions.length > 0 && (
                        <div className="flex py-3">
                            <div className="font-bold">Auction Name</div>
                            <div className="flex-1 text-right font-bold">
                                Active Since
                            </div>
                        </div>
                    )}

                    {loading && <Spinner className="mx-auto my-8" />}

                    {activeAuctions.map((item, i) => (
                        <div
                            key={i}
                            className="flex py-3 border-t border-gray-300 border-solid"
                        >
                            <Link
                                to={`/admin/auction-interface/${item.auction_id}/input`}
                                className="text-cta font-bold"
                            >
                                <div className="flex space-x-2 items-center">
                                    <div>{item.auction_name}</div>

                                    {item.auction_bidding_engine_host && (
                                        <img
                                            width="18"
                                            style={{ marginTop: "-3px" }}
                                            alt="Multi-tract"
                                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAjElEQVRIid2VSwqAMAxER/FyxUu59Cwey/YeujEoWuxMNkEHQqGfeaQpKfBHjQAKgE2MDCAxgOwwt1gZgG1WVT3XO4wkfR8wvKx56vBQaAad6FXNODQDVdWMQ58p0xoWnLcwAZgZKNt37uY234SovehqTkEUQM28Cfl+Ny3HyBZbVoL2q5ncQFYxNdgBTShqcIc71l4AAAAASUVORK5CYII="
                                        />
                                    )}
                                </div>
                            </Link>
                            <div className="flex-1 text-right">
                                {format(
                                    Date.parse(item.auction_in_progress),
                                    "M/dd/yy h:mm a"
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {rogueBiddingEngines.length > 0 && (
                <div className="rounded-xl border border-gray-300 mt-8 max-w-2xl m-auto">
                    <h1 className="font-bold text-xl text-center p-5 border-solid border-b border-gray-300 bg-gray-100 rounded-t-xl">
                        Rogue Bidding Engines
                    </h1>
                    <div className="p-5">
                        <div className="flex py-3">
                            <div className="font-bold">Bidding Engine Port</div>
                            <div className="flex-1 text-right font-bold">
                                Details
                            </div>
                        </div>

                        {rogueBiddingEngines.map((item, i) => (
                            <div
                                key={i}
                                className="flex py-3 border-t border-gray-300 border-solid"
                            >
                                <div className="flex space-x-2 items-center">
                                    <div className="flex space-x-2">
                                        <div>{item.port}</div>
                                        {!terminationInProgress ? (
                                            <div
                                                className="cursor-pointer font-bold text-cta"
                                                onClick={() =>
                                                    destroyBiddingEngine(
                                                        item.port
                                                    )
                                                }
                                            >
                                                Destroy
                                            </div>
                                        ) : (
                                            <Spinner width="20" />
                                        )}
                                    </div>
                                </div>

                                <div className="flex-1 text-right">
                                    {item.message}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}
