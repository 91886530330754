import { format } from "date-fns";
import SchraderLogoPrimary from "../../images/SchraderLogoPrimary.svg";

export default function PrintHeader({ title, auction }) {
    return (
        <div className="overflow-hidden h-0 printHeader flex items-start justify-between">
            <img src={SchraderLogoPrimary} alt="Logo" className="block w-64" />
            <div className="text-right">
                <h2 className="font-bold text-2xl text-gray-700 mt-2 uppercase">
                    {auction}
                </h2>
                <p className="text-xl font-medium text-gray-500">{title}</p>
                <p className="text-gray-500">
                    {format(new Date(), "MMMM dd, yyyy h:mm a")}
                </p>
            </div>
        </div>
    );
}
