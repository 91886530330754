import { useContext, useState } from "react";
import AuthLayout from "./components/AuthLayout";
import { AuthContext } from "../contexts/AuthContext";
import { Link, Redirect } from "react-router-dom";
import { sendForgotPasswordEmail } from "../services/SessionService";
import Button from "../components/Button";
import Input from "../components/Input";
import Icon from "../components/Icon";
import Alert from "../components/Alert";

export default function ForgotPassword() {
    const authContext = useContext(AuthContext);
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let email = document.getElementById("email").value;
            let params = {
                email: email,
            };
            let res = await sendForgotPasswordEmail(params);
            if (res.success) {
                setMessage(res.message);
                setError("");
            }
        } catch (error) {
            setError(error.response.data.message);
        }
    };

    if (authContext.isLoggedIn) {
        return <Redirect to={"/"} />;
    }

    return (
        <AuthLayout title="Reset Password">
            {message !== "" && (
                <Alert type="success" className="mb-8 mx-auto max-w-sm">
                    {message}
                </Alert>
            )}
            {error && <Alert className="mb-8 mx-auto max-w-sm">{error}</Alert>}

            <form className="w-full" onSubmit={(e) => handleSubmit(e)}>
                <div className="max-w-xs mx-auto">
                    <div className="flex mb-10">
                        <Icon type="user" width="50px" className="mr-2" />
                        <Input
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            borderless
                            htmlType="email"
                            placeholder="Email"
                            id="email"
                            required
                        />
                    </div>
                    <Button
                        className="block mt-12 mx-auto font-secondary"
                        size="lg"
                        type="light"
                        htmlType="submit"
                        rounded
                    >
                        Submit
                    </Button>
                    <Link
                        className="block mt-5 text-bold text-center text-sm text-white hover:text-gray-300 focus:outline-none"
                        to="/login"
                    >
                        Back to Login
                    </Link>
                </div>
            </form>
        </AuthLayout>
    );
}
