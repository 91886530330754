import { useContext } from "react";
import { useQueryClient } from "react-query";
import { AdminAuctionContext } from "../../../context/AdminAuctionContext";
import useChatQueries from "./useChatQueries";
import usePlayChatSound from "./usePlayChatSound";

const useNewMessage = (state) => {
    const queryClient = useQueryClient();
    const { currentAuctionId } = useContext(AdminAuctionContext);
    const { markAsRead, markAsUnread } = useChatQueries(state);
    const { playChatSound } = usePlayChatSound();

    const handleNewMessage = (data) => {
        const currentContactID = state.currentSelectedBidder.bidder_contact_id;
        const msgContactID = data.message.bidder_contact_id || "ADMIN";
        const msgBidderID = data.message.message_bidder_id || "ADMIN";

        console.log(data.message);

        // update the msg count
        const newUserData = queryClient.getQueryData("users").map((user) => {
            if (user.bidder_id === msgBidderID) {
                return {
                    ...user,
                    messages_count: user.messages_count + 1,
                };
            }
            return user;
        });
        queryClient.setQueryData("users", newUserData);

        // If the chat history is already cached, update it
        const currentMessages = queryClient.getQueryData([
            "messages",
            currentAuctionId + "-" + msgContactID,
        ]);

        if (currentMessages) {
            queryClient.setQueryData(
                ["messages", currentAuctionId + "-" + msgContactID],
                [...currentMessages, data.message]
            );
        }

        const currentMessagesAll = queryClient.getQueryData([
            "chatMessagesAll",
            currentAuctionId,
        ]);

        if (currentMessagesAll) {
            queryClient.refetchQueries(["chatMessagesAll", currentAuctionId]);
        }

        // None of the following steps are necessary if the message is from an admin
        if (data.message.message_from_admin) return;

        // if the user is the selected user call the server to mark the message as read
        if (msgContactID === currentContactID) {
            console.log("marking as read");
            markAsRead({
                bidder_id: msgBidderID,
                auction_id: currentAuctionId,
            });
        } else {
            console.log("marking as unread");
            markAsUnread({
                bidder_id: msgBidderID,
                auction_id: currentAuctionId,
            });
        }
        playChatSound();
    };
    return { handleNewMessage };
};

export default useNewMessage;
