import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./auth/Login";
import Logout from "./auth/Logout";
import ResetPassword from "./auth/ResetPassword";
import ForgotPassword from "./auth/ForgotPassword";
import Admin from "./admin/Admin";
import UserHome from "./user/UserHome";
import { QueryClient, QueryClientProvider } from "react-query";
import PrivateRoute from "./components/PrivateRoute";
import AuthContextProvider from "./contexts/AuthContext";
import { PusherContextProvider } from "./contexts/PusherContext";
import GlobalContextProvider from "./contexts/GlobalContext";
import { DumpProvider } from "./utilities/DumpProvider";
import { ToastProvider } from "react-toast-notifications";
import { ReactQueryDevtools } from "react-query/devtools";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import GlobalFallback from "./components/GlobalFallback";
import { useEffect } from "react";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN || null, // We only want to use this in development and might want to add it to production later
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

function App() {
    // Create a client
    const queryClient = new QueryClient();

    useEffect(() => {
        const title = process.env.REACT_APP_APP_NAME;
        document.title = title || "Schrader Auction";
    }, []);

    return (
        <Sentry.ErrorBoundary fallback={<GlobalFallback />} showDialog={false}>
            <AuthContextProvider>
                <PusherContextProvider>
                    <QueryClientProvider client={queryClient}>
                        <ReactQueryDevtools initialIsOpen={false} />
                        <ToastProvider>
                            <DumpProvider />
                            <Router>
                                <Switch>
                                    <Route path="/login">
                                        <Login />
                                    </Route>
                                    <Route path="/logout">
                                        <Logout />
                                    </Route>
                                    <Route path="/forgot-password">
                                        <ForgotPassword />
                                    </Route>
                                    <Route path="/password/reset">
                                        <ResetPassword />
                                    </Route>
                                    <PrivateRoute path="/admin">
                                        <GlobalContextProvider>
                                            <Admin />
                                        </GlobalContextProvider>
                                    </PrivateRoute>
                                    <PrivateRoute path="/">
                                        <GlobalContextProvider>
                                            <UserHome />
                                        </GlobalContextProvider>
                                    </PrivateRoute>
                                </Switch>
                            </Router>
                        </ToastProvider>
                    </QueryClientProvider>
                </PusherContextProvider>
            </AuthContextProvider>
        </Sentry.ErrorBoundary>
    );
}

export default App;
