function TableRoot({ children, className, ...props }) {
    return (
        <div className={`overflow-auto ${className}`} {...props}>
            <table className="min-w-full h-auto divide-y divide-brand-gray-300 font-secondary">
                {children}
            </table>
        </div>
    );
}

function Header({ children, className, ...props }) {
    return (
        <thead className={className} {...props}>
            <tr>{children}</tr>
        </thead>
    );
}

function HeaderCell({ children, className, ...props }) {
    return (
        <th
            scope="col"
            className={`first:pl-2 last:pr-1 px-6 pb-1.5 pt-2.5 text-xs font-bold text-brand-gray-500 tracking-wider sticky top-0 bg-white ${className}`}
            {...props}
        >
            {children}
        </th>
    );
}

function Body({ children, className, ...props }) {
    return (
        <tbody
            className={`bg-white divide-y divide-gray-200 ${className}`}
            {...props}
        >
            {children}
        </tbody>
    );
}

function Row({ height = "h-10", children, className, ...props }) {
    return (
        <tr className={`${height} ${className}`} {...props}>
            {children}
        </tr>
    );
}

function Cell({ children, className, colSpan = 1 }) {
    return (
        <td
            colSpan={colSpan}
            className={`first:pl-2 last:pr-2 px-6 whitespace-nowrap text-xs font-medium text-brand-gray-700 ${
                className !== undefined ? className : ""
            }`}
        >
            {children}
        </td>
    );
}

export const Table = Object.assign(TableRoot, {
    Header,
    HeaderCell,
    Body,
    Row,
    Cell,
});
