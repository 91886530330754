import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import apiClient from "../utilities/ApiClient";

const randomJitter = Math.floor(Math.random() * 1000) + 4000;

export default function useAuctionStartEndTime({
    auctionID,
    auctionPendingClose,
    onAuctionClosed,
}) {
    const [auctionStartEndTime, setAuctionStartEndTime] = useState({
        auction_start: null,
        auction_end: null,
    });
    const {
        data: startEndTime,
        isLoading: startEndTimeLoading,
        error: startEndTimeError,
    } = useQuery(
        ["start-end-time", auctionID],
        () =>
            apiClient
                .get(`/auctions/${auctionID}/start-end-time`)
                .then((res) => {
                    if (res.data.auction_ended && res.data.online_only) {
                        onAuctionClosed();
                    }
                    return {
                        auction_start: res.data.auction_start * 1000,
                        auction_end: res.data.auction_end * 1000,
                    };
                }),
        {
            refetchInterval: auctionPendingClose ? randomJitter : null,
            refetchOnWindowFocus:
                auctionStartEndTime.start && auctionStartEndTime.end,
        }
    );

    useEffect(() => {
        if (startEndTimeLoading || startEndTimeError || !startEndTime) return;
        setAuctionStartEndTime(startEndTime);
    }, [startEndTimeLoading, startEndTimeError, startEndTime]);

    return {
        currentAuctionBidOpen: auctionStartEndTime.auction_start,
        currentAuctionBidClose: auctionStartEndTime.auction_end,
    };
}
