import { useContext, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import { AuthContext } from "../../../../contexts/AuthContext";
import apiClient from "../../../../utilities/ApiClient";
import { AdminAuctionContext } from "../../../context/AdminAuctionContext";

const useChatQueries = (state) => {
    const { loggedInUser } = useContext(AuthContext);
    const { currentAuctionId } = useContext(AdminAuctionContext);
    const queryClient = useQueryClient();
    const bidderID = useMemo(() => {
        if (state.currentSelectedBidder) {
            return state.currentSelectedBidder === "ADMIN"
                ? "ADMIN"
                : state.currentSelectedBidder.bidder_id;
        }
    }, [state.currentSelectedBidder]);
    const bidderContactID = useMemo(() => {
        if (state.currentSelectedBidder) {
            return state.currentSelectedBidder === "ADMIN"
                ? "ADMIN"
                : state.currentSelectedBidder.bidder_contact_id;
        }
    }, [state.currentSelectedBidder]);

    const useChatMessagesAll = useQuery(
        ["chatMessagesAll", currentAuctionId],
        () =>
            apiClient.get(`chat/${currentAuctionId}/all`).then((data) => {
                return data.data.messages.sort((a, b) => {
                    return (
                        new Date(a.message_created_on) -
                        new Date(b.message_created_on)
                    );
                });
            }),
        {
            enabled: !!currentAuctionId,
        }
    );

    const useCurrentUserMessages = () =>
        useQuery(
            ["messages", currentAuctionId + "-" + bidderContactID],
            () => {
                if (bidderID === "ADMIN") {
                    return apiClient
                        .get(`chat/auction-admin-messages`, {
                            params: { auction_id: currentAuctionId },
                        })
                        .then((res) => res.data.messages);
                } else {
                    apiClient.put("chat/mark-as-read", {
                        bidder_id: bidderID,
                        auction_id: currentAuctionId,
                    });
                    return apiClient
                        .get("chat/conversation-messages", {
                            params: {
                                bidder_id: bidderID,
                                auction_id: currentAuctionId,
                            },
                        })
                        .then((res) => res.data.messages);
                }
            },
            {
                enabled: !!state.currentSelectedBidder,
                refetchOnWindowFocus: false,
            }
        );

    const useAuctionUsers = () =>
        useQuery(
            "users",
            () =>
                apiClient
                    .get("chat/auction-conversations", {
                        params: {
                            exclude_id: loggedInUser.id, // don't include logged in user in results
                            auction_id: currentAuctionId,
                        },
                    })
                    .then((res) => res.data.users),
            { enabled: !!currentAuctionId, refetchOnWindowFocus: false }
        );

    const markAsRead = (values) => {
        return apiClient
            .put("chat/mark-as-read", values)
            .then(() => {
                const newUserData = queryClient
                    .getQueryData("users")
                    .map((user) => {
                        if (user.bidder_id === values.bidder_id) {
                            return {
                                ...user,
                                message_read: 1,
                            };
                        }
                        return user;
                    });
                queryClient.setQueryData("users", newUserData);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const markAsUnread = (values) => {
        const newUserData = queryClient.getQueryData("users").map((user) => {
            if (user.bidder_id === values.bidder_id) {
                return {
                    ...user,
                    message_read: 0,
                };
            }
            return user;
        });
        return queryClient.setQueryData("users", newUserData);
    };

    return {
        useCurrentUserMessages,
        useChatMessagesAll,
        useAuctionUsers,
        markAsRead,
        markAsUnread,
    };
};

export default useChatQueries;
