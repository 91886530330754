import "react-datepicker/dist/react-datepicker.css";
import { useContext, useState } from "react";
import { Redirect } from "react-router";
import Button from "../../components/Button";
import { Table } from "../../components/Table";
import { format } from "date-fns";
import {
    ArrowUpIcon,
    ExternalLinkIcon,
    HeartIcon,
} from "@heroicons/react/solid";
import {
    useAddFavoriteAuction,
    useGetFavoriteAuctions,
    useRemoveFavoriteAuction,
} from "../../services/useFavoriteAuctions";
import { AuthContext } from "../../contexts/AuthContext";
import { GlobalContext } from "../../contexts/GlobalContext";
import auctionPlaceholder from "../../images/auction-placeholder.jpg";

// possible TODO: reset scrollbar to top of page on clicking next or previous
export default function AuctionList({ auctions }) {
    const [auctionSelected, setAuctionSelected] = useState(null);
    const globalContext = useContext(GlobalContext);
    const { loggedInUser } = useContext(AuthContext);
    const {
        data: favoritesData,
        isLoading: favoritesLoading,
        error: favoritesError,
    } = useGetFavoriteAuctions({ user_id: loggedInUser.id });
    const addFavoriteAuction = useAddFavoriteAuction();
    const removeFavoriteAuction = useRemoveFavoriteAuction();

    if (auctionSelected) {
        return <Redirect push to={`/bid/${auctionSelected}`} />;
    }

    return (
        <>
            <ul className="grid md:hidden grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mb-4">
                {auctions.map((auction) => (
                    <MobileListItem
                        auction={auction}
                        setAuctionSelected={setAuctionSelected}
                        key={auction.id}
                        favoriteClick={() =>
                            favoritesData?.includes(auction.id)
                                ? removeFavoriteAuction.mutate({
                                      auction_id: auction.id,
                                  })
                                : addFavoriteAuction.mutate({
                                      favorited_auctions_user_id:
                                          loggedInUser.id,
                                      favorited_auctions_auction_id: auction.id,
                                  })
                        }
                        isFavorite={favoritesData?.includes(auction.id)}
                    />
                ))}
            </ul>
            <Table className="hidden md:block">
                <Table.Header>
                    <Table.HeaderCell>
                        <span className="sr-only">Auction Image</span>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <span className="text-sm uppercase font-medium tracking-wide">
                            Auction Name
                        </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <span className="text-sm uppercase font-medium tracking-wide">
                            Location
                        </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <span className="text-sm uppercase font-medium tracking-wide">
                            Date
                        </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <span className="sr-only">Favorite</span>
                    </Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {auctions.map((auction) => (
                        <Table.Row key={`auction_${auction.auction_number}`}>
                            <Table.Cell>
                                <div className="flex-shrink-0 py-3">
                                    <a
                                        href={`${process.env.REACT_APP_SCHRADER_PUBLIC_URL}/auctions/${auction.id}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="hover:text-cta"
                                    >
                                        <img
                                            src={
                                                auction.image_src
                                                    ? auction.image_src
                                                    : auctionPlaceholder
                                            }
                                            alt={auction.image_alt}
                                            className="max-h-32 min-w-36 object-cover"
                                        />
                                    </a>
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                <div className="flex items-center space-x-3 py-3">
                                    <div className="flex-shrink-1 max-w-md space-y-3">
                                        <div className="font-bold text-gray-800 whitespace-normal">
                                            {canViewAuction(
                                                loggedInUser,
                                                auction
                                            ) && (
                                                <a
                                                    href={`${process.env.REACT_APP_SCHRADER_PUBLIC_URL}/auctions/${auction.id}`}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    className="hover:text-cta text-sm"
                                                >
                                                    {auction.title}
                                                </a>
                                            )}
                                            {!canViewAuction(
                                                loggedInUser,
                                                auction
                                            ) && (
                                                <span className="text-sm">
                                                    {auction.title}
                                                </span>
                                            )}
                                        </div>
                                        <div className="text-gray-400 text-xs whitespace-normal">
                                            {auction.tagline}
                                        </div>
                                        <div>
                                            <AuctionButton
                                                auction={auction}
                                                setAuctionSelected={
                                                    setAuctionSelected
                                                }
                                                globalContext={globalContext}
                                                loggedInUser={loggedInUser}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                <div className="whitespace-normal text-sm font-bold w-48">
                                    {auction.location}
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                <span className="px-3">
                                    <div className="text-base font-medium">
                                        {format(
                                            parseInt(
                                                auction.auction_date_time * 1000
                                            ),
                                            "d MMM yyyy"
                                        )}
                                    </div>
                                    <div className="text-gray-400 font-bold">
                                        {auction.auction_time}{" "}
                                        {auction.auction_timezone}
                                    </div>
                                </span>
                            </Table.Cell>
                            <Table.Cell>
                                {(favoritesLoading || favoritesError) && <></>}
                                {!favoritesLoading &&
                                !favoritesError &&
                                favoritesData?.includes(auction.id) ? (
                                    <AuctionFav
                                        onClick={() =>
                                            removeFavoriteAuction.mutate({
                                                auction_id: auction.id,
                                            })
                                        }
                                    />
                                ) : (
                                    <AuctionNotFav
                                        onClick={() =>
                                            addFavoriteAuction.mutate({
                                                favorited_auctions_user_id:
                                                    loggedInUser.id,
                                                favorited_auctions_auction_id:
                                                    auction.id,
                                            })
                                        }
                                    />
                                )}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </>
    );
}

const canViewAuction = (loggedInUser, auction) => {
    if (
        loggedInUser.is_admin ||
        loggedInUser.auctions.includes(auction.id) ||
        auction.status === 3
    ) {
        return true;
    }
    return false;
};

const AuctionButton = ({
    auction,
    setAuctionSelected,
    globalContext,
    loggedInUser,
}) => {
    // New, Inactive Auction
    if (
        !globalContext.activeAuctions.includes(auction.id) &&
        auction.status === 1
    ) {
        return loggedInUser.is_admin ? (
            <Button
                onClick={() => setAuctionSelected(auction.id)}
                size="sm"
                type="secondary"
                className="outline-none"
            >
                NOT YET OPEN
            </Button>
        ) : (
            <Button size="sm" className="cursor-default" type="secondary">
                NOT YET OPEN
            </Button>
        );
    }

    // Active Auction
    if (
        (globalContext.activeAuctions.includes(auction.id) &&
            loggedInUser.auctions.includes(auction.id)) ||
        (globalContext.activeAuctions.includes(auction.id) &&
            loggedInUser.is_admin)
    ) {
        return (
            <Button onClick={() => setAuctionSelected(auction.id)} size="sm">
                ENTER AUCTION
            </Button>
        );
    }

    // Completed Auction
    return (
        <Button
            onClick={() =>
                (window.location.href = `${process.env.REACT_APP_SCHRADER_PUBLIC_URL}/auctions/${auction.id}`)
            }
            size="sm"
        >
            VIEW RESULTS
        </Button>
    );
};

const AuctionButtonMobile = ({
    setAuctionSelected,
    auction,
    globalContext,
}) => {
    if (
        !globalContext.activeAuctions.includes(auction.id) &&
        auction.status === 1
    ) {
        return (
            <button className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                NOT YET OPEN
            </button>
        );
    }

    return (
        <button
            onClick={() => {
                if (
                    !globalContext.activeAuctions.includes(auction.id) &&
                    auction.status === 3
                )
                    return (window.location.href = `${process.env.REACT_APP_SCHRADER_PUBLIC_URL}/auctions/${auction.id}`);
                setAuctionSelected(auction.id);
            }}
            className="relative w-0 flex-1 inline-flex flex-col  items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
        >
            <div className="rounded-full bg-gray-200 p-1">
                <ArrowUpIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                />
            </div>
            <span className="mt-2">
                {globalContext.activeAuctions.includes(auction.id) &&
                    "Enter Auction"}
                {!globalContext.activeAuctions.includes(auction.id) &&
                    auction.status === 3 &&
                    "View Results"}
            </span>
        </button>
    );
};

const AuctionFav = ({ onClick = () => undefined }) => {
    return (
        <button onClick={onClick} className="rounded-full bg-red-200 p-1">
            <HeartIcon className="text-red-600 h-5 w-5" />
        </button>
    );
};

const AuctionNotFav = ({ onClick }) => {
    return (
        <button onClick={onClick} className="rounded-full bg-gray-200 p-1">
            <HeartIcon className="text-gray-600 h-5 w-5" />
        </button>
    );
};

const MobileListItem = ({
    auction,
    setAuctionSelected,
    favoriteClick,
    isFavorite,
}) => {
    const globalContext = useContext(GlobalContext);
    const { loggedInUser } = useContext(AuthContext);
    return (
        <li className="col-span-1 bg-gray-50 bg-opacity-50 border border-gray-200 divide-y divide-gray-200">
            <div className="w-full flex items-center justify-between p-6 space-x-6">
                <img
                    className="w-32 h-auto bg-gray-300 flex-shrink-0"
                    src={auction.image_src}
                    alt={auction.image_alt}
                />
                <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                        <h3 className="text-gray-900 text-sm font-medium truncate">
                            {auction.tagline ? auction.tagline : auction.title}
                        </h3>
                    </div>
                    <p className="mt-1 text-gray-500 text-sm truncate">
                        {auction.location}
                    </p>
                    <p className="mt-1 text-gray-500 text-xs font-bold truncate">
                        {format(
                            parseInt(auction.auction_date_time * 1000),
                            "d MMM yyyy"
                        )}{" "}
                        {auction.auction_time} {auction.auction_timezone}
                    </p>
                </div>
            </div>
            <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="w-0 flex-1 flex">
                        <a
                            href={`${process.env.REACT_APP_SCHRADER_PUBLIC_URL}/auctions/${auction.id}`}
                            className="relative -mr-px w-0 flex-1 inline-flex flex-col items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                        >
                            <div className="rounded-full bg-gray-200 p-1">
                                <ExternalLinkIcon
                                    className="w-5 h-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <span className="mt-2">View Details</span>
                        </a>
                    </div>
                    <div className="w-0 flex-1 flex">
                        <button
                            onClick={favoriteClick}
                            className="relative -mr-px w-0 flex-1 inline-flex flex-col items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                        >
                            {isFavorite ? <AuctionFav /> : <AuctionNotFav />}
                            <span className="mt-2">Favorite</span>
                        </button>
                    </div>
                    {((loggedInUser.auctions.includes(auction.id) &&
                        globalContext.activeAuctions.includes(auction.id)) ||
                        loggedInUser.is_admin) && (
                        <div className="-ml-px w-0 flex-1 flex">
                            <AuctionButtonMobile
                                setAuctionSelected={setAuctionSelected}
                                auction={auction}
                                globalContext={globalContext}
                            />
                        </div>
                    )}
                </div>
            </div>
        </li>
    );
};
