const sortHighBids = (bids) => {
    // sort completion bids by tract
    bids.sort((a, b) => {
        const aIsSingleTract =
            !a.bid_alias &&
            !a.bid_name.includes("+") &&
            !a.bid_name.includes("-");
        const bIsSingleTract =
            !b.bid_alias &&
            !b.bid_name.includes("+") &&
            !b.bid_name.includes("-");

        if (aIsSingleTract && bIsSingleTract) {
            return parseInt(a.bid_name) > parseInt(b.bid_name) ? 1 : -1;
        } else if (!aIsSingleTract && bIsSingleTract) {
            return 1;
        } else if (aIsSingleTract && !bIsSingleTract) {
            return -1;
        }
        if (!a.bid_alias && !b.bid_alias) {
            return a.bid_name.localeCompare(b.bid_name, "en");
        }
        if (!a.bid_alias) return 1;
        if (!b.bid_alias) return -1;

        return a.bid_alias.localeCompare(b.bid_alias, "en");
    });
    return bids;
};

export default sortHighBids;
