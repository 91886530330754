import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { DotsHorizontalIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

export default function TableOptions({ auctionID }) {
    return (
        <div className="text-right">
            <Menu as="div" className="inline-block text-left">
                <div>
                    <Menu.Button className="inline-flex justify-center w-full px-2 py-2 text-sm font-medium text-white bg-black rounded-md bg-opacity-10 hover:bg-opacity-20 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        <DotsHorizontalIcon className="h-5 w-5 text-gray-500" />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-4 w-36 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                <Link
                                    to={`/admin/auction-interface/${auctionID}/input`}
                                    className="group flex rounded-md items-center w-full px-2 py-2 text-sm hover:text-cta"
                                >
                                    Input
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link
                                    to={`/admin/auction-interface/${auctionID}/bids`}
                                    className="group flex rounded-md items-center w-full px-2 py-2 text-sm hover:text-cta"
                                >
                                    Bids
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link
                                    to={`/admin/auction-interface/${auctionID}/what-if`}
                                    className="group flex rounded-md items-center w-full px-2 py-2 text-sm hover:text-cta"
                                >
                                    What If
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link
                                    to={`/admin/auction-interface/${auctionID}/completions`}
                                    className="group flex rounded-md items-center w-full px-2 py-2 text-sm hover:text-cta"
                                >
                                    Completions
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link
                                    to={`/admin/auction-interface/${auctionID}/bid-board`}
                                    className="group flex rounded-md items-center w-full px-2 py-2 text-sm hover:text-cta"
                                >
                                    Bid Board
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link
                                    className="group flex rounded-md items-center w-full px-2 py-2 text-sm hover:text-cta"
                                    to={`/admin/auction-interface/${auctionID}/chat`}
                                >
                                    Chat
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link
                                    className="group flex rounded-md items-center w-full px-2 py-2 text-sm hover:text-cta"
                                    to={`/admin/auction-interface/${auctionID}/online`}
                                >
                                    Online
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link
                                    className="group flex rounded-md items-center w-full px-2 py-2 text-sm hover:text-cta"
                                    to={`/admin/auction-interface/${auctionID}/tracts`}
                                >
                                    Tract Map
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link
                                    className="group flex rounded-md items-center w-full px-2 py-2 text-sm hover:text-cta"
                                    to={`/admin/auction-interface/${auctionID}/rebuild`}
                                >
                                    Rebuild
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link
                                    className="group flex rounded-md items-center w-full px-2 py-2 text-sm hover:text-cta"
                                    to={`/admin/auction-interface/${auctionID}/settings`}
                                >
                                    Settings
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link
                                    className="group flex rounded-md items-center w-full px-2 py-2 text-sm hover:text-cta"
                                    to={{
                                        pathname: `${process.env.REACT_APP_SCHRADER_ADMIN_URL}/tfen.cgi?__mode=auction_info&id=${auctionID}`,
                                    }}
                                    target="_blank"
                                >
                                    Setup
                                </Link>
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}
