import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { XCircleIcon } from "@heroicons/react/solid";

const listeners = [];
let memoryState = null;

const reducer = (state, action) => {
    switch (action.type) {
        case "ADD_DD":
            return action.payload;
        case "REMOVE_DD":
            return null;
        default:
            return state;
    }
};

export const dispatch = (action) => {
    memoryState = reducer(memoryState, action);
    listeners.forEach((listener) => {
        listener(memoryState);
    });
};

export const showDD = (data) => {
    return dispatch({
        type: "ADD_DD",
        payload: data,
    });
};

export const DumpProvider = () => {
    const [state, setState] = useState(memoryState);
    useEffect(() => {
        listeners.push(setState);
        return () => {
            const index = listeners.indexOf(setState);
            if (index > -1) {
                listeners.splice(index, 1);
            }
        };
    }, [state]);

    let ddPortal = document.getElementById("dd-portal");
    if (!ddPortal) {
        ddPortal = document.createElement("div");
        ddPortal.setAttribute("id", "dd-portal");
        document.body.appendChild(ddPortal);
    }

    const DdModal = () => {
        return (
            <div className="fixed z-50 inset-0 overflow-y-auto bg-black bg-opacity-70">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                        <div
                            className="mt-2 overflow-y-auto"
                            style={{ maxHeight: "80vh" }}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: state,
                                }}
                            />
                        </div>
                        <button
                            onClick={() => dispatch({ type: "REMOVE_DD" })}
                            className="absolute -right-4 -top-4"
                        >
                            <div className="bg-white rounded-full">
                                <XCircleIcon className="w-8" />
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return <>{state && ReactDOM.createPortal(<DdModal />, ddPortal)}</>;
};
