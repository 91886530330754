import { useContext } from "react";
import { useParams } from "react-router-dom";
import TabNav, { Item } from "../../components/TabNav";
import { AdminAuctionContext } from "../context/AdminAuctionContext";

export default function AdminAuctionsTabNav() {
    const { auctionID } = useParams();
    const { newBidCount } = useContext(AdminAuctionContext);

    return (
        <TabNav>
            <Item link={`/admin/auction-interface/${auctionID}/input`}>
                Input
            </Item>
            <Item link={`/admin/auction-interface/${auctionID}/bids`}>
                {newBidCount > 0 && (
                    <div className="relative">
                        <div className="absolute -right-2.5 -top-3.5">
                            <div className="bg-cta rounded-full h-4 w-4 flex items-center justify-center">
                                <p className="text-white text-center leading-none text-bold text-xs">
                                    {newBidCount}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                Bids
            </Item>
            <Item link={`/admin/auction-interface/${auctionID}/what-if`}>
                What If
            </Item>
            <Item link={`/admin/auction-interface/${auctionID}/completions`}>
                Completions
            </Item>
            <Item link={`/admin/auction-interface/${auctionID}/bid-board`}>
                Bid Board
            </Item>
            <Item link={`/admin/auction-interface/${auctionID}/chat`}>
                Chat
            </Item>
            <Item link={`/admin/auction-interface/${auctionID}/online`}>
                Online
            </Item>
            <Item link={`/admin/auction-interface/${auctionID}/tracts`}>
                Tract Map
            </Item>
            <Item link={`/admin/auction-interface/${auctionID}/rebuild`}>
                Start/Stop
            </Item>
            <Item link={`/admin/auction-interface/${auctionID}/settings`}>
                Settings
            </Item>
            <Item
                to={{
                    pathname: `${process.env.REACT_APP_SCHRADER_ADMIN_URL}/tfen.cgi?__mode=auction_info&id=${auctionID}`,
                }}
                target="_blank"
            >
                <div className="flex space-x-2">
                    <div>Setup</div>
                    <div>
                        <svg
                            width="18"
                            height="18"
                            viewBox="0 0 22 22"
                            fill="#444"
                        >
                            <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 12 L 19 12 L 19 19 L 5 19 L 5 5 L 12 5 L 12 3 L 5 3 z M 14 3 L 14 5 L 17.585938 5 L 8.2929688 14.292969 L 9.7070312 15.707031 L 19 6.4140625 L 19 10 L 21 10 L 21 3 L 14 3 z"></path>
                        </svg>
                    </div>
                </div>
            </Item>
        </TabNav>
    );
}
