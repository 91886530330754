import { useContext } from "react";
import { AuctionContext } from "../context/AuctionContext";
import { AuthContext } from "../../contexts/AuthContext";

export default function TabNavInfo() {
    const {
        currentAuctionPremium,
        currentAuctionId,
        currentAuctionRecentBid,
        userIsRecentBidder,
    } = useContext(AuctionContext);
    const { loggedInUser } = useContext(AuthContext);

    return (
        <div className="space-y-2 sm:space-y-0 sm:flex sm:space-x-2">
            {loggedInUser.auction_bidder_numbers[currentAuctionId] && (
                <div>
                    <span className="text-sm text-gray-500">
                        Your Bidder Number:{" "}
                    </span>
                    <span className="text-gray-900 font-medium">
                        #{loggedInUser.auction_bidder_numbers[currentAuctionId]}
                    </span>
                </div>
            )}
            {currentAuctionPremium && (
                <div>
                    <span className="text-sm text-gray-500">
                        Auction Premium:{" "}
                    </span>
                    <span className="text-gray-900 font-medium">
                        {currentAuctionPremium}
                    </span>
                </div>
            )}
            {currentAuctionRecentBid && (
                <div>
                    <RecentBidBadge isUser={userIsRecentBidder} />
                </div>
            )}
        </div>
    );
}

const RecentBidBadge = ({ isUser }) => {
    if (isUser) {
        return (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-success text-gray-900">
                You're the Most Recent Bidder
            </span>
        );
    }

    return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-sm text-sm font-medium bg-cta bg-opacity-50 text-gray-900">
            Indicates Recent Bid
        </span>
    );
};
