import { Table } from "../../components/Table";
import formatSequential from "../../utilities/formatSequential";
import formatUSD from "../../utilities/formatUSD";

export default function CompletionCard({ completion }) {
    const countBidTractAcres = (bids) => {
        let acres = 0;
        bids.forEach((bid) => {
            bid.tracts.forEach((tract) => {
                acres += parseFloat(tract.tract_acres);
            });
        });
        return acres;
    };
    const countTractsAcres = (tracts) => {
        let acres = 0;
        tracts.forEach((tract) => {
            acres += parseFloat(tract.tract_acres);
        });
        return acres;
    };
    return (
        <div className="bg-white p-4 rounded-xl mb-8 border border-gray-200">
            <div className="bg-gray-200 p-2 -m-4 mb-1 rounded-t-xl">
                <div className="flex">
                    <div className="w-1/6 text-center">TOTAL</div>
                    <div className="w-1/2">{/* Filler... */}</div>
                    <div className="w-1/6 text-center">
                        {formatUSD(completion.completion_sale_price)}
                    </div>
                    <div className="w-1/6 text-center">
                        {formatUSD(
                            Math.round(
                                completion.completion_sale_price /
                                    countBidTractAcres(completion.bids)
                            )
                        ) + `/ac`}
                    </div>
                </div>
            </div>
            <Table>
                <Table.Header>
                    <Table.HeaderCell className="text-center w-1/6">
                        Alias
                    </Table.HeaderCell>
                    <Table.HeaderCell className="text-center w-1/6">
                        Tract(s)
                    </Table.HeaderCell>
                    <Table.HeaderCell className="text-center w-1/6">
                        Acres
                    </Table.HeaderCell>
                    <Table.HeaderCell className="text-center w-1/6">
                        Bidder
                    </Table.HeaderCell>
                    <Table.HeaderCell className="text-center w-1/6">
                        Bid Amount
                    </Table.HeaderCell>
                    <Table.HeaderCell className="text-center w-1/6">
                        PPA
                    </Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {Object.keys(completion).length > 0 &&
                        completion.bids.map((data, i) => (
                            <Table.Row
                                className={`borderless ${
                                    i % 2 !== 0 && "bg-brand-gray-200"
                                }`}
                                key={data.bid_id}
                                height="h-8"
                            >
                                <Table.Cell className="text-center">
                                    {data.bid_alias}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                    {formatSequential(data.bid_name)}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                    {Math.round(countTractsAcres(data.tracts))}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                    <div className="flex space-x-2">
                                        <div className="w-1/2 text-right">
                                            <div
                                                className="bidderColorBlock"
                                                style={{
                                                    backgroundColor: `${
                                                        data.bidder
                                                            ?.bidder_color ??
                                                        "#ccc"
                                                    }`,
                                                }}
                                            ></div>
                                        </div>
                                        <div className="w-1/2 text-left">
                                            {data.bidder?.bidder_number && (
                                                <>
                                                    #
                                                    {data.bidder?.bidder_number}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                    {formatUSD(data.bid_amount)}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                    {formatUSD(
                                        Math.round(
                                            data.bid_amount /
                                                countTractsAcres(data.tracts)
                                        )
                                    ) + `/ac`}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                </Table.Body>
            </Table>
        </div>
    );
}
