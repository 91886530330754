const suggestedBid = (amount, suggestions) => {
    let suggestionArray = [];
    if (!suggestions) {
        suggestionArray = sortSuggestions(defaultIncrements);
    } else {
        suggestionArray = sortSuggestions(suggestions);
    }

    amount = amount.toString();
    amount = amount.replace(/\)|\(|[,]/g, "");
    amount = parseFloat(amount);
    const toSuggest = suggestionArray.find(
        (suggestion) => parseFloat(suggestion.amount) <= amount
    );

    if (!toSuggest) return amount + 1000;
    return amount + parseFloat(toSuggest.suggest);
};

export const sortSuggestions = (suggestions) => {
    return suggestions.sort((a, b) => b.amount - a.amount);
};

export const defaultIncrements = [
    {
        id: "3dad1495-1ef0-4266-b1f0-c2b6ca79cb15",
        amount: 1000000,
        suggest: 20000,
    },
    {
        id: "59a2dd74-e160-4762-b1a4-a89e954c8756",
        amount: 300000,
        suggest: 10000,
    },
    {
        id: "6925f169-98c9-4ceb-b58c-c8b5274e263f",
        amount: 100000,
        suggest: 5000,
    },
    {
        id: "469a8b7a-9173-4ea5-93cc-27674af1defa",
        amount: 50000,
        suggest: 2000,
    },
    { id: "f1f544a1-3a52-4436-9f38-fc4ad7275115", amount: 0, suggest: 1000 },
];

export default suggestedBid;
